export const Layout = {
  GRID_TILES: 'GRID_TILES',
  WHAT_WE_DO_BEST: 'WHAT_WE_DO_BEST',
  ACCELERATE_YOUR_WEB_APP: 'ACCELERATE_YOUR_WEB_APP',
  OUR_VALUES: 'OUR_VALUES',
  OUR_MANAGEMENT: 'OUR_MANAGEMENT',
};

export const Target = {
  NOT_SET: '',
  SELF: '_self',
  BLANK: '_blank',
};

export const LayoutOptions = [
  { key: Layout.GRID_TILES, value: Layout.GRID_TILES, text: 'Grid Tiles' },
  { key: Layout.WHAT_WE_DO_BEST, value: Layout.WHAT_WE_DO_BEST, text: 'What we do best?' },
  {
    key: Layout.ACCELERATE_YOUR_WEB_APP,
    value: Layout.ACCELERATE_YOUR_WEB_APP,
    text: 'Accelerate your web app development',
  },
  { key: Layout.OUR_VALUES, value: Layout.OUR_VALUES, text: 'Our values' },
  { key: Layout.OUR_MANAGEMENT, value: Layout.OUR_MANAGEMENT, text: 'Our management' },
];

export const TargetOptions = [
  { key: Target.NOT_SET, value: Target.NOT_SET, text: 'Not set' },
  { key: Target.SELF, value: Target.SELF, text: Target.SELF },
  { key: Target.BLANK, value: Target.BLANK, text: Target.BLANK },
];

export const GridOptions = new Array(12)
  .fill()
  .map((value, index) => ({ key: index + 1, value: index + 1, text: `${index + 1}` }));
