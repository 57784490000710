import {
  Layout,
  Design,
} from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements/components/PageHeaderElement/consts';

export const PageHeaderElementModel = {
  cssClasses: [],
  layout: Layout.TEXT_LEFT_IMAGE_RIGHT,
  design: Design.HEADER,
  title: {
    pl: '',
    en: '',
  },
  content: {
    pl: '',
    en: '',
  },
  buttons: [],
  images: [],
};
