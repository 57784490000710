import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import { Button, Divider, Form, Grid, Segment } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { InputField } from '@shared/components/translatable';
import { Container } from '@material-ui/core';
import { SwitchField } from '@screens/offers/NewOfferForm/components';
import { isEmpty, isEqual } from 'lodash';

const UserForm = ({
  loading,
  isCreate,
  handleReloadUser,
  handleAddUser,
  handleSaveUser,
  user,
  usersErrors,
  isViewMode,
}) => {
  const [userData, setUserData] = useState(user);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [t] = useTranslation(TRANSLATIONS.USERS);

  useEffect(() => {
    isEmpty(userData) && setUserData(user);
  }, [user]);

  useEffect(() => {
    if (!user?.id && !isCreate) {
      setUserData({
        ...user,
        id: params.id,
      });
    }
  }, [user, isCreate, params.id]);

  const onChange = (e, { name, value }) => {
    if (name === 'phoneNumber') {
      return setUserData(prevState => ({
        ...prevState,
        investorData: {
          ...prevState?.investorData,
          [name]: value,
        },
      }));
    }

    return setUserData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSwitchChange = (e, { name }) => {
    setUserData(prevState => ({
      ...prevState,
      [name]: !prevState?.[name],
    }));
  };

  const handleSave = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await handleSaveUser({ userData, saveAndBack });
    await setIsLoading(false);
  };

  const handleCreate = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await handleAddUser({ userData, saveAndBack });
    await setIsLoading(false);
  };

  const renderMenuButtons = () => (
    <Grid columns={1}>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.USERS}>
          {t('actionButtons.goBack')}
        </Button>
        {!isCreate && (
          <Button
            type="submit"
            disabled={isLoading || loading}
            secondary
            onClick={() => handleReloadUser(params.id)}
            style={{ marginRight: '5px' }}
          >
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isCreate && !isViewMode && (
          <Button
            style={{ marginRight: '5px' }}
            variant="outlined"
            primary
            onClick={handleSave}
            disabled={isEqual(user, userData) || isEmpty(userData?.email) || isLoading || loading}
          >
            {t('actionButtons.save')}
          </Button>
        )}

        {!isCreate && !isViewMode && (
          <Button
            variant="outlined"
            color="facebook"
            onClick={() => handleSave({ saveAndBack: true })}
            disabled={isEqual(user, userData) || isEmpty(userData?.email) || isLoading || loading}
          >
            {t('actionButtons.saveAndBack')}
          </Button>
        )}

        {isCreate && (
          <Button
            variant="outlined"
            primary
            style={{ marginRight: '5px' }}
            onClick={handleCreate}
            disabled={isEqual(user, userData) || isEmpty(userData?.email) || isLoading || loading}
          >
            {t('actionButtons.add')}
          </Button>
        )}

        {isCreate && (
          <Button
            variant="outlined"
            color="facebook"
            onClick={() => handleCreate({ saveAndBack: true })}
            disabled={isEqual(user, userData) || isEmpty(userData?.email) || isLoading || loading}
          >
            {t('actionButtons.addAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="md">
      {renderMenuButtons()}
      <Segment>
        <Form className="series-form" loading={loading || isLoading}>
          <Form.Group>
            <Grid container divided stackable columns={2}>
              <InputField
                label={t('form.email')}
                name="email"
                onChange={onChange}
                value={userData?.email}
                errorMessage={usersErrors?.email}
                icon="mail"
                type="email"
                disabled={isViewMode}
              />
              <Grid.Column className="d-flex justify-content-around">
                <div className="d-flex justify-content-around w-100">
                  <SwitchField
                    className="text-center"
                    name="isAdmin"
                    value={userData?.isAdmin}
                    label={t('form.isAdmin')}
                    onChange={onSwitchChange}
                    disabled={isViewMode}
                  />
                  <SwitchField
                    disabled={isViewMode}
                    className="text-center"
                    name="active"
                    value={userData?.active}
                    label={t('form.active')}
                    onChange={onSwitchChange}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <InputField
                label={t('form.firstName')}
                name="firstName"
                onChange={onChange}
                value={userData?.firstName}
                errorMessage={usersErrors?.firstName}
                icon="font"
                disabled={isViewMode}
              />
              <InputField
                label={t('form.lastName')}
                name="lastName"
                onChange={onChange}
                value={userData?.lastName}
                errorMessage={usersErrors?.lastName}
                icon="font"
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

UserForm.propTypes = {
  isViewMode: bool,
  isCreate: bool,
  loading: bool,
  handleReloadUser: func,
  handleAddUser: func,
  handleSaveUser: func,
  user: shape({}),
  usersErrors: arrayOf(shape({})),
  countries: arrayOf(shape({})),
};

UserForm.defaultProps = {
  isViewMode: false,
  isCreate: false,
  loading: false,
  handleReloadUser: () => {},
  handleAddUser: () => {},
  handleSaveUser: () => {},
  user: {},
  usersErrors: [],
  countries: [],
};

export default UserForm;
