const getAllOffers = state => state?.offers?.allOffers;
const getOfferStatus = state => state?.offers?.status;
const getSingleOffer = state => state?.offers?.singleOffer;
const getOfferErrors = state => state?.offers?.error?.errors;
const getAllOffersQueryParams = state => state?.offers?.queryParams;
const getFeaturedOffersSelectOptions = state =>
  state?.offers?.allOffers?.map(offer => ({ key: offer?.id, value: offer?.id, text: offer?.title }));
const getOffersOptions = state =>
  state?.offers?.allOffers?.map(offer => ({ key: offer?.id, value: offer?.id, text: offer?.title }));
const getOfferOptionsForInvesting = state => ({
  agreements: state?.offers?.singleOffer?.agreements,
  series: state?.offers?.singleOffer?.series,
});

const getOffersAsOptions = state =>
  state?.offers?.allOffers?.map(offer => ({
    key: offer?.id,
    value: offer?.id,
    text: offer?.title,
  }));

const getOffersSlugs = state =>
  state?.offers?.allOffers?.reduce((acc, offer) => {
    acc[offer?.id] = offer?.slug;
    return acc;
  }, {});

export {
  getAllOffers,
  getOfferStatus,
  getSingleOffer,
  getOfferErrors,
  getAllOffersQueryParams,
  getFeaturedOffersSelectOptions,
  getOffersOptions,
  getOfferOptionsForInvesting,
  getOffersSlugs,
  getOffersAsOptions,
};
