import {
  REVALIDATE_ALL_OFFERS_INIT,
  REVALIDATE_ALL_OFFERS_SUCCESS,
  REVALIDATE_ALL_OFFERS_FAILURE,
} from '@store/action-types/revalidate';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const revalidateAllOffersInit = () => ({ type: REVALIDATE_ALL_OFFERS_INIT });
const revalidateAllOffersSuccess = () => ({ type: REVALIDATE_ALL_OFFERS_SUCCESS });
const revalidateAllOffersFailure = () => ({ type: REVALIDATE_ALL_OFFERS_FAILURE });

const revalidateAllOffers = () => async dispatch => {
  dispatch(revalidateAllOffersInit());

  try {
    await client.revalidateAllOffers();
    await dispatch(revalidateAllOffersSuccess());

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('revalidateAllOffers.success'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('revalidateAllOffers.error'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(revalidateAllOffersFailure());
  }
};

export default revalidateAllOffers;
