import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import TranslationNamespaces from '@translations/translationNamespaces';
import styles from './styles.module.scss';

const MenuItem = ({ id, onDelete, label, position, url }) => {
  const [t] = useTranslation(TranslationNamespaces.MENU);
  const history = useHistory();

  const editMenuItem = () => history.push(`/menu/${id}`);

  const deleteMenuItem = e => {
    e.stopPropagation();

    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(`${label[i18next.language || 'en']} - ${t('deleteMenuItemDisclaimer')}`)) {
      onDelete(id);
    }
  };

  return (
    <button type="button" onClick={editMenuItem} className={styles.wrapper}>
      <p className={styles.text}>
        <span>{position}.</span>&nbsp;
        {label[i18next.language || 'en']} - {url[i18next.language || 'en']}
      </p>
      <div>
        <Button onClick={editMenuItem} icon color="linkedin">
          <Icon className={styles.whiteIcon} name="edit" />
        </Button>
        <Button onClick={deleteMenuItem} icon color="youtube">
          <Icon className={styles.whiteIcon} name="trash" />
        </Button>
      </div>
    </button>
  );
};

MenuItem.propTypes = {
  id: PropTypes.string,
  onDelete: PropTypes.func,
  label: PropTypes.shape({
    pl: PropTypes.string,
    en: PropTypes.string,
  }),
  url: PropTypes.shape({
    pl: PropTypes.string,
    en: PropTypes.string,
  }),
};

MenuItem.defaultProps = {
  id: '',
  onDelete: () => {},
  label: {
    pl: '',
    en: '',
  },
  url: {
    pl: '',
    en: '',
  },
};

export default MenuItem;
