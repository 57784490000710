import { DELETE_MENU_ITEM_FAILURE, DELETE_MENU_ITEM_INIT, DELETE_MENU_ITEM_SUCCESS } from '@store/action-types/menu';
import Client from '@api-sdk/client';
import getMenuList from '@store/actions/menu/getMenuList';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteMenuItemInit = () => ({ type: DELETE_MENU_ITEM_INIT });
const deleteMenuItemSuccess = () => ({ type: DELETE_MENU_ITEM_SUCCESS });
const deleteMenuItemFailure = error => ({ type: DELETE_MENU_ITEM_FAILURE, payload: { error } });

const deleteMenuItem = id => async dispatch => {
  dispatch(deleteMenuItemInit());

  try {
    await Client.deleteMenuItem({ slug: id });
    dispatch(getMenuList());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('menu.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
    return dispatch(deleteMenuItemSuccess(id));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('menu.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'danger',
        },
      }),
    );
    return dispatch(deleteMenuItemFailure(error));
  }
};

export default deleteMenuItem;
