import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSingleOfferAction, editOfferAction } from '@store/actions/offers';
import { getSeriesOptionsAction } from '@store/actions/series';
import { getSingleOffer, getOfferStatus, getOfferErrors } from '@store/reducers/offers/selectors';
import { getSeriesOptions } from '@store/reducers/series/selectors';
import { getTypesAndTargetsAction } from '@store/actions/agreements';
import { revalidateOffer } from '@store/actions/revalidate';
import { getTargets, getTypes } from '@store/reducers/agreements/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singleOffer: getSingleOffer(state),
  offerStatus: getOfferStatus(state),
  offerErrors: getOfferErrors(state),
  seriesOptions: getSeriesOptions(state),
  targets: getTargets(state),
  types: getTypes(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleOfferAction,
      editOfferAction,
      getSeriesOptionsAction,
      getTypesAndTargetsAction,
      revalidateOffer,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
