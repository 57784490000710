const REVALIDATE_OFFER_INIT = '@revalidate/REVALIDATE_OFFER_INIT';
const REVALIDATE_OFFER_SUCCESS = '@revalidate/REVALIDATE_OFFER_SUCCESS';
const REVALIDATE_OFFER_FAILURE = '@revalidate/REVALIDATE_OFFER_FAILURE';

const REVALIDATE_ALL_OFFERS_INIT = '@revalidate/REVALIDATE_ALL_OFFERS_INIT';
const REVALIDATE_ALL_OFFERS_SUCCESS = '@revalidate/REVALIDATE_ALL_OFFERS_SUCCESS';
const REVALIDATE_ALL_OFFERS_FAILURE = '@revalidate/REVALIDATE_ALL_OFFERS_FAILURE';

export {
  REVALIDATE_OFFER_INIT,
  REVALIDATE_OFFER_SUCCESS,
  REVALIDATE_OFFER_FAILURE,
  REVALIDATE_ALL_OFFERS_INIT,
  REVALIDATE_ALL_OFFERS_SUCCESS,
  REVALIDATE_ALL_OFFERS_FAILURE,
};
