import { GET_MENU_ITEM_INIT, GET_MENU_ITEM_FAILURE, GET_MENU_ITEM_SUCCESS } from '@store/action-types/menu';
import Client from '@api-sdk/client';

const getMenuItemInit = () => ({ type: GET_MENU_ITEM_INIT });
const getMenuItemSuccess = menuItem => ({
  type: GET_MENU_ITEM_SUCCESS,
  payload: { menuItem },
});
const getMenuItemFailure = error => ({ type: GET_MENU_ITEM_FAILURE, payload: { error } });

const getMenuItem = menuItemId => async dispatch => {
  dispatch(getMenuItemInit());

  try {
    const singleMenuItem = await Client.getMenuItem({ slug: menuItemId });
    dispatch(getMenuItemSuccess(singleMenuItem.response));
  } catch (error) {
    dispatch(getMenuItemFailure(error));
  }
};

export default getMenuItem;
