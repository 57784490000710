import { GET_SINGLE_BLOG_INIT, GET_SINGLE_BLOG_FAILURE, GET_SINGLE_BLOG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';

const getSinglePostInit = () => ({ type: GET_SINGLE_BLOG_INIT });
const getSinglePostSuccess = singlePost => ({
  type: GET_SINGLE_BLOG_SUCCESS,
  payload: { singlePost },
});
const getSinglePostFailure = error => ({ type: GET_SINGLE_BLOG_FAILURE, payload: { error } });

const getSinglePost = postId => async dispatch => {
  dispatch(getSinglePostInit());

  try {
    const singlePost = await Client.getSinglePost({ slug: postId });
    dispatch(getSinglePostSuccess({ ...singlePost.response, tags: singlePost.response?.tags?.map(tag => tag?.id) }));
  } catch (error) {
    dispatch(getSinglePostFailure(error));
  }
};

export default getSinglePost;
