import React from 'react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { shape, string } from 'prop-types';
import OfferForm from '../NewOfferForm/OfferForm';

class Main extends React.PureComponent {
  state = {
    isOfferRevalidating: false,
  };

  componentDidMount() {
    const { actions, match } = this.props;

    actions.getSingleOfferAction(match?.params?.id);
    actions.getSeriesOptionsAction();
    actions.getTypesAndTargetsAction();
  }

  handleRevalidateOffer = async () => {
    /* eslint-disable-next-line */
    if (confirm('Are you sure you want to revalidate this offer?')) {
      const { actions, match } = this.props;

      this.setState(prevState => ({
        ...prevState,
        isOfferRevalidating: true,
      }));
      await actions.revalidateOffer(match?.params?.id);
      this.setState(prevState => ({
        ...prevState,
        isOfferRevalidating: false,
      }));
    }
  };

  render() {
    const {
      actions,
      singleOffer,
      offerStatus,
      offerErrors,
      match,
      seriesOptions,
      targets,
      types,
      permittedPermissions,
    } = this.props;

    const isUserPermitted = permission =>
      permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

    return (
      <div>
        <OfferForm
          isLoading={
            offerStatus === FETCH_STATUS.IDLE || offerStatus === FETCH_STATUS.LOADING || this.state.isOfferRevalidating
          }
          offer={singleOffer}
          offerErrors={offerErrors}
          agreementTargets={targets}
          agreementTypes={types}
          onRefresh={() => actions.getSingleOfferAction(match?.params?.id)}
          onSaveEditedOffer={actions.editOfferAction}
          seriesOptions={seriesOptions}
          isViewMode={!isUserPermitted(PERMISSIONS.EDIT_OFFER)}
          onRevalidate={this.handleRevalidateOffer}
        />
      </div>
    );
  }
}

Main.propTypes = {
  actions: shape({}),
  singleOffer: shape({}),
  offerStatus: string,
};

Main.defaultProps = {
  actions: {},
  singleOffer: {},
  offerStatus: '',
};

export default Main;
