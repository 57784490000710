import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Icon, Menu, Tab } from 'semantic-ui-react';
import { Container } from '@material-ui/core';
import ROUTES from '@shared/consts/routes';
import TRANSLATIONS from '@translations/translationNamespaces';
import { BlogFormMain, BlogFormContent, BlogFormSeo } from './components';

const BlogForm = ({ isViewMode, onRefresh, onSave, post, isLoading, errors, isEdit }) => {
  const [postData, setPostData] = useState(post);
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation(TRANSLATIONS.BLOG);

  useEffect(() => {
    setPostData(post);
  }, [post]);

  const tabs = [
    {
      menuItem: (
        <Menu.Item key="main-tab">
          {t('form.main')}
          {(errors?.tag || errors?.publishedAt || errors?.author || errors?.tag || errors?.slug) && (
            <Icon className="ml-1" name="warning circle" color="red" />
          )}
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane key="main">
          <BlogFormMain errors={errors} isViewMode={isViewMode} postData={postData} onChange={setPostData} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="content-tab">
          {t('form.content')}
          {(errors?.title || errors?.content || errors?.description) && (
            <Icon className="ml-1" name="warning circle" color="red" />
          )}
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane key="content">
          <BlogFormContent onChange={setPostData} errors={errors} isViewMode={isViewMode} postData={postData} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="seo-tab">
          {t('form.seo')}
          {(errors?.seoTitle || errors?.seoDescription) && <Icon className="ml-1" name="warning circle" color="red" />}
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane key="seo">
          <BlogFormSeo onChange={setPostData} errors={errors} isViewMode={isViewMode} postData={postData} />
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChange = (e, { activeIndex }) => setActiveTab(activeIndex);

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{isEdit ? t('editPost') : t('createPost')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.BLOG}>
          {t('actionButtons.goBack')}
        </Button>
        {isEdit && (
          <Button
            disabled={isLoading}
            type="submit"
            secondary
            onClick={() => onRefresh()}
            style={{ marginRight: '5px' }}
          >
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isViewMode && (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading}
            variant="outlined"
            primary
            onClick={() => onSave({ post: postData, postId: params?.id })}
          >
            {isEdit ? t('actionButtons.save') : t('actionButtons.create')}
          </Button>
        )}

        {!isViewMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            color="facebook"
            onClick={() => onSave({ post: postData, saveAndBack: true, postId: params?.id })}
          >
            {isEdit ? t('actionButtons.saveAndBack') : t('actionButtons.createAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="lg">
      {renderMenuButtons()}
      <Form loading={isLoading} className="mt-5 mb-3">
        <Tab
          onTabChange={handleTabChange}
          activeIndex={activeTab}
          renderActiveOnly={false}
          menu={{ attached: 'top' }}
          panes={tabs}
        />
      </Form>
      {renderMenuButtons()}
    </Container>
  );
};

BlogForm.propTypes = {
  isViewMode: PropTypes.bool,
  onRefresh: PropTypes.func,
  onSave: PropTypes.func,
  post: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  errors: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    description: PropTypes.string,
    position: PropTypes.string,
    publishedAt: PropTypes.string,
    author: PropTypes.string,
    tag: PropTypes.string,
    slug: PropTypes.string,
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
};

BlogForm.defaultProps = {
  isViewMode: false,
  onRefresh: () => {},
  onSave: () => {},
  post: {},
  isLoading: false,
  errors: {},
  isEdit: false,
};

export default BlogForm;
