const getPostsList = state => state?.blog?.list?.data;

const getPostsListStatus = state => state?.blog?.list?.status;

const getPostsQueryParams = state => state?.blog?.list?.queryParams;

const getPostsStatus = state => state?.blog?.status;

const getPostsError = state => state?.blog?.error;

const getSinglePost = state => state?.blog?.post;

const getTagsList = state => state?.blog?.tags?.list?.data;

const getTagsListStatus = state => state?.blog?.tags?.list?.status;

const getTagStatus = state => state?.blog?.tags?.tag?.status;

const getTag = state => state?.blog?.tags?.tag?.data;

const getTagError = state => state?.blog?.tags?.tag?.error;

export {
  getPostsList,
  getPostsListStatus,
  getPostsQueryParams,
  getPostsStatus,
  getPostsError,
  getSinglePost,
  getTagsList,
  getTagsListStatus,
  getTagStatus,
  getTag,
  getTagError,
};
