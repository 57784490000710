import {
  REVALIDATE_OFFER_INIT,
  REVALIDATE_OFFER_SUCCESS,
  REVALIDATE_OFFER_FAILURE,
} from '@store/action-types/revalidate';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const revalidateOfferInit = () => ({ type: REVALIDATE_OFFER_INIT });
const revalidateOfferSuccess = () => ({ type: REVALIDATE_OFFER_SUCCESS });
const revalidateOfferFailure = () => ({ type: REVALIDATE_OFFER_FAILURE });

const revalidateOffer = offerId => async dispatch => {
  dispatch(revalidateOfferInit());

  try {
    await client.revalidateOffer({ slug: offerId });
    await dispatch(revalidateOfferSuccess());

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('revalidateOffer.success'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('revalidateOffer.error'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(revalidateOfferFailure());
  }
};

export default revalidateOffer;
