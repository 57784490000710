import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { Container } from '@material-ui/core';
import ROUTES from '@shared/consts/routes';
import TRANSLATIONS from '@translations/translationNamespaces';
import { TranslatableTextField } from '@screens/offers/NewOfferForm/components';

const TagsForm = ({ isViewMode, onRefresh, onSave, tag, isLoading, errors, isEdit }) => {
  const [tagData, setTagData] = useState(tag);
  const params = useParams();
  const { t } = useTranslation(TRANSLATIONS.BLOG);

  useEffect(() => {
    setTagData(tag);
  }, [tag]);

  const onTranslatableInputChange = (e, { name, value, language }) => {
    setTagData({
      ...tagData,
      [name]: {
        ...tagData[name],
        [language]: value,
      },
    });
  };

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{isEdit ? t('editTag') : t('createTag')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.BLOG_TAGS}>
          {t('actionButtons.goBack')}
        </Button>
        {isEdit && (
          <Button
            disabled={isLoading}
            type="submit"
            secondary
            onClick={() => onRefresh()}
            style={{ marginRight: '5px' }}
          >
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isViewMode && (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading}
            variant="outlined"
            primary
            onClick={() => onSave({ tag: tagData, tagId: params?.id })}
          >
            {isEdit ? t('actionButtons.save') : t('actionButtons.create')}
          </Button>
        )}

        {!isViewMode && (
          <Button
            disabled={isLoading}
            variant="outlined"
            color="facebook"
            onClick={() => onSave({ tag: tagData, saveAndBack: true, tagId: params?.id })}
          >
            {isEdit ? t('actionButtons.saveAndBack') : t('actionButtons.createAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="lg">
      {renderMenuButtons()}
      <Segment>
        <Form loading={isLoading} className="mt-5 mb-3">
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                icon="tag"
                disabled={isViewMode}
                label={t('form.name')}
                name="name"
                errorMessage={errors?.name}
                value={tagData?.name}
                onChange={onTranslatableInputChange}
                labelClassName="d-flex align-items-center justify-content-center w-fit-content"
              />
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

TagsForm.propTypes = {
  isViewMode: PropTypes.bool,
  onRefresh: PropTypes.func,
  onSave: PropTypes.func,
  tag: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  errors: PropTypes.shape({
    name: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
};

TagsForm.defaultProps = {
  isViewMode: false,
  onRefresh: () => {},
  onSave: () => {},
  tag: {},
  isLoading: false,
  errors: {},
  isEdit: false,
};

export default TagsForm;
