import { EDIT_BLOG_FAILURE, EDIT_BLOG_INIT, EDIT_BLOG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';
import { isEmpty, set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';
import history from '@store/history';

const editPostInit = () => ({ type: EDIT_BLOG_INIT });
const editPostSuccess = () => ({ type: EDIT_BLOG_SUCCESS });
const editPostFailure = error => {
  if (!error?.errors) {
    return { type: EDIT_BLOG_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: EDIT_BLOG_FAILURE, payload: { error: mappedErrors } };
};

const editPost = ({ post, postId, saveAndBack = false }) => async dispatch => {
  dispatch(editPostInit());

  if (isEmpty(String(post?.position))) {
    return dispatch(editPostFailure({ position: 'POSITION_IS_REQUIRED' }));
  }

  try {
    await Client.editPost({
      data: {
        ...post,
        imageUrl: undefined,
        thumbUrl: undefined,
        id: undefined,
      },
      slug: postId,
    });

    if (saveAndBack) {
      history.push(ROUTES.BLOG);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    dispatch(editPostSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editPostFailure(error));
  }
};

export default editPost;
