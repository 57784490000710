import { Target } from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements/components/GridElement/consts';

const GridElementItemModel = ({ mobile, tablet, desktop } = { mobile: 4, tablet: 4, desktop: 4 }) => ({
  position: 1,
  imageUrl: '',
  target: Target.NOT_SET,
  title: { pl: '', en: '' },
  content: { pl: '', en: '' },
  url: { pl: '', en: '' },
  buttons: [],
  grid: {
    mobile,
    tablet,
    desktop,
  },
});

export default GridElementItemModel;
