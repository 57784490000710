import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import { InputField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import clsx from 'clsx';
import { getTagsList, getTagsListStatus } from '@store/reducers/blog/selectors';
import { bindActionCreators, compose } from 'redux';
import { getTags } from '@store/actions/blog';
import { connect } from 'react-redux';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import i18next from 'i18next';
import styles from './TagSelect.module.scss';

const TagSelect = ({ actions, isViewMode, onChange, value, errorMessage, labelClassName, tags, status }) => {
  const { t } = useTranslation(TRANSLATIONS.BLOG);

  useEffect(() => {
    actions.getTags();
  }, []);

  return (
    <Form.Group>
      <Grid container stackable columns={1} divided>
        <Grid.Column>
          <Form.Field>
            {errorMessage && (
              <Label color="red" className={clsx('mb-5', labelClassName)}>
                {errorMessage}
              </Label>
            )}
            <Form.Field className="d-flex justify-content-between">
              <label>{t('form.tags')}</label>
            </Form.Field>
            <Dropdown
              disabled={isViewMode || status === FETCH_STATUS.IDLE || status === FETCH_STATUS.IDLE}
              placeholder={status === FETCH_STATUS.IDLE || status === FETCH_STATUS.IDLE ? 'Loading...' : t('form.tags')}
              selection
              name="tags"
              value={value}
              className={styles.dropdown}
              options={
                tags?.map(tag => ({
                  key: tag.id,
                  value: tag.id,
                  text: tag.name?.[i18next.language || 'en'],
                })) || []
              }
              multiple
              onChange={(e, { name, value: v }) => onChange(e, { name, value: v })}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    </Form.Group>
  );
};

TagSelect.propTypes = {
  actions: PropTypes.shape({
    getTags: PropTypes.func.isRequired,
  }).isRequired,
  isViewMode: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  labelClassName: PropTypes.string,
  status: PropTypes.string,
  tags: [],
};

TagSelect.defaultProps = {
  isViewMode: false,
  onChange: () => {},
  value: '',
  errorMessage: '',
  labelClassName: '',
  status: FETCH_STATUS.IDLE,
  tags: [],
};

const mapStateToProps = state => ({
  tags: getTagsList(state),
  status: getTagsListStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getTags,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(TagSelect);
