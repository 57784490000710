import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { getAbsoluteUrl } from '@metronic/helpers';
import { useHtmlClassService } from '@metronic/providers';
import SVG from 'react-inlinesvg';
import { Typography } from '@material-ui/core';
import ROUTES from '@shared/consts/routes';
import UltifideLogo from '@shared/components/UltifideLogo';
import useStyles from './styles';

const Brand = () => {
  const uiService = useHtmlClassService();
  const styles = useStyles();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, 'aside.self.minimize.toggle'),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <>
      <div className={`brand flex-column-auto ${layoutProps.brandClasses} ${styles.brand}`} id="kt_brand">
        <div className={`${styles.logoContainer} brand-logo`}>
          <Typography color="textSecondary" variant="body2" to={ROUTES.HOME} component={Link}>
            <UltifideLogo isWhite />
          </Typography>
        </div>

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            <button type="button" className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={getAbsoluteUrl('/images/icons/angle-double-left.svg')} />
              </span>
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default Brand;
