export const ButtonCssClasses = [
  {
    key: 'Primary',
    text: 'Primary',
    value: 'btn-primary',
  },
  {
    key: 'Secondary',
    text: 'Secondary',
    value: 'btn-secondary',
  },
  {
    key: 'Bigger',
    text: 'Bigger',
    value: 'btn-bigger',
  },
];
