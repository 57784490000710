import { combineReducers } from 'redux';
import globalReducer from '@store/reducers/global';
import counterReducer from './counter';
import offersReducer from './offers';
import faqReducer from './faq';
import investorsReducer from './investors';
import investmentsReducer from './investments';
import ordersReducer from './orders';
import transitionReducer from './transactions';
import userReducer from './user';
import configReducer from './settings';
import pageReducer from './page';
import agreementsReducer from './agreements';
import rolesReducer from './roles';
import permissionReducer from './permissions';
import emailReducer from './email-templates';
import sliderReducer from './slider';
import toastReducer from './toast';
import seriesReducer from './series';
import userManagementReducer from './user-management';
import splashScreenReducer from './splash-screen';
import staticContentReducer from './static-content';
import featuredOffersReducer from './featured-offers';
import paypalReducer from './paypal';
import checkPermissionReducer from './check-permission';
import blogReducer from './blog';
import menuReducer from './menu';

const rootReducer = combineReducers({
  platform: combineReducers({
    user: userReducer,
    global: globalReducer,
    splashScreen: splashScreenReducer,
  }),
  checkPermission: checkPermissionReducer,
  staticContent: staticContentReducer,
  counter: counterReducer,
  offers: offersReducer,
  series: seriesReducer,
  faq: faqReducer,
  investments: investmentsReducer,
  orders: ordersReducer,
  transactions: transitionReducer,
  page: pageReducer,
  blog: blogReducer,
  investors: investorsReducer,
  settings: combineReducers({
    config: configReducer,
  }),
  agreements: agreementsReducer,
  staffManagement: combineReducers({
    roles: rolesReducer,
    permission: permissionReducer,
  }),
  emailTemplates: emailReducer,
  slider: sliderReducer,
  app: toastReducer,
  userManagement: userManagementReducer,
  featuredOffers: featuredOffersReducer,
  paypal: paypalReducer,
  menu: menuReducer,
});

export default rootReducer;
