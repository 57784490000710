import { CLONE_OFFER_INIT, CLONE_OFFER_SUCCESS, CLONE_OFFER_FAILURE } from '@store/action-types/offers';
import client from '@api-sdk/client';
import translateToastMessage from '@shared/utils/translateToastMessage';
import { enqueueSnackbar } from '@store/actions/toast';
import history from '@store/history';
import mapSingleOfferResponse from '@store/actions/offers/utils/responseMapper';

const cloneOfferInit = () => ({ type: CLONE_OFFER_INIT });
const cloneOfferSuccess = clonedOffer => ({ type: CLONE_OFFER_SUCCESS, payload: { clonedOffer } });
const cloneOfferFailure = error => ({ type: CLONE_OFFER_FAILURE, payload: { error } });

const cloneOffer = offerId => async dispatch => {
  dispatch(cloneOfferInit());

  try {
    const cloneOfferResponse = await client.cloneOffer({ slug: `${offerId}/clone` });
    history.push(`/pages/${cloneOfferResponse.response.id}/edit`);
    await dispatch(cloneOfferSuccess(mapSingleOfferResponse(cloneOfferResponse.response)));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.clone'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.error.clone'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(cloneOfferFailure(error));
  }
};

export default cloneOffer;
