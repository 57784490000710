import { CREATE_TAG_FAILURE, CREATE_TAG_INIT, CREATE_TAG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const createTagInit = () => ({ type: CREATE_TAG_INIT });
const createTagSuccess = () => ({ type: CREATE_TAG_SUCCESS });
const createTagFailure = error => {
  if (!error?.errors) {
    return { type: CREATE_TAG_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: CREATE_TAG_FAILURE, payload: { error: mappedErrors } };
};

const createTag = ({ tag, saveAndBack = false }) => async dispatch => {
  dispatch(createTagInit());

  try {
    const tagResponse = await Client.createTag({
      data: tag,
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.tags.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    if (saveAndBack) {
      history.push(ROUTES.BLOG_TAGS);
    } else {
      history.push(`/blog/tags/${tagResponse.response?.id}`);
    }

    return dispatch(createTagSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.tags.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    return dispatch(createTagFailure(error));
  }
};

export default createTag;
