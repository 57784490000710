import { CREATE_BLOG_FAILURE, CREATE_BLOG_INIT, CREATE_BLOG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';
import { set, toPath, isEmpty } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';
import moment from 'moment';

const createPostInit = () => ({ type: CREATE_BLOG_INIT });
const createPostSuccess = () => ({ type: CREATE_BLOG_SUCCESS });
const createPostFailure = error => {
  if (!error?.errors) {
    return { type: CREATE_BLOG_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: CREATE_BLOG_FAILURE, payload: { error: mappedErrors } };
};

const createPost = ({ post, saveAndBack = false }) => async dispatch => {
  dispatch(createPostInit());

  if (isEmpty(String(post?.position))) {
    return dispatch(createPostFailure({ position: 'POSITION_IS_REQUIRED' }));
  }

  try {
    const blogResponse = await Client.createPost({
      data: post,
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    if (saveAndBack) {
      history.push(ROUTES.BLOG);
    } else {
      history.push(`/blog/${blogResponse.response?.id}`);
    }

    return dispatch(createPostSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    return dispatch(createPostFailure(error));
  }
};

export default createPost;
