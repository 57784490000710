import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getMenuItemList,
  getMenuItemStatus,
  getMenuItemListStatus,
  getMenuQueryParams,
} from '@store/reducers/menu/selectors';
import { getMenuList, deleteMenuItem, clearStatus } from '@store/actions/menu';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  menuList: getMenuItemList(state),
  listStatus: getMenuItemListStatus(state),
  status: getMenuItemStatus(state),
  queryParams: getMenuQueryParams(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getMenuList,
      deleteMenuItem,
      clearStatus,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
