import {
  YoutubeElementModel,
  TimelineElementModel,
  TextElementModel,
  MapElementModel,
  ImageElementModel,
  CounterElementModel,
  GalleryElementModel,
  AttachmentsElementModel,
  BarChartElementModel,
  PieChartElementModel,
  TableElementModel,
  PageHeaderElementModel,
  GridElementModel,
  ContactFormElementModel,
  SuccessStoryElementModel,
} from '../../models/elementModels';

const ELEMENT_TYPES = {
  pageHeader: { value: 'pageHeader', additionalFields: PageHeaderElementModel },
  grid: { value: 'grid', additionalFields: GridElementModel },
  successStory: { value: 'successStory', additionalFields: SuccessStoryElementModel },
  gallery: { value: 'gallery', additionalFields: GalleryElementModel },
  contactForm: { value: 'contactForm', additionalFields: ContactFormElementModel },
  counter: { value: 'counter', additionalFields: CounterElementModel },
  timeline: { value: 'timeline', additionalFields: TimelineElementModel },
  table: { value: 'table', additionalFields: TableElementModel, column: 'column', row: 'row' },
  // attachments: { value: 'attachments', additionalFields: AttachmentsElementModel },
  // barChart: { value: 'barChart', additionalFields: BarChartElementModel },
  // pieChart: { value: 'pieChart', additionalFields: PieChartElementModel },
  // image: { value: 'image', additionalFields: ImageElementModel },
  map: { value: 'map', additionalFields: MapElementModel },
  text: { value: 'text', additionalFields: TextElementModel },
  // youtube: { value: 'youtube', additionalFields: YoutubeElementModel },
};

const getElementTypesDropdownOptions = t =>
  Object.keys(ELEMENT_TYPES).map(type => ({
    key: type,
    value: type,
    text: t(`elementTypes.${type}`),
  }));

export { ELEMENT_TYPES, getElementTypesDropdownOptions };
