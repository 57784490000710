import React, { useState, Fragment, useMemo, useRef } from 'react';
import { bool, func, number, shape } from 'prop-types';
import clsx from 'clsx';
import { Button, Dropdown, Form, Grid, Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
  TableRowModel,
  TableColumnModel,
  TechnologiesColumnsModel,
  TechnologiesRowsModel,
} from '@screens/offers/NewOfferForm/OfferForm/models/elementModels/TableElementModel';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import TRANSLATIONS from '@translations/translationNamespaces';
import { VALID_DOCUMENT_MIME_TYPES } from '@screens/page/const';
import { bindActionCreators } from 'redux';
import { uploadSingleFileAction } from '@store/actions/single-upload';
import { connect } from 'react-redux';
import { TranslatableRichTextField } from '@shared/components/translatable';
import useStyles from './styles';

const Quill = ReactQuill.Quill;
const Inline = Quill.import('blots/inline');

let attachmentBlotInnerHtml = null;

class AttachmentBlot extends Inline {
  static create(url) {
    const node = super.create();
    node.setAttribute('href', url);
    node.setAttribute('target', '_blank');

    if (attachmentBlotInnerHtml) {
      node.innerHTML = attachmentBlotInnerHtml;
    }

    if (url) {
      node.onclick = () => window.open(url, '_blank');
    }

    node.setAttribute('title', node.textContent);

    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('href') || true;
  }

  format(name, value) {
    if (name === 'attachment' && value) {
      this.domNode.setAttribute('href', value);
    } else {
      super.format(name, value);
    }
  }

  formats() {
    const formats = super.formats();
    formats.attachment = AttachmentBlot.formats(this.domNode);
    return formats;
  }
}

AttachmentBlot.blotName = 'attachment';
AttachmentBlot.tagName = 'a';
Quill.register(AttachmentBlot);

const languages = ['en', 'pl'];
const languageFlags = {
  en: <i className="us flag" />,
  pl: <i className="pl flag" />,
};

const ROW_PRESETS = {
  PRESET_1: {
    key: 'preset_1',
    name: 'Preset 1',
    textColor: '#9F3A38',
    backgroundColor: '#FFF6F6',
  },
  PRESET_2: {
    key: 'preset_2',
    name: 'Preset 2',
    textColor: '#573A08',
    backgroundColor: '#FFFAF3',
  },
  PRESET_3: {
    key: 'preset_3',
    name: 'Preset 3',
    textColor: '#000000',
    backgroundColor: '#FFFFFF',
  },
};

const TableElement = ({ actions, element, onChange, sectionNumber, isViewMode }) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.OFFERS);
  const quillRef = useRef(null);
  const [selectedCell, setSelectedCell] = useState({});

  const rowDropdownPositions = element?.rows?.map?.(row => ({
    key: row.position,
    value: row.position,
    text: row.position,
  }));

  const columnDropdownPositions = element?.columns?.map?.(column => ({
    key: column.position,
    value: column.position,
    text: column.position,
  }));

  const onTranslatableContentChange = (e, { name, language, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: {
                        ...el[name],
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const selectAttachment = async () => {
    const input = document.createElement('input');
    attachmentBlotInnerHtml = prompt(t('enterFileName'));

    if (attachmentBlotInnerHtml) {
      input.setAttribute('type', 'file');
      input.setAttribute('accept', VALID_DOCUMENT_MIME_TYPES.join(', '));
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        const reader = new FileReader();

        if (file && actions.uploadSingleFileAction) {
          reader.onloadend = async () => {
            const uploadedFile = await actions.uploadSingleFileAction({
              base64: reader.result,
              fileName: file.name,
            });

            let range = quillRef?.current.getEditor().getSelection(true);
            quillRef.current.getEditor().setSelection(range.index + 1);
            quillRef.current.getEditor().insertEmbed(range.index, 'attachment', uploadedFile?.payload?.file?.url);

            range = quillRef?.current.getEditor().getSelection(true);
            quillRef.current.getEditor().setSelection(range.index + attachmentBlotInnerHtml?.length);
            attachmentBlotInnerHtml = null;
          };

          return reader.readAsDataURL(file);
        }
      };
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: 1 }, { header: 2 }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['image', 'link', 'attachment', 'blockquote'],
          ['clean'],
        ],
        handlers: {
          attachment: selectAttachment,
        },
      },
    }),
    [],
  );

  const onAddColumn = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      columns: el.columns.concat([
                        {
                          ...TableColumnModel,
                          position: el?.columns?.[el?.columns?.length - 1]?.position + 1 || 1,
                        },
                      ]),
                      rows: el.rows.map(row => ({
                        ...row,
                        cells: row.cells.concat([
                          {
                            position: el?.columns?.[el?.columns?.length - 1]?.position + 1 || 1,
                            value: { pl: '-', en: '-' },
                          },
                        ]),
                      })),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onAddRow = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      rows: el.rows.concat([
                        {
                          ...TableRowModel,
                          cells: new Array(element?.columns?.length).fill(true).map((v, i) => ({
                            position: i + 1,
                            value: { pl: '-', en: '-' },
                          })),
                          position: el?.rows?.[el?.rows?.length - 1]?.position + 1 || 1,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const setTechonologiesTemplate = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      columns: TechnologiesColumnsModel,
                      rows: TechnologiesRowsModel,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onRemoveColumn = innerElementPosition =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      columns: el.columns.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc;
                        }
                        if (innerElement.position > innerElementPosition) {
                          return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                      rows: el.rows.map(row => ({
                        ...row,
                        cells: row.cells.reduce((valueAcc, rowValue) => {
                          if (rowValue.position === innerElementPosition) {
                            return valueAcc;
                          }

                          if (rowValue.position > innerElementPosition) {
                            return valueAcc.concat([{ ...rowValue, position: rowValue.position - 1 }]);
                          }

                          return valueAcc.concat([rowValue]);
                        }, []),
                      })),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onRemoveRow = innerElementPosition =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      rows: el.rows.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc;
                        }
                        if (innerElement.position > innerElementPosition) {
                          return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onColumnChangePosition = ({ currentPosition, newPosition }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  const currentPositionColumn = el?.columns?.find?.(col => col.position === currentPosition);
                  const newPositionColumn = el?.columns?.find?.(col => col.position === newPosition);

                  return elementsAcc.concat([
                    {
                      ...el,
                      columns: el.columns.reduce((colAcc, col) => {
                        if (col.position === currentPosition) {
                          return colAcc.concat({ ...currentPositionColumn, position: newPositionColumn.position });
                        }

                        if (col.position === newPosition) {
                          return colAcc.concat({ ...newPositionColumn, position: currentPositionColumn.position });
                        }

                        return colAcc.concat(col);
                      }, []),

                      rows: el.rows.reduce((rowAcc, row) => {
                        return rowAcc.concat([
                          {
                            ...row,
                            cells: row.cells.reduce((valAcc, value) => {
                              if (value.position === currentPosition) {
                                return valAcc.concat({ ...value, position: newPosition });
                              }

                              if (value.position === newPosition) {
                                return valAcc.concat({ ...value, position: currentPosition });
                              }

                              return valAcc.concat(value);
                            }, []),
                          },
                        ]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onRowChangePosition = ({ currentPosition, newPosition }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  const currentPositionRow = el?.rows?.find?.(row => row.position === currentPosition);
                  const newPositionRow = el?.rows?.find?.(row => row.position === newPosition);

                  return elementsAcc.concat([
                    {
                      ...el,
                      rows: el.rows.reduce((rowAcc, row) => {
                        if (row.position === currentPosition) {
                          return rowAcc.concat({ ...currentPositionRow, position: newPositionRow.position });
                        }

                        if (row.position === newPosition) {
                          return rowAcc.concat({ ...newPositionRow, position: currentPositionRow.position });
                        }

                        return rowAcc.concat(row);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const setPreset = ({ rowPosition, preset }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      rows: el.rows.reduce((rowAcc, row) => {
                        if (row.position === rowPosition) {
                          return rowAcc.concat({
                            ...row,
                            textColor: preset.textColor,
                            backgroundColor: preset.backgroundColor,
                          });
                        }

                        return rowAcc.concat(row);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onCellChange = content =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      rows: el.rows.reduce((rowAcc, row) => {
                        if (row.position === selectedCell?.rowPosition) {
                          return rowAcc.concat({
                            ...row,
                            cells: row.cells.reduce((valAcc, val) => {
                              if (val.position === selectedCell?.columnPosition) {
                                return valAcc.concat({
                                  ...val,
                                  value: { ...val.value, [selectedCell?.language]: content },
                                });
                              }

                              return valAcc.concat(val);
                            }, []),
                          });
                        }

                        return rowAcc.concat(row);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onHeaderCellChange = content =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      columns: el.columns.reduce((colAcc, column) => {
                        if (column.position === selectedCell?.columnPosition) {
                          return colAcc.concat({
                            ...column,
                            name: {
                              ...column.name,
                              [selectedCell?.language]: content,
                            },
                          });
                        }

                        return colAcc.concat(column);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onCellClick = ({ rowPosition, columnPosition, language, headerCell }) =>
    setSelectedCell({ rowPosition, columnPosition, language, headerCell });

  const clearSelectedCell = () => setSelectedCell({});

  return (
    <>
      {isEmpty(selectedCell) ? null : (
        <div onClick={clearSelectedCell} className={styles.modalWrapper}>
          <div onClick={e => e.stopPropagation()} className={styles.quillWrapper}>
            <div className={styles.quillInfoWrapper}>
              {selectedCell?.language ? <span>{languageFlags[selectedCell?.language]}</span> : null}
              {selectedCell?.columnPosition ? (
                <span>
                  {t('table.column')} #{selectedCell?.columnPosition}
                </span>
              ) : null}
              {selectedCell?.rowPosition ? (
                <span>
                  {t('table.row')} #{selectedCell?.rowPosition}
                </span>
              ) : null}
            </div>
            <ReactQuill
              ref={quillRef}
              modules={modules}
              formats={[
                'header',
                'font',
                'background',
                'color',
                'code',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'script',
                'align',
                'direction',
                'link',
                'image',
                'code-block',
                'formula',
                'audio',
                'attachment',
              ]}
              value={
                selectedCell?.headerCell
                  ? String(
                      element?.columns?.find?.(col => col.position === selectedCell?.columnPosition)?.name?.[
                        selectedCell?.language
                      ],
                    )
                  : String(
                      element?.rows
                        ?.find(row => row.position === selectedCell?.rowPosition)
                        ?.cells?.find(val => val.position === selectedCell?.columnPosition)?.value?.[
                        selectedCell?.language
                      ],
                    )
              }
              onChange={
                selectedCell?.headerCell ? content => onHeaderCellChange(content) : content => onCellChange(content)
              }
            />
          </div>
        </div>
      )}

      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            type="text"
            name="subtitle"
            label={t('table.subtitle')}
            value={element?.subtitle}
            icon="font"
            onChange={onTranslatableContentChange}
            disabled={isViewMode}
          />
        </Grid>
      </Form.Group>

      {languages?.map?.(language => (
        <Fragment key={language}>
          {isViewMode ? null : (
            <Form.Group>
              <Grid container stackable columns={2} divided verticalAlign="middle">
                <Grid.Column>
                  <Button onClick={onAddColumn}>
                    <Icon name="plus" />
                    {t('table.addColumn')}
                  </Button>
                  <Button onClick={onAddRow}>
                    <Icon name="plus" />
                    {t('table.addRow')}
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals,no-alert
                      if (confirm(t('table.setTechnologiesTemplateDisclaimer'))) {
                        setTechonologiesTemplate();
                      }
                    }}
                  >
                    <Icon name="connectdevelop" />
                    {t('table.technologies')}
                  </Button>
                </Grid.Column>
              </Grid>
            </Form.Group>
          )}

          <Form.Group>
            <div className="table-responsive">
              <table className="table table-hover table-vertical-center table-bordered">
                <thead>
                  <tr>
                    <th className={clsx(styles.td, styles.buttonCell)}>
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        {languageFlags[language]}
                      </div>
                    </th>

                    {element?.columns
                      ?.sort?.((a, b) => a.position - b.position)
                      ?.map(({ name, position }) => (
                        <th
                          onClick={() =>
                            onCellClick({
                              columnPosition: position,
                              language,
                              headerCell: true,
                            })
                          }
                          className={styles.th}
                          key={`${position}-column-${language}`}
                        >
                          <div className="d-flex flex-column align-items-center">
                            <div onClick={e => e.stopPropagation()} className="d-flex mb-5">
                              <Button
                                className={clsx(styles.removeButton, 'mb-0 mr-1')}
                                color="red"
                                onClick={() => {
                                  // eslint-disable-next-line no-restricted-globals,no-alert
                                  if (confirm(t('table.removeColumnDisclaimer'))) {
                                    onRemoveColumn(position);
                                  }
                                }}
                              >
                                <Icon name="trash alternate" className="m-0" />
                              </Button>
                              <Dropdown
                                disabled={isViewMode}
                                placeholder="Position"
                                selection
                                fluid
                                value={position}
                                onChange={(e, { value }) =>
                                  onColumnChangePosition({
                                    currentPosition: position,
                                    newPosition: value,
                                  })
                                }
                                options={columnDropdownPositions}
                              />
                            </div>

                            {/* eslint-disable-next-line react/no-danger */}
                            <span dangerouslySetInnerHTML={{ __html: name[language] }} />
                          </div>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {element?.rows
                    ?.sort?.((a, b) => a.position - b.position)
                    ?.map(rowData => (
                      <tr
                        key={`${rowData.position}-row-${language}`}
                        style={{ color: rowData?.textColor, backgroundColor: rowData?.backgroundColor }}
                      >
                        <td className={styles.buttonCell}>
                          <div className="d-flex">
                            <Button
                              color="red"
                              className={styles.removeButton}
                              onClick={() => {
                                // eslint-disable-next-line no-restricted-globals,no-alert
                                if (confirm(t('table.removeRowDisclaimer'))) {
                                  onRemoveRow(rowData.position);
                                }
                              }}
                            >
                              <Icon name="trash alternate" className="m-0" />
                            </Button>

                            <Dropdown icon="adjust" button className="icon" disabled={isViewMode}>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  className="d-flex align-items-center"
                                  onClick={() =>
                                    setPreset({ rowPosition: rowData.position, preset: ROW_PRESETS.PRESET_1 })
                                  }
                                >
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: ROW_PRESETS.PRESET_1.textColor,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: ROW_PRESETS.PRESET_1.backgroundColor,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                  {ROW_PRESETS.PRESET_1.name}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  className="d-flex align-items-center"
                                  onClick={() =>
                                    setPreset({ rowPosition: rowData.position, preset: ROW_PRESETS.PRESET_2 })
                                  }
                                >
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: ROW_PRESETS.PRESET_2.backgroundColor,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: ROW_PRESETS.PRESET_2.textColor,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                  {ROW_PRESETS.PRESET_2.name}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  className="d-flex align-items-center"
                                  onClick={() =>
                                    setPreset({ rowPosition: rowData.position, preset: ROW_PRESETS.PRESET_3 })
                                  }
                                >
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: ROW_PRESETS.PRESET_3.backgroundColor,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: ROW_PRESETS.PRESET_3.textColor,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                  {ROW_PRESETS.PRESET_3.name}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown
                              disabled={isViewMode}
                              placeholder="Position"
                              selection
                              fluid
                              value={rowData.position}
                              onChange={(e, { value }) =>
                                onRowChangePosition({
                                  currentPosition: rowData.position,
                                  newPosition: value,
                                })
                              }
                              options={rowDropdownPositions}
                            />
                          </div>
                        </td>

                        {rowData?.cells
                          ?.sort?.((a, b) => a.position - b.position)
                          ?.map(cell => (
                            <td
                              className={styles.td}
                              onClick={() =>
                                onCellClick({
                                  rowPosition: rowData.position,
                                  columnPosition: cell.position,
                                  language,
                                })
                              }
                              align="center"
                              key={`${cell.position}-cell-${language}`}
                            >
                              <span
                                /* eslint-disable-next-line react/no-danger */
                                dangerouslySetInnerHTML={{ __html: cell.value[language] }}
                              />
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Form.Group>
        </Fragment>
      ))}
    </>
  );
};

TableElement.propTypes = {
  element: shape({}),
  actions: shape({}),
  elementErrors: shape({}),
  onChange: func,
  sectionNumber: number,
  isViewMode: bool,
};

TableElement.defaultProps = {
  element: {},
  actions: {},
  elementErrors: {},
  onChange: () => {},
  sectionNumber: null,
  isViewMode: false,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      uploadSingleFileAction,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(TableElement);
