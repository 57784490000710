import { Layout } from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements/components/GridElement/consts';

const GirdElementModel = {
  layout: Layout.GRID_TILES,
  items: [],
  subtitle: {
    pl: '',
    en: '',
  },
  defaultGrid: {
    mobile: 12,
    tablet: 6,
    desktop: 4,
  },
};

export default GirdElementModel;
