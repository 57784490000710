import React from 'react';
import { Form, Grid, Image, Input, Segment } from 'semantic-ui-react';
import { TranslatableTextField } from '../../../../../components';
import VALID_IMAGE_MIME_TYPES from '../../../../models/ImageTypes';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';

class ImageElement extends React.PureComponent {
  elementImageRef = React.createRef();

  constructor(props) {
    super(props);

    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
  }

  onChange(e, { value, name }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: value,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onDescriptionChange(e, { value, name, language }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: {
                        ...el[name],
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onUploadImage(e) {
    const { onChange, sectionNumber, element } = this.props;

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        return onChange(prevState => ({
          offerSections: prevState.offerSections.reduce((acc, section) => {
            if (section.position === sectionNumber) {
              return acc.concat([
                {
                  ...section,
                  elements: section.elements.reduce((elementsAcc, el) => {
                    if (element.position === el.position) {
                      return elementsAcc.concat([
                        {
                          ...el,
                          file: {
                            fileName: file.name,
                            delete: false,
                            base64: reader.result,
                          },
                        },
                      ]);
                    }
                    return elementsAcc.concat([el]);
                  }, []),
                },
              ]);
            }
            return acc.concat([section]);
          }, []),
        }));
      };

      return reader.readAsDataURL(file);
    }
  }

  render() {
    const { element, elementErrors, t, isViewMode } = this.props;

    return (
      <>
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              type="text"
              name="description"
              label={t('image.description')}
              tooltip={t('tooltip.image.description')}
              errorMessage={elementErrors?.description}
              value={element?.description}
              icon="font"
              onChange={this.onDescriptionChange}
              disabled={isViewMode}
            />
          </Grid>
        </Form.Group>
        <Segment>
          <Form.Group>
            <Grid container stackable columns={isViewMode ? 1 : 2} divided>
              <Grid.Column>
                <Image src={element?.file?.base64 || element.imageUrl} size="medium" rounded bordered />
              </Grid.Column>

              {isViewMode ? null : (
                <Grid.Column>
                  <Input
                    ref={this.elementImageRef}
                    onChange={this.onUploadImage}
                    type="file"
                    accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                  />
                </Grid.Column>
              )}
            </Grid>
          </Form.Group>
        </Segment>
      </>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(ImageElement);
