import { GET_MENU_LIST_INIT, GET_MENU_LIST_FAILURE, GET_MENU_LIST_SUCCESS } from '@store/action-types/menu';
import Client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getMenuListInit = () => ({ type: GET_MENU_LIST_INIT });
const getMenuListSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_MENU_LIST_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getMenuListFailure = error => ({ type: GET_MENU_LIST_FAILURE, payload: { error } });

const getMenuList = ({ activeFilters, pagination, order } = {}) => async dispatch => {
  dispatch(getMenuListInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const menu = await Client.getMenuList({ params: activeFilters || urlQueryParams?.all });
    dispatch(
      getMenuListSuccess({
        response: menu.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getMenuListFailure(error));
  }
};

export default getMenuList;
