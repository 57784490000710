import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, number, shape } from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';
import { Select } from '@shared/components';
import TRANSLATIONS from '@translations/translationNamespaces';
import { ContactFormOptions } from './consts';

const ContactFormElement = ({ element, elementErrors, onChange, sectionNumber, isViewMode }) => {
  const { t } = useTranslation(TRANSLATIONS.OFFERS);

  const onCodeNameChange = (e, { name, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: value,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  return (
    <Form.Group>
      <Grid container stackable columns={1} divided>
        <Select
          disabled={isViewMode}
          type="text"
          label={t('contactForm.codeName')}
          name="codeName"
          value={element?.codeName}
          errorMessage={elementErrors?.codeName}
          onChange={onCodeNameChange}
          options={ContactFormOptions}
          placeholder={t('contactForm.placeholder')}
        />
      </Grid>
    </Form.Group>
  );
};

ContactFormElement.propTypes = {
  element: shape({}),
  elementErrors: shape({}),
  onChange: func,
  sectionNumber: number,
  isViewMode: bool,
};

ContactFormElement.defaultProps = {
  element: {},
  elementErrors: {},
  onChange: () => {},
  sectionNumber: null,
  isViewMode: false,
};

export default ContactFormElement;
