import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { TagsForm } from '@screens/blog/components';

const Main = ({ actions, tag, status, errors, permittedPermissions }) => {
  const params = useParams();

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getTagById(params.id);
  });

  const handleRefresh = () => actions.getTagById(params.id);

  return (
    <TagsForm
      isViewMode={!isUserPermitted(PERMISSIONS.EDIT_BLOG)}
      onSave={actions.editTag}
      onRefresh={handleRefresh}
      tag={tag}
      isLoading={status === FETCH_STATUS.LOADING || status === FETCH_STATUS.IDLE}
      errors={errors}
      isEdit
    />
  );
};

Main.propTypes = {
  actions: shape({ getTagById: func, editTag: func }).isRequired,
  tag: shape({}),
  status: string,
  errors: shape({}),
  permittedPermissions: arrayOf(shape({})),
};
Main.defaultProps = {
  tag: {},
  status: undefined,
  errors: {},
  permittedPermissions: [],
};

export default Main;
