import React from 'react';
import clsx from 'clsx';
import { Form, Grid, Icon, Input, Label } from 'semantic-ui-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  icon: {
    '&:hover': { cursor: 'pointer' },
  },
});

class InputField extends React.PureComponent {
  render() {
    const {
      name,
      value,
      label,
      onChange,
      type,
      disabled,
      fluid,
      icon,
      iconPosition,
      placeholder,
      errorMessage,
      labelClassName,
      required,
      tooltip,
      classes,
      onBlur,
    } = this.props;

    return (
      <Grid.Column>
        <Form.Field>
          {errorMessage && (
            <Label color="red" className={clsx('mb-5', labelClassName)}>
              {errorMessage}
            </Label>
          )}
          {label ? (
            <Form.Field className="d-flex justify-content-between">
              <label>
                {label}{' '}
                {tooltip && (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                  >
                    <Icon className={classes.icon} name="info circle" />
                  </OverlayTrigger>
                )}
              </label>
            </Form.Field>
          ) : null}
          <Input
            icon={icon}
            iconPosition={iconPosition || 'left'}
            fluid={fluid}
            disabled={disabled}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            label={required ? { icon: 'asterisk' } : undefined}
            labelPosition={required ? 'right corner' : undefined}
            onBlur={onBlur}
          />
        </Form.Field>
      </Grid.Column>
    );
  }
}

export default withStyles(styles)(InputField);
