import {
  GET_MENU_LIST_INIT,
  GET_MENU_LIST_SUCCESS,
  GET_MENU_LIST_FAILURE,
  GET_MENU_ITEM_INIT,
  GET_MENU_ITEM_FAILURE,
  GET_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_INIT,
  DELETE_MENU_ITEM_FAILURE,
  EDIT_MENU_ITEM_INIT,
  EDIT_MENU_ITEM_SUCCESS,
  EDIT_MENU_ITEM_FAILURE,
  CREATE_MENU_ITEM_INIT,
  CREATE_MENU_ITEM_FAILURE,
  CREATE_MENU_ITEM_SUCCESS,
  CLEAR_STATUS,
} from '@store/action-types/menu';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  queryParams: {},
  list: {
    data: [],
    status: FETCH_STATUS.IDLE,
  },
  menuItem: {},
  status: FETCH_STATUS.IDLE,
  error: undefined,
};

const menuReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MENU_LIST_INIT: {
      return {
        ...state,
        queryParams: {},
        list: {
          ...state.list,
          status: FETCH_STATUS.LOADING,
        },
      };
    }
    case GET_MENU_LIST_SUCCESS: {
      return {
        ...state,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        list: {
          ...state.list,
          data: payload?.items,
          status: FETCH_STATUS.SUCCESS,
        },
        error: undefined,
      };
    }
    case GET_MENU_LIST_FAILURE: {
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          status: FETCH_STATUS.FAILURE,
        },
        error: payload.error,
      };
    }
    case GET_MENU_ITEM_INIT: {
      return {
        ...state,
        menuItem: {},
        status: FETCH_STATUS.LOADING,
      };
    }
    case GET_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        menuItem: payload.menuItem,
        status: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case GET_MENU_ITEM_FAILURE: {
      return {
        ...state,
        menuItem: {},
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case DELETE_MENU_ITEM_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: {},
      };
    }
    case DELETE_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case DELETE_MENU_ITEM_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case EDIT_MENU_ITEM_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: {},
      };
    }
    case EDIT_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case EDIT_MENU_ITEM_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case CREATE_MENU_ITEM_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: {},
      };
    }
    case CREATE_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case CREATE_MENU_ITEM_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case CLEAR_STATUS: {
      return {
        ...state,
        status: undefined,
        menuItem: {},
      };
    }
    default:
      return state;
  }
};

export default menuReducer;
