import React, { useRef, useState } from 'react';
import { Button, Form, Grid, Header, Icon, Image, Input, Label, Segment } from 'semantic-ui-react';
import { TranslatableRichTextField } from '@shared/components/translatable';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import clsx from 'clsx';
import {
  SuccessStoryElementItemModel,
  SuccessStoryElementTagModel,
} from '@screens/offers/NewOfferForm/OfferForm/models/elementModels';
import VALID_IMAGE_MIME_TYPES from '@screens/offers/NewOfferForm/OfferForm/models/ImageTypes';
import { SuccessStoryElementTag, SuccessStoryElementItem } from './components';

const SuccessStoryElement = ({ element, elementErrors, onChange, sectionNumber, isViewMode }) => {
  const { t } = useTranslation(TRANSLATIONS.OFFERS);
  const imageRef = useRef();
  const [isTagWrapperVisible, setIsTagWrapperVisible] = useState(false);

  const toggleTagWrapperExpand = () => setIsTagWrapperVisible(p => !p);

  const onUploadImage = e => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        return onChange(prevState => ({
          offerSections: prevState.offerSections.reduce((acc, section) => {
            if (section.position === sectionNumber) {
              return acc.concat([
                {
                  ...section,
                  elements: section.elements.reduce((elementsAcc, el) => {
                    if (element.position === el.position) {
                      return elementsAcc.concat([
                        {
                          ...el,
                          file: {
                            fileName: file.name,
                            delete: false,
                            base64: reader.result,
                          },
                        },
                      ]);
                    }
                    return elementsAcc.concat([el]);
                  }, []),
                },
              ]);
            }
            return acc.concat([section]);
          }, []),
        }));
      };

      return reader.readAsDataURL(file);
    }

    return null;
  };

  const onTranslatableContentChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: {
                        ...el[name],
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onAddTag = () => {
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      tags: el.tags.concat([
                        {
                          ...SuccessStoryElementTagModel,
                          position: el?.tags?.[el?.tags?.length - 1]?.position + 1 || 1,
                          backendIndex: el?.tags?.length || 0,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
    setIsTagWrapperVisible(true);
  };

  const onAddItem = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.concat([
                        {
                          ...SuccessStoryElementItemModel,
                          position: el?.items?.[el?.items?.length - 1]?.position + 1 || 1,
                          backendIndex: el?.items?.length || 0,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const renderTags = () => {
    if (isEmpty(element.tags)) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center p-3">
          <Header className="text-center" style={{ height: 'fit-content' }}>
            {t('successStory.noTags')}
          </Header>
          <Button onClick={onAddTag}>{t('successStory.addTag')}</Button>
        </div>
      );
    }

    return element.tags.map((tag, index) => (
      <SuccessStoryElementTag
        key={tag.position}
        element={tag}
        onChange={onChange}
        sectionNumber={sectionNumber}
        isViewMode={isViewMode}
        elementIndex={index}
        elementPosition={element.position}
        isArrowDownDisabled={tag.position === element.tags[element.tags.length - 1].position}
        isArrowUpDisabled={tag.position === element.tags[0].position}
      />
    ));
  };

  return (
    <>
      <Form.Group>
        <Grid container stackable columns={isViewMode ? 1 : 2} divided>
          <Grid.Column>
            <Image src={element?.file?.base64 || element.imageUrl} size="medium" rounded bordered />
          </Grid.Column>

          {isViewMode ? null : (
            <Grid.Column>
              <Input ref={imageRef} onChange={onUploadImage} type="file" accept={VALID_IMAGE_MIME_TYPES.join(', ')} />
            </Grid.Column>
          )}
        </Grid>
      </Form.Group>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            type="text"
            name="description"
            label={t('successStory.description')}
            errorMessage={elementErrors?.description}
            value={element?.description}
            icon="font"
            onChange={onTranslatableContentChange}
            disabled={isViewMode}
          />
        </Grid>
      </Form.Group>
      <Segment>
        <Grid container stackable columns={1} divided>
          <Grid.Row className={clsx('pl-4 d-flex align-items-center', isTagWrapperVisible ? 'mb-8' : 'mb-0')}>
            <Label>
              {t('successStory.tagsWrapper')} {element?.tags?.length ? `(${element?.tags?.length})` : null}
            </Label>
            {isViewMode ? null : (
              <>
                <Button className="mr-2 ml-2" color="green" icon onClick={toggleTagWrapperExpand}>
                  {isTagWrapperVisible ? <Icon name="angle double up" /> : <Icon name="angle double down" />}
                </Button>

                <Button className="ml-auto" icon onClick={onAddTag}>
                  <Icon name="plus" />
                </Button>
              </>
            )}
          </Grid.Row>
        </Grid>
        {isTagWrapperVisible ? renderTags() : null}
      </Segment>
      {isEmpty(element.items)
        ? null
        : element.items.map((item, index) => (
            <SuccessStoryElementItem
              key={item.position}
              element={item}
              onChange={onChange}
              sectionNumber={sectionNumber}
              isViewMode={isViewMode}
              elementIndex={index}
              elementPosition={element.position}
              isArrowDownDisabled={item.position === element.items[element.items.length - 1].position}
              isArrowUpDisabled={item.position === element.items[0].position}
            />
          ))}
      <div className="d-flex justify-content-center align-items-center p-4">
        <Button onClick={onAddItem}>
          <Icon name="plus" />
          {t('successStory.add')}
        </Button>
      </div>
    </>
  );
};

export default SuccessStoryElement;
