import { refreshToken, loginUser } from '@api-sdk/api/login';
import { API_BASE_URL } from './consts';
import { getOffers, getSingleOffer, createOffer, cloneOffer, editOffer, deleteOffer } from './api/offers';
import { getFaq, getSingleFaq, deleteFaq, editFaq, createFaq } from './api/faq';
import { getCountries } from './api/investors';
import { resetPassword, submitResetPassword, validateResetPasswordToken, getUserInfo } from './api/user';
import { getInvestments, getSingleInvestment } from './api/investments';
import { getOrders, getSingleOrder, editOrder, rejectOrder, acceptOrder, createOrder } from './api/orders';
import { getTransactions, getSingleTransaction, acceptTransaction, extendTransaction } from './api/transactions';
import { getConfig, editConfig } from './api/settings';
import { getPages, getSinglePage, createPage, editPage, deletePage } from './api/pages';
import { getSeries, getSingleSeries, createSeries, deleteSeries, editSeries } from './api/series';
import { revalidateAllOffers, revalidateOffer } from './api/revalidate';
import {
  getAgreements,
  getSingleAgreement,
  createAgreement,
  editAgreement,
  deleteAgreement,
  getTypesAndTargets,
  getUserAgreements,
} from './api/agreements';
import { getSlider, getSingleSlider, deleteSlider, editSlider, createSlider } from './api/slider';
import {
  createRole,
  deleteRole,
  editRole,
  getRoles,
  getSingleRole,
  addPermissionToRole,
  getRoleUsers,
  getRolePermissions,
  removeUserFromRole,
  addUserToRole,
  removePermissionFromRole,
} from './api/roles';
import {
  getPermissions,
  getSinglePermission,
  createPermission,
  deletePermission,
  editPermission,
  permissionCheck,
} from './api/permissions';
import {
  createUser,
  getUsers,
  getPersonalPermissions,
  getSingleUser,
  editUser,
  deleteUser,
  getUserRoles,
  removePersonalPermissionFromUser,
  addPersonalPermissionToUser,
  addRoleToUser,
  removeRoleFromUser,
} from './api/user-management';
import uploadSingleFile from './api/upload-single-file';
import uploadSinglePhoto from './api/upload-single-photo';
import {
  getTemplatesConfig,
  getTemplates,
  getSingleTemplate,
  editTemplate,
  sendTestMessage,
} from './api/email-templates';
import { getSplashScreen } from './api/splash-screen';
import {
  getStaticContent,
  getStaticContentElement,
  getStaticContentParent,
  editStaticContent,
} from './api/static-content';
import { getFeaturedOffers, getSingleFeaturedOffer, editFeaturedOffer } from './api/featured-offers';
import {
  getPosts,
  getSinglePost,
  editPost,
  deletePost,
  createPost,
  getTagById,
  editTag,
  deleteTag,
  getTags,
  createTag,
} from './api/blog';
import { getPaypalConfig, editPaypalConfig } from './api/paypal';
import { createMenuItem, deleteMenuItem, editMenuItem, getMenuList, getMenuItem } from './api/menu';

const registerModules = client => {
  client.register({
    action: getInvestments,
    alias: 'getInvestments',
    requestParams: { url: `${API_BASE_URL}/admin/investment`, method: 'GET' },
  });

  client.register({
    action: getSingleInvestment,
    alias: 'getSingleInvestment',
    requestParams: { url: `${API_BASE_URL}/admin/investment`, method: 'GET' },
  });

  client.register({
    action: getTransactions,
    alias: 'getTransactions',
    requestParams: { url: `${API_BASE_URL}/admin/transaction`, method: 'GET' },
  });

  client.register({
    action: getSingleTransaction,
    alias: 'getSingleTransaction',
    requestParams: { url: `${API_BASE_URL}/admin/transaction`, method: 'GET' },
  });

  client.register({
    action: acceptTransaction,
    alias: 'acceptTransaction',
    requestParams: { url: `${API_BASE_URL}/admin/transaction`, method: 'PUT' },
  });

  client.register({
    action: extendTransaction,
    alias: 'extendTransaction',
    requestParams: { url: `${API_BASE_URL}/admin/transaction`, method: 'PUT' },
  });

  client.register({
    action: getOffers,
    alias: 'getOffers',
    requestParams: { url: `${API_BASE_URL}/admin/offer`, method: 'GET' },
  });

  client.register({
    action: getSingleOffer,
    alias: 'getSingleOffer',
    requestParams: { url: `${API_BASE_URL}/admin/offer`, method: 'GET' },
  });

  client.register({
    action: createOffer,
    alias: 'createOffer',
    requestParams: { url: `${API_BASE_URL}/admin/offer`, method: 'POST' },
  });

  client.register({
    action: editOffer,
    alias: 'editOffer',
    requestParams: { url: `${API_BASE_URL}/admin/offer`, method: 'PUT' },
  });

  client.register({
    action: cloneOffer,
    alias: 'cloneOffer',
    requestParams: { url: `${API_BASE_URL}/admin/offer`, method: 'POST' },
  });

  client.register({
    action: deleteOffer,
    alias: 'deleteOffer',
    requestParams: { url: `${API_BASE_URL}/admin/offer`, method: 'DELETE' },
  });

  client.register({
    action: getPages,
    alias: 'getPages',
    requestParams: { url: `${API_BASE_URL}/admin/page`, method: 'GET' },
  });

  client.register({
    action: getSinglePage,
    alias: 'getSinglePage',
    requestParams: { url: `${API_BASE_URL}/admin/page`, method: 'GET' },
  });

  client.register({
    action: createPage,
    alias: 'createPage',
    requestParams: { url: `${API_BASE_URL}/admin/page`, method: 'POST' },
  });

  client.register({
    action: editPage,
    alias: 'editPage',
    requestParams: { url: `${API_BASE_URL}/admin/page`, method: 'PUT' },
  });

  client.register({
    action: deletePage,
    alias: 'deletePage',
    requestParams: { url: `${API_BASE_URL}/admin/page`, method: 'DELETE' },
  });

  client.register({
    action: getCountries,
    alias: 'getCountries',
    requestParams: { url: `${API_BASE_URL}/investor/register`, method: 'GET' },
  });

  client.register({
    action: resetPassword,
    alias: 'resetPassword',
    requestParams: { url: `${API_BASE_URL}/user/reset-password`, method: 'POST' },
  });

  client.register({
    action: submitResetPassword,
    alias: 'submitResetPassword',
    requestParams: { url: `${API_BASE_URL}/user/reset-password`, method: 'POST' },
  });

  client.register({
    action: validateResetPasswordToken,
    alias: 'validateResetPasswordToken',
    requestParams: { url: `${API_BASE_URL}/user/reset-password`, method: 'GET' },
  });

  client.register({
    action: getFaq,
    alias: 'getFaq',
    requestParams: { url: `${API_BASE_URL}/admin/faq`, method: 'GET' },
  });

  client.register({
    action: getSingleFaq,
    alias: 'getSingleFaq',
    requestParams: { url: `${API_BASE_URL}/admin/faq`, method: 'GET' },
  });

  client.register({
    action: deleteFaq,
    alias: 'deleteFaq',
    requestParams: { url: `${API_BASE_URL}/admin/faq`, method: 'DELETE' },
  });

  client.register({
    action: createFaq,
    alias: 'createFaq',
    requestParams: { url: `${API_BASE_URL}/admin/faq`, method: 'POST' },
  });

  client.register({
    action: editFaq,
    alias: 'editFaq',
    requestParams: { url: `${API_BASE_URL}/admin/faq`, method: 'PUT' },
  });

  client.register({
    action: getMenuList,
    alias: 'getMenuList',
    requestParams: { url: `${API_BASE_URL}/admin/menu`, method: 'GET' },
  });

  client.register({
    action: getMenuItem,
    alias: 'getMenuItem',
    requestParams: { url: `${API_BASE_URL}/admin/menu`, method: 'GET' },
  });

  client.register({
    action: deleteMenuItem,
    alias: 'deleteMenuItem',
    requestParams: { url: `${API_BASE_URL}/admin/menu`, method: 'DELETE' },
  });

  client.register({
    action: createMenuItem,
    alias: 'createMenuItem',
    requestParams: { url: `${API_BASE_URL}/admin/menu`, method: 'POST' },
  });

  client.register({
    action: editMenuItem,
    alias: 'editMenuItem',
    requestParams: { url: `${API_BASE_URL}/admin/menu`, method: 'PUT' },
  });

  client.register({
    action: getPosts,
    alias: 'getPosts',
    requestParams: { url: `${API_BASE_URL}/admin/blog`, method: 'GET' },
  });

  client.register({
    action: getSinglePost,
    alias: 'getSinglePost',
    requestParams: { url: `${API_BASE_URL}/admin/blog`, method: 'GET' },
  });

  client.register({
    action: deletePost,
    alias: 'deletePost',
    requestParams: { url: `${API_BASE_URL}/admin/blog`, method: 'DELETE' },
  });

  client.register({
    action: createPost,
    alias: 'createPost',
    requestParams: { url: `${API_BASE_URL}/admin/blog`, method: 'POST' },
  });

  client.register({
    action: editPost,
    alias: 'editPost',
    requestParams: { url: `${API_BASE_URL}/admin/blog`, method: 'PUT' },
  });

  client.register({
    action: getTags,
    alias: 'getTags',
    requestParams: { url: `${API_BASE_URL}/admin/tags`, method: 'GET' },
  });

  client.register({
    action: getTagById,
    alias: 'getTagById',
    requestParams: { url: `${API_BASE_URL}/admin/tags`, method: 'GET' },
  });

  client.register({
    action: deleteTag,
    alias: 'deleteTag',
    requestParams: { url: `${API_BASE_URL}/admin/tags`, method: 'DELETE' },
  });

  client.register({
    action: createTag,
    alias: 'createTag',
    requestParams: { url: `${API_BASE_URL}/admin/tags`, method: 'POST' },
  });

  client.register({
    action: editTag,
    alias: 'editTag',
    requestParams: { url: `${API_BASE_URL}/admin/tags`, method: 'PUT' },
  });

  client.register({
    action: getAgreements,
    alias: 'getAgreements',
    requestParams: { url: `${API_BASE_URL}/admin/agreements`, method: 'GET' },
  });

  client.register({
    action: getSingleAgreement,
    alias: 'getSingleAgreement',
    requestParams: { url: `${API_BASE_URL}/admin/agreement`, method: 'GET' },
  });

  client.register({
    action: createAgreement,
    alias: 'createAgreement',
    requestParams: { url: `${API_BASE_URL}/admin/agreements`, method: 'POST' },
  });

  client.register({
    action: editAgreement,
    alias: 'editAgreement',
    requestParams: { url: `${API_BASE_URL}/admin/agreements`, method: 'PUT' },
  });

  client.register({
    action: deleteAgreement,
    alias: 'deleteAgreement',
    requestParams: { url: `${API_BASE_URL}/admin/agreements`, method: 'DELETE' },
  });

  client.register({
    action: getTypesAndTargets,
    alias: 'getTypesAndTargets',
    requestParams: { url: `${API_BASE_URL}/admin/agreements/typesAndTargets`, method: 'GET' },
  });

  client.register({
    action: getUserAgreements,
    alias: 'getUserAgreements',
    requestParams: { url: `${API_BASE_URL}/admin/user-agreement`, method: 'GET' },
  });

  client.register({
    action: loginUser,
    alias: 'loginUser',
    requestParams: { url: `${API_BASE_URL}/token`, method: 'POST', isPrivate: true },
  });

  client.register({
    action: refreshToken,
    alias: 'refreshToken',
    requestParams: {
      url: `${API_BASE_URL}/token`,
      method: 'POST',
      isPrivate: true,
      disableAuthorizationHeader: true,
    },
  });

  client.register({
    action: getUserInfo,
    alias: 'getUserInfo',
    requestParams: { url: `${API_BASE_URL}/user/me`, method: 'GET', isPrivate: true },
  });

  client.register({
    action: getSeries,
    alias: 'getSeries',
    requestParams: { url: `${API_BASE_URL}/admin/share/series`, method: 'GET' },
  });

  client.register({
    action: createSeries,
    alias: 'createSeries',
    requestParams: { url: `${API_BASE_URL}/admin/share/series`, method: 'POST' },
  });

  client.register({
    action: getSingleSeries,
    alias: 'getSingleSeries',
    requestParams: { url: `${API_BASE_URL}/admin/share/series`, method: 'GET' },
  });

  client.register({
    action: editSeries,
    alias: 'editSeries',
    requestParams: { url: `${API_BASE_URL}/admin/share/series`, method: 'PUT' },
  });

  client.register({
    action: deleteSeries,
    alias: 'deleteSeries',
    requestParams: { url: `${API_BASE_URL}/admin/share/series`, method: 'DELETE' },
  });

  client.register({
    action: getOrders,
    alias: 'getOrders',
    requestParams: { url: `${API_BASE_URL}/admin/order`, method: 'GET' },
  });

  client.register({
    action: getSingleOrder,
    alias: 'getSingleOrder',
    requestParams: { url: `${API_BASE_URL}/admin/order`, method: 'GET' },
  });

  client.register({
    action: editOrder,
    alias: 'editOrder',
    requestParams: { url: `${API_BASE_URL}/admin/order`, method: 'PUT' },
  });

  client.register({
    action: acceptOrder,
    alias: 'acceptOrder',
    requestParams: { url: `${API_BASE_URL}/admin/order`, method: 'POST' },
  });

  client.register({
    action: rejectOrder,
    alias: 'rejectOrder',
    requestParams: { url: `${API_BASE_URL}/admin/order`, method: 'POST' },
  });

  client.register({
    action: getPermissions,
    alias: 'getPermissions',
    requestParams: { url: `${API_BASE_URL}/admin/permission`, method: 'GET' },
  });

  client.register({
    action: getSinglePermission,
    alias: 'getSinglePermission',
    requestParams: { url: `${API_BASE_URL}/admin/permission`, method: 'GET' },
  });

  client.register({
    action: createPermission,
    alias: 'createPermission',
    requestParams: { url: `${API_BASE_URL}/admin/permission`, method: 'POST' },
  });

  client.register({
    action: editPermission,
    alias: 'editPermission',
    requestParams: { url: `${API_BASE_URL}/admin/permission`, method: 'PUT' },
  });

  client.register({
    action: deletePermission,
    alias: 'deletePermission',
    requestParams: { url: `${API_BASE_URL}/admin/permission`, method: 'DELETE' },
  });

  client.register({
    action: getRoles,
    alias: 'getRoles',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'GET' },
  });

  client.register({
    action: getSingleRole,
    alias: 'getSingleRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'GET' },
  });

  client.register({
    action: createRole,
    alias: 'createRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'POST' },
  });

  client.register({
    action: editRole,
    alias: 'editRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'PUT' },
  });

  client.register({
    action: deleteRole,
    alias: 'deleteRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'DELETE' },
  });

  client.register({
    action: getRolePermissions,
    alias: 'getRolePermissions',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'GET' },
  });

  client.register({
    action: addPermissionToRole,
    alias: 'addPermissionToRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'PUT' },
  });

  client.register({
    action: removePermissionFromRole,
    alias: 'removePermissionFromRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'DELETE' },
  });

  client.register({
    action: getRoleUsers,
    alias: 'getRoleUsers',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'GET' },
  });

  client.register({
    action: addUserToRole,
    alias: 'addUserToRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'PUT' },
  });

  client.register({
    action: removeUserFromRole,
    alias: 'removeUserFromRole',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'DELETE' },
  });

  client.register({
    action: getSingleUser,
    alias: 'getSingleUser',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'GET' },
  });

  client.register({
    action: editUser,
    alias: 'editUser',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'PUT' },
  });

  client.register({
    action: deleteUser,
    alias: 'deleteUser',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'DELETE' },
  });

  client.register({
    action: createUser,
    alias: 'createUser',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'POST' },
  });

  client.register({
    action: getUsers,
    alias: 'getUsers',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'GET' },
  });

  client.register({
    action: getPersonalPermissions,
    alias: 'getPersonalPermissions',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'GET' },
  });

  client.register({
    action: getUserRoles,
    alias: 'getUserRoles',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'GET' },
  });

  client.register({
    action: removePersonalPermissionFromUser,
    alias: 'removePersonalPermissionFromUser',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'DELETE' },
  });

  client.register({
    action: addPersonalPermissionToUser,
    alias: 'addPersonalPermissionToUser',
    requestParams: { url: `${API_BASE_URL}/admin/user`, method: 'PUT' },
  });

  client.register({
    action: addRoleToUser,
    alias: 'addRoleToUser',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'PUT' },
  });

  client.register({
    action: removeRoleFromUser,
    alias: 'removeRoleFromUser',
    requestParams: { url: `${API_BASE_URL}/admin/role`, method: 'DELETE' },
  });

  client.register({
    action: uploadSingleFile,
    alias: 'uploadSingleFile',
    requestParams: { url: `${API_BASE_URL}/admin/common/upload-file`, method: 'POST' },
  });

  client.register({
    action: uploadSinglePhoto,
    alias: 'uploadSinglePhoto',
    requestParams: { url: `${API_BASE_URL}/admin/common/upload-photo`, method: 'POST' },
  });

  client.register({
    action: getTemplates,
    alias: 'getTemplates',
    requestParams: { url: `${API_BASE_URL}/admin/email/template`, method: 'GET' },
  });

  client.register({
    action: getTemplatesConfig,
    alias: 'getTemplatesConfig',
    requestParams: { url: `${API_BASE_URL}/admin/email/template/config`, method: 'GET' },
  });

  client.register({
    action: getSingleTemplate,
    alias: 'getSingleTemplate',
    requestParams: { url: `${API_BASE_URL}/admin/email/template`, method: 'GET' },
  });

  client.register({
    action: editTemplate,
    alias: 'editTemplate',
    requestParams: { url: `${API_BASE_URL}/admin/email/template`, method: 'PUT' },
  });

  client.register({
    action: sendTestMessage,
    alias: 'sendTestMessage',
    requestParams: { url: `${API_BASE_URL}/admin/email/template/test-message`, method: 'POST' },
  });

  client.register({
    action: getSplashScreen,
    alias: 'getSplashScreen',
    requestParams: { url: `${API_BASE_URL}/admin/splash-screen`, method: 'GET' },
  });

  client.register({
    action: getConfig,
    alias: 'getConfig',
    requestParams: { url: `${API_BASE_URL}/admin/config`, method: 'GET' },
  });

  client.register({
    action: editConfig,
    alias: 'editConfig',
    requestParams: { url: `${API_BASE_URL}/admin/config`, method: 'PUT' },
  });

  client.register({
    action: getStaticContent,
    alias: 'getStaticContent',
    requestParams: { url: `${API_BASE_URL}/admin/static-content/list`, method: 'GET' },
  });

  client.register({
    action: getStaticContentParent,
    alias: 'getStaticContentParent',
    requestParams: { url: `${API_BASE_URL}/admin/static-content/list`, method: 'GET' },
  });

  client.register({
    action: getStaticContentElement,
    alias: 'getStaticContentElement',
    requestParams: { url: `${API_BASE_URL}/admin/static-content/element`, method: 'GET' },
  });

  client.register({
    action: editStaticContent,
    alias: 'editStaticContent',
    requestParams: { url: `${API_BASE_URL}/admin/static-content/element`, method: 'PUT' },
  });

  client.register({
    action: getSlider,
    alias: 'getSlider',
    requestParams: { url: `${API_BASE_URL}/admin/slider`, method: 'GET' },
  });

  client.register({
    action: getSingleSlider,
    alias: 'getSingleSlider',
    requestParams: { url: `${API_BASE_URL}/admin/slider`, method: 'GET' },
  });

  client.register({
    action: deleteSlider,
    alias: 'deleteSlider',
    requestParams: { url: `${API_BASE_URL}/admin/slider`, method: 'DELETE' },
  });

  client.register({
    action: editSlider,
    alias: 'editSlider',
    requestParams: { url: `${API_BASE_URL}/admin/slider`, method: 'PUT' },
  });

  client.register({
    action: createSlider,
    alias: 'createSlider',
    requestParams: { url: `${API_BASE_URL}/admin/slider`, method: 'POST' },
  });

  client.register({
    action: getFeaturedOffers,
    alias: 'getFeaturedOffers',
    requestParams: { url: `${API_BASE_URL}/admin/featured-offer`, method: 'GET' },
  });

  client.register({
    action: getSingleFeaturedOffer,
    alias: 'getSingleFeaturedOffer',
    requestParams: { url: `${API_BASE_URL}/admin/featured-offer`, method: 'GET' },
  });

  client.register({
    action: editFeaturedOffer,
    alias: 'editFeaturedOffer',
    requestParams: { url: `${API_BASE_URL}/admin/featured-offer`, method: 'PUT' },
  });

  client.register({
    action: getPaypalConfig,
    alias: 'getPaypalConfig',
    requestParams: { url: `${API_BASE_URL}/admin/config/paypal`, method: 'GET' },
  });

  client.register({
    action: editPaypalConfig,
    alias: 'editPaypalConfig',
    requestParams: { url: `${API_BASE_URL}/admin/config/paypal`, method: 'PUT' },
  });

  client.register({
    action: permissionCheck,
    alias: 'permissionCheck',
    requestParams: { url: `${API_BASE_URL}/admin/user/me/permission-check`, method: 'GET' },
  });

  client.register({
    action: createOrder,
    alias: 'createOrder',
    requestParams: { url: `${API_BASE_URL}/admin/investment/manual-order`, method: 'POST' },
  });

  client.register({
    action: revalidateAllOffers,
    alias: 'revalidateAllOffers',
    requestParams: { url: `${API_BASE_URL}/admin/revalidate/offers`, method: 'POST' },
  });

  client.register({
    action: revalidateOffer,
    alias: 'revalidateOffer',
    requestParams: { url: `${API_BASE_URL}/admin/revalidate/offers`, method: 'POST' },
  });
};

export default client => registerModules(client);
