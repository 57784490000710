import { CREATE_PAGE_INIT, CREATE_PAGE_SUCCESS, CREATE_PAGE_FAILURE } from '@store/action-types/page';
import client from '@api-sdk/client';
import history from '@store/history';
import { isEmpty, set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';

const createPageInit = () => ({ type: CREATE_PAGE_INIT });
const createPageSuccess = () => ({ type: CREATE_PAGE_SUCCESS });
const createPageFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: CREATE_PAGE_FAILURE, payload: { error: mappedErrors } };
};

const transformPageDataRequest = ({ pageData, imageData, filesData }) => ({
  active: pageData?.active,
  image: !isEmpty(imageData) && imageData,
  slug: pageData?.slug,
  title: pageData?.title,
  subTitle: pageData?.subTitle,
  content: pageData?.content,
  seoTitle: pageData?.seoTitle,
  seoDescription: pageData?.seoDescription,
  attachments: filesData
    ?.filter?.(item => !item.file.delete)
    ?.map?.(file => ({
      displayName: file?.displayName,
      file: {
        fileName: file?.file?.fileName,
        base64: file?.file?.base64,
      },
    })),
});

const createPage = ({ pageData, imageData, filesData, saveAndBack = false }) => async dispatch => {
  dispatch(createPageInit());

  try {
    const createPageResponse = await client.createPage({
      data: transformPageDataRequest({ pageData, imageData, filesData }),
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('page.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    await dispatch(createPageSuccess(createPageResponse.response));

    if (saveAndBack) {
      history.push(ROUTES.PAGES);
    } else {
      history.push(`/static-pages/${createPageResponse.response?.id}`);
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('page.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );

    dispatch(createPageFailure(error));
  }
};

export default createPage;
