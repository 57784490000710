import React, { useMemo, useRef } from 'react';
import { Form, Grid, Label } from 'semantic-ui-react';
import ReactQuill from 'react-quill';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import clsx from 'clsx';
import { VALID_DOCUMENT_MIME_TYPES } from '@screens/page/const';

const Quill = ReactQuill.Quill;
const Inline = Quill.import('blots/inline');

let attachmentBlotInnerHtml = null;

class AttachmentBlot extends Inline {
  static create(url) {
    const node = super.create();
    node.setAttribute('href', url);
    node.setAttribute('target', '_blank');

    if (attachmentBlotInnerHtml) {
      node.innerHTML = attachmentBlotInnerHtml;
    }

    if (url) {
      node.onclick = () => window.open(url, '_blank');
    }

    node.setAttribute('title', node.textContent);

    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('href') || true;
  }

  format(name, value) {
    if (name === 'attachment' && value) {
      this.domNode.setAttribute('href', value);
    } else {
      super.format(name, value);
    }
  }

  formats() {
    const formats = super.formats();
    formats.attachment = AttachmentBlot.formats(this.domNode);
    return formats;
  }
}

AttachmentBlot.blotName = 'attachment';
AttachmentBlot.tagName = 'a';
Quill.register(AttachmentBlot);

const languages = ['en', 'pl'];
const languageFlags = {
  en: <i className="us flag" />,
  pl: <i className="pl flag" />,
};

const icons = Quill.import('ui/icons');
icons.attachment = '<i aria-hidden="true" class="black file icon"></i>';

const TranslatableRichTextField = ({
  actions,
  label,
  name,
  onChange,
  value,
  errorMessage,
  disabled,
  labelClassName,
}) => {
  const quillRefs = useRef([...languages].map(() => React.createRef()));
  const [t] = useTranslation(TRANSLATIONS.OFFERS);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const reader = new FileReader();
      const languageFromActiveElement = document.activeElement.parentElement.parentElement.getAttribute('id');

      if (file && actions.uploadSinglePhotoAction) {
        reader.onloadend = async () => {
          const uploadedFile = await actions.uploadSinglePhotoAction({ base64: reader.result, fileName: file.name });

          let range = quillRefs?.current?.[languageFromActiveElement].current.getEditor().getSelection(true);
          quillRefs.current[languageFromActiveElement].current.getEditor().setSelection(range.index + 1);
          quillRefs.current[languageFromActiveElement].current
            .getEditor()
            .insertEmbed(range.index, 'image', uploadedFile?.payload?.photo?.url);

          range = quillRefs?.current?.[languageFromActiveElement].current.getEditor().getSelection(true);
          quillRefs.current[languageFromActiveElement].current.getEditor().setSelection(range.index + 2);
        };

        return reader.readAsDataURL(file);
      }
    };
  };

  const selectAttachment = async () => {
    const input = document.createElement('input');
    attachmentBlotInnerHtml = prompt(t('enterFileName'));

    if (attachmentBlotInnerHtml) {
      input.setAttribute('type', 'file');
      input.setAttribute('accept', VALID_DOCUMENT_MIME_TYPES.join(', '));
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        const reader = new FileReader();
        const languageFromActiveElement = document.activeElement.parentElement.parentElement.getAttribute('id');

        if (file && actions.uploadSingleFileAction) {
          reader.onloadend = async () => {
            const uploadedFile = await actions.uploadSingleFileAction({
              base64: reader.result,
              fileName: file.name,
            });

            let range = quillRefs?.current?.[languageFromActiveElement].current.getEditor().getSelection(true);
            quillRefs.current[languageFromActiveElement].current.getEditor().setSelection(range.index + 1);
            quillRefs.current[languageFromActiveElement].current
              .getEditor()
              .insertEmbed(range.index, 'attachment', uploadedFile?.payload?.file?.url);

            range = quillRefs?.current?.[languageFromActiveElement].current.getEditor().getSelection(true);
            quillRefs.current[languageFromActiveElement].current
              .getEditor()
              .setSelection(range.index + attachmentBlotInnerHtml?.length);
            attachmentBlotInnerHtml = null;
          };

          return reader.readAsDataURL(file);
        }
      };
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: 1 }, { header: 2 }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ color: ['#42a6ff', '#001C44', '#4ADEDE', '#F9FAFE'] }, { background: [] }],
          [{ align: [] }],
          ['image', 'link', 'attachment', 'blockquote'],
          ['clean'],
        ],
        handlers: {
          image: selectLocalImage,
          attachment: selectAttachment,
        },
      },
    }),
    [],
  );

  return (
    <>
      {languages.map((language, index) => {
        return (
          <Grid.Column key={`${name}-${language}`}>
            <Form.Field>
              {errorMessage && (
                <Label color="red" className={clsx('mb-5', labelClassName)}>
                  {error(errorMessage)}
                </Label>
              )}
              <label>
                {languageFlags[language]}
                {label}
              </label>
              <ReactQuill
                id={index}
                ref={quillRefs?.current?.[index]}
                modules={modules}
                formats={[
                  'header',
                  'font',
                  'background',
                  'color',
                  'code',
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'script',
                  'align',
                  'direction',
                  'link',
                  'image',
                  'code-block',
                  'formula',
                  'audio',
                  'attachment',
                ]}
                readOnly={disabled}
                theme="snow"
                index={index}
                name={name}
                value={value?.[language] ?? ' '}
                onChange={value => onChange(0, { name, value, language })}
              />
            </Form.Field>
          </Grid.Column>
        );
      })}
    </>
  );
};

TranslatableRichTextField.defaultProps = {
  label: '',
  name: '',
  onChange: () => {},
  value: '',
};

export default TranslatableRichTextField;
