import { CREATE_MENU_ITEM_INIT, CREATE_MENU_ITEM_SUCCESS, CREATE_MENU_ITEM_FAILURE } from '@store/action-types/menu';
import Client from '@api-sdk/client';
import { set, toPath, isEmpty } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const createMenuItemInit = () => ({ type: CREATE_MENU_ITEM_INIT });
const createMenuItemSuccess = () => ({ type: CREATE_MENU_ITEM_SUCCESS });
const createMenuItemFailure = error => {
  if (!error?.errors) {
    return { type: CREATE_MENU_ITEM_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: CREATE_MENU_ITEM_FAILURE, payload: { error: mappedErrors } };
};

const createMenuItem = ({ menuItem, saveAndBack = false }) => async dispatch => {
  dispatch(createMenuItemInit());

  if (isEmpty(String(menuItem?.position))) {
    return dispatch(createMenuItemFailure({ position: 'POSITION_IS_REQUIRED' }));
  }

  try {
    const menuItemResponse = await Client.createMenuItem({ data: menuItem });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('menu.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    if (saveAndBack) {
      history.push(ROUTES.MENU);
    } else {
      history.push(`/menu/${menuItemResponse.response?.id}`);
    }

    return dispatch(createMenuItemSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('menu.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    return dispatch(createMenuItemFailure(error));
  }
};

export default createMenuItem;
