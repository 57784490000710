export const OfferSectionElementCssClasses = [
  {
    key: 'Primary Background',
    text: 'Primary Background',
    value: 'primary-background',
  },
  {
    key: 'Secondary Background',
    text: 'Secondary Background',
    value: 'secondary-background',
  },
  {
    key: 'Shape Top',
    text: 'Shape Top',
    value: 'shape-top',
  },
  {
    key: 'Shape Bottom',
    text: 'Shape Bottom',
    value: 'shape-bottom',
  },
  {
    key: 'Hidden image on mobile',
    text: 'Hidden image on mobile',
    value: 'hidden-image-on-mobile',
  },
  {
    key: 'Hidden image on desktop',
    text: 'Hidden image on desktop',
    value: 'hidden-image-on-desktop',
  },
  {
    key: 'Hidden image on mobile and tablet',
    text: 'Hidden image on mobile and tablet',
    value: 'hidden-image-on-mobile-and-tablet',
  },
];
