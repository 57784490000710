import { ELEMENT_TYPES } from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements';

const mapSingleOfferResponse = response => {
  return {
    ...response,
    badges: response?.badges?.map?.((badge, index) => ({ ...badge, backendIndex: index })),
    descriptions: response?.descriptions?.map?.((badge, index) => ({ ...badge, backendIndex: index })),
    properties: response?.properties?.map?.((badge, index) => ({ ...badge, backendIndex: index })),
    agreements:
      response?.agreements
        ?.sort((a, b) => Number(a.position) - Number(b.position))
        ?.map?.((attachment, index) => ({
          ...attachment,
          backendIndex: index,
          position: index + 1,
        })) || [],
    sections: response?.sections?.map?.((section, index) => ({
      ...section,
      backendIndex: index,
      elements: section.elements?.map?.((sectionElement, sectionElementIndex) => ({
        ...sectionElement,
        backendIndex: sectionElementIndex,
        cssClasses: sectionElement?.cssClasses?.split(' ').map(cssClass => cssClass) || [],
        elements: sectionElement?.elements?.map?.((innerElement, innerElementIndex) => ({
          ...innerElement,
          backendIndex: innerElementIndex,
        })),

        ...(sectionElement.buttons && {
          buttons: sectionElement?.buttons?.map?.((innerElement, innerElementIndex) => ({
            ...innerElement,
            backendIndex: innerElementIndex,
            cssClasses: innerElement?.cssClasses?.split(' ').map(cssClass => cssClass) || [],
          })),
        }),

        ...(sectionElement.type === ELEMENT_TYPES.pageHeader.value && {
          images: sectionElement?.images?.map?.((innerElement, innerElementIndex) => ({
            ...innerElement,
            backendIndex: innerElementIndex,
          })),
        }),

        ...(sectionElement.type === ELEMENT_TYPES.grid.value && {
          items: sectionElement?.items?.map?.((innerElement, innerElementIndex) => ({
            ...innerElement,
            buttons: innerElement?.buttons?.map?.((innerButton, innerButtonIndex) => ({
              ...innerButton,
              backendIndex: innerButtonIndex,
            })),
            backendIndex: innerElementIndex,
          })),
        }),

        ...(sectionElement.type === ELEMENT_TYPES.successStory.value && {
          items: sectionElement?.items?.map?.((innerElement, innerElementIndex) => ({
            ...innerElement,
            backendIndex: innerElementIndex,
          })),
          tags: sectionElement?.tags?.map?.((innerElement, innerElementIndex) => ({
            ...innerElement,
            backendIndex: innerElementIndex,
          })),
        }),
      })),
    })),
  };
};

export default mapSingleOfferResponse;
