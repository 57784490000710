import { GET_BLOG_INIT, GET_BLOG_FAILURE, GET_BLOG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getBlogInit = () => ({ type: GET_BLOG_INIT });
const getBlogSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_BLOG_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getBlogFailure = error => ({ type: GET_BLOG_FAILURE, payload: { error } });

const getBlog = ({ activeFilters, pagination, order } = {}) => async dispatch => {
  dispatch(getBlogInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const blog = await Client.getPosts({ params: activeFilters || urlQueryParams?.all });
    dispatch(
      getBlogSuccess({
        response: blog.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getBlogFailure(error));
  }
};

export default getBlog;
