import React, { createRef, useState } from 'react';
import { bool, func, number, shape } from 'prop-types';
import { Button, Dropdown, Form, Grid, Icon, Image, Input, Label, Segment } from 'semantic-ui-react';
import clsx from 'clsx';
import { Select } from '@shared/components';
import {
  GridOptions,
  Target,
  TargetOptions,
} from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements/components/GridElement/consts';
import VALID_IMAGE_MIME_TYPES from '@screens/offers/NewOfferForm/OfferForm/models/ImageTypes';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { TranslatableTextField, TranslatableRichTextField } from '@screens/offers/NewOfferForm/components';
import { GridElementItemButtonModel } from '@screens/offers/NewOfferForm/OfferForm/models/elementModels';
import { isEmpty } from 'lodash';
import { GridElementItemButton } from '..';

const GridElementItem = ({
  element,
  isViewMode,
  elementErrors,
  onChange,
  sectionNumber,
  gridElementPosition,
  isArrowDownDisabled,
  isArrowUpDisabled,
  onAddGirdElementItem,
}) => {
  const { t } = useTranslation(TRANSLATIONS.OFFERS);
  const [isExpanded, setIsExpanded] = useState(false);
  const imageRef = createRef();

  const toggleGridElementItemExpand = () => setIsExpanded(prev => !prev);

  const onSelectChange = (e, { name, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === element.position) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: value,
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onGridChange = ({ name, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === element.position) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              grid: {
                                ...innerElement?.grid,
                                [name]: value,
                              },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onTranslatableContentChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === element.position) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: {
                                ...innerElement[name],
                                [language]: value,
                              },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onArrowUp = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.items.find(item => item.position === element.position - 1);
                        const matchingItem = el.items.find(item => item.position === element.position);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onArrowDown = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.items.find(item => item.position === element.position + 1);
                        const matchingItem = el.items.find(item => item.position === element.position);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onRemoveGridElementItem = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove header button? Button changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (gridElementPosition === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        items: el.items.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === element.position) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > element.position) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  };

  const onAddButton = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((itemsAcc, item) => {
                        if (item.position === element.position) {
                          return itemsAcc.concat([
                            {
                              ...item,
                              buttons: item.buttons.concat([
                                {
                                  ...GridElementItemButtonModel,
                                  position: item?.buttons?.[item?.buttons?.length - 1]?.position + 1 || 1,
                                  backendIndex: item?.buttons?.length || 0,
                                },
                              ]),
                            },
                          ]);
                        }
                        return itemsAcc.concat([item]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onUploadImage = e => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        return onChange(prevState => ({
          offerSections: prevState.offerSections.reduce((acc, section) => {
            if (section.position === sectionNumber) {
              return acc.concat([
                {
                  ...section,
                  elements: section.elements.reduce((elementsAcc, el) => {
                    if (gridElementPosition === el.position) {
                      return elementsAcc.concat([
                        {
                          ...el,
                          items: el.items.reduce((innerElementsAcc, innerElement) => {
                            if (innerElement.position === element.position) {
                              return innerElementsAcc.concat([
                                {
                                  ...innerElement,
                                  file: {
                                    fileName: file.name,
                                    delete: false,
                                    base64: reader.result,
                                  },
                                },
                              ]);
                            }
                            return innerElementsAcc.concat([innerElement]);
                          }, []),
                        },
                      ]);
                    }
                    return elementsAcc.concat([el]);
                  }, []),
                },
              ]);
            }
            return acc.concat([section]);
          }, []),
        }));
      };

      return reader.readAsDataURL(file);
    }

    return null;
  };

  return (
    <Segment>
      <Grid container stackable columns={1} divided>
        <Grid.Row className={clsx('pl-4 d-flex align-items-center', isExpanded ? 'mb-8' : 'mb-0')}>
          <Label>
            {t('grid.item.element')} #{element.position}
          </Label>
          {isViewMode ? null : (
            <>
              <Button icon className="mr-2 ml-2" disabled={isArrowUpDisabled} onClick={onArrowUp}>
                <Icon name="arrow up" />
              </Button>
              <Button icon className="mr-2 ml-2" disabled={isArrowDownDisabled} onClick={onArrowDown}>
                <Icon name="arrow down" />
              </Button>
              <Button className="mr-2 ml-2" icon color="red" onClick={onRemoveGridElementItem}>
                <Icon name="trash" />
              </Button>
              <Button className="mr-2 ml-2" color="green" icon onClick={toggleGridElementItemExpand}>
                {isExpanded ? <Icon name="angle double up" /> : <Icon name="angle double down" />}
              </Button>

              <div className="d-flex align-items-center justify-content-center">
                <Dropdown
                  icon="desktop"
                  className="icon mr-2 ml-2"
                  disabled={isViewMode}
                  labeled
                  fluid
                  button
                  selection
                  placeholder={t('desktop')}
                  label={t('desktop')}
                  value={element?.grid?.desktop}
                  name="desktop"
                  options={GridOptions}
                  onChange={(e, { value, name }) => onGridChange({ value, name })}
                />
                <Dropdown
                  disabled={isViewMode}
                  icon="tablet alternate"
                  className="icon mr-2 ml-2"
                  labeled
                  fluid
                  button
                  selection
                  placeholder={t('tablet')}
                  label={t('tablet')}
                  value={element?.grid?.tablet}
                  name="tablet"
                  options={GridOptions}
                  onChange={(e, { value, name }) => onGridChange({ value, name })}
                />
                <Dropdown
                  disabled={isViewMode}
                  icon="mobile"
                  className="icon mr-2 ml-2"
                  labeled
                  fluid
                  button
                  selection
                  placeholder={t('mobile')}
                  label={t('mobile')}
                  name="mobile"
                  value={element?.grid?.mobile}
                  options={GridOptions}
                  onChange={(e, { value, name }) => onGridChange({ value, name })}
                />
              </div>

              <Button className="ml-auto" icon onClick={onAddGirdElementItem}>
                <Icon name="plus" />
              </Button>
            </>
          )}
        </Grid.Row>
      </Grid>
      {isExpanded ? (
        <>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <Select
                disabled={isViewMode}
                type="text"
                label={t('grid.item.target')}
                name="target"
                value={element.target}
                onChange={onSelectChange}
                options={TargetOptions}
                placeholder="Not set"
              />
            </Grid>
          </Form.Group>
          {element.target ? (
            <Form.Group>
              <Grid container stackable columns={2} divided>
                <TranslatableTextField
                  disabled={isViewMode}
                  type="text"
                  label={t('grid.item.url')}
                  name="url"
                  value={element.url}
                  icon="anchor"
                  onChange={onTranslatableContentChange}
                  placeholder={Target.NOT_SET}
                />
              </Grid>
            </Form.Group>
          ) : null}
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableRichTextField
                type="text"
                name="title"
                label={t('grid.item.title')}
                errorMessage={elementErrors?.title}
                value={element?.title}
                icon="font"
                onChange={onTranslatableContentChange}
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableRichTextField
                type="text"
                name="content"
                label={t('grid.item.content')}
                errorMessage={elementErrors?.content}
                value={element?.content}
                icon="font"
                onChange={onTranslatableContentChange}
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={isViewMode ? 1 : 2} divided>
              <Grid.Column>
                <Image src={element?.file?.base64 || element.imageUrl} size="medium" rounded bordered />
              </Grid.Column>

              {isViewMode ? null : (
                <Grid.Column>
                  <Input
                    ref={imageRef}
                    onChange={onUploadImage}
                    type="file"
                    accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                  />
                </Grid.Column>
              )}
            </Grid>
          </Form.Group>
          {isEmpty(element.buttons) ? (
            <div className="d-flex justify-content-center align-items-center p-4">
              <Button onClick={onAddButton}>
                <Icon name="plus" />
                {t('grid.item.add')}
              </Button>
            </div>
          ) : (
            <Segment>
              {element.buttons.map(button => (
                <GridElementItemButton
                  element={button}
                  isArrowDownDisabled={button.position === element?.buttons?.[element?.buttons?.length - 1]?.position}
                  isArrowUpDisabled={button.position === element?.buttons?.[0]?.position}
                  onChange={onChange}
                  sectionNumber={sectionNumber}
                  gridElementPosition={gridElementPosition}
                  gridElementItemPosition={element.position}
                  onAddButton={onAddButton}
                />
              ))}
            </Segment>
          )}
        </>
      ) : null}
    </Segment>
  );
};

GridElementItem.propTypes = {
  sectionNumber: number.isRequired,
  gridElementPosition: number.isRequired,
  element: shape({}),
  isViewMode: bool,
  isArrowDownDisabled: bool,
  isArrowUpDisabled: bool,
  elementErrors: shape({}),
  onChange: func,
  onAddGirdElementItem: func,
};

GridElementItem.defaultProps = {
  element: {},
  isViewMode: false,
  isArrowDownDisabled: false,
  isArrowUpDisabled: false,
  elementErrors: {},
  onChange: () => null,
  onAddGirdElementItem: () => null,
};

export default GridElementItem;
