import { EDIT_MENU_ITEM_FAILURE, EDIT_MENU_ITEM_INIT, EDIT_MENU_ITEM_SUCCESS } from '@store/action-types/menu';
import Client from '@api-sdk/client';
import { isEmpty, set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';
import history from '@store/history';

const editMenuItemInit = () => ({ type: EDIT_MENU_ITEM_INIT });
const editMenuItemSuccess = () => ({ type: EDIT_MENU_ITEM_SUCCESS });
const editMenuItemFailure = error => {
  if (!error?.errors) {
    return { type: EDIT_MENU_ITEM_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: EDIT_MENU_ITEM_FAILURE, payload: { error: mappedErrors } };
};

const editMenuItem = ({ menuItem, saveAndBack = false }) => async dispatch => {
  dispatch(editMenuItemInit());

  if (isEmpty(String(menuItem?.position))) {
    return dispatch(editMenuItemFailure({ position: 'POSITION_IS_REQUIRED' }));
  }

  try {
    await Client.editMenuItem({
      slug: menuItem?.id,
      data: {
        parent: menuItem?.parent ? menuItem?.parent : null,
        codeName: menuItem?.codeName ? menuItem?.codeName : null,
        position: menuItem?.position,
        label: menuItem?.label,
        url: menuItem?.url,
      },
    });

    if (saveAndBack) {
      history.push(ROUTES.MENU);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('menu.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    dispatch(editMenuItemSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('menu.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editMenuItemFailure(error));
  }
};

export default editMenuItem;
