import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { NotFoundMessage, Table } from '@shared/components';
import ROUTES from '@shared/consts/routes';
import TRANSLATIONS from '@translations/translationNamespaces';
import { ThumbImage } from '@screens/blog/components';
import PERMISSIONS from '@shared/consts/permissions';

const PostsList = ({ isLoading, posts, queryParams, isUserPermitted, searchAction, reloadAction, deleteAction }) => {
  const { t } = useTranslation(TRANSLATIONS.BLOG);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'thumbUrl',
        columnName: 'thumbUrl',
        columnOrder: 'thumbUrl',
        Cell: ({ row }) => {
          const thumbUrl = row?.original?.thumbUrl;
          return <ThumbImage src={thumbUrl} />;
        },
      },
      {
        Header: t('title'),
        accessor: 'title',
        columnName: 'title',
        columnOrder: 'title',
        Cell: ({ row }) => {
          const title = row?.original?.title;
          // eslint-disable-next-line react/no-danger
          return <span dangerouslySetInnerHTML={{ __html: title?.[i18next?.language] }} />;
        },
      },
      {
        Header: t('slug'),
        accessor: 'slug',
        columnName: 'slug',
        columnOrder: 'slug',
        Cell: ({ row }) => {
          const slug = row?.original?.slug;
          return slug?.[i18next?.language];
        },
      },
      {
        Header: t('publishedAt'),
        accessor: 'publishedAt',
        columnName: 'publishedAt',
        columnOrder: 'publishedAt',
      },
      {
        Header: t('isVisible'),
        accessor: 'isVisible',
        columnName: 'isVisible',
        columnOrder: 'isVisible',
        Cell: ({ row }) => {
          const isVisible = row?.original?.isVisible;
          return isVisible ? t('yes') : t('no');
        },
      },
      {
        accessor: 'actions',
        className: 'text-right',
        Cell: ({ row }) => (
          <>
            {isUserPermitted(PERMISSIONS.GET_BLOG_BY_ID) ? (
              <Button as={Link} to={`/blog/${row?.original?.id}`}>
                {t('edit')}
              </Button>
            ) : null}
            {isUserPermitted(PERMISSIONS.DELETE_BLOG) ? (
              <Button
                color="red"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals,no-alert
                  if (confirm(`${t('deleteBlogDisclaimer')}`)) {
                    deleteAction(row.original.id);
                  }
                }}
              >
                {t('delete')}
              </Button>
            ) : null}
          </>
        ),
      },
    ],
    [t],
  );

  return (
    <Table
      className="table-responsive"
      isLoading={isLoading}
      data={posts}
      columns={columns}
      queryParams={queryParams}
      searchAction={searchAction}
      reloadAction={reloadAction}
      notFoundMessage={
        <NotFoundMessage
          title={t('notFoundPosts')}
          subtitle={t('clickBelow')}
          buttonText={t('create')}
          to={ROUTES.ADD_BLOG}
        />
      }
    />
  );
};

PostsList.propTypes = {
  isLoading: PropTypes.bool,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.shape({
        en: PropTypes.string,
        ar: PropTypes.string,
      }),
      slug: PropTypes.shape({
        en: PropTypes.string,
        ar: PropTypes.string,
      }),
      publishedAt: PropTypes.string,
      isVisible: PropTypes.bool,
    }),
  ),
  queryParams: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
    search: PropTypes.string,
    sort: PropTypes.string,
    order: PropTypes.string,
  }),
  isUserPermitted: PropTypes.func,
  searchAction: PropTypes.func,
  reloadAction: PropTypes.func,
  deleteAction: PropTypes.func,
};

PostsList.defaultProps = {
  isLoading: false,
  posts: [],
  queryParams: {},
  isUserPermitted: () => {},
  searchAction: () => {},
  reloadAction: () => {},
  deleteAction: () => {},
};

export default PostsList;
