const GET_BLOG_INIT = '@blog/GET_BLOG_INIT';
const GET_BLOG_SUCCESS = '@blog/GET_BLOG_SUCCESS';
const GET_BLOG_FAILURE = '@blog/GET_BLOG_FAILURE';

const GET_SINGLE_BLOG_INIT = '@blog/GET_SINGLE_BLOG_INIT';
const GET_SINGLE_BLOG_SUCCESS = '@blog/GET_SINGLE_BLOG_SUCCESS';
const GET_SINGLE_BLOG_FAILURE = '@blog/GET_SINGLE_BLOG_FAILURE';

const DELETE_BLOG_INIT = '@blog/DELETE_BLOG_INIT';
const DELETE_BLOG_SUCCESS = '@blog/DELETE_BLOG_SUCCESS';
const DELETE_BLOG_FAILURE = '@blog/DELETE_BLOG_FAILURE';

const EDIT_BLOG_INIT = '@blog/EDIT_BLOG_INIT';
const EDIT_BLOG_SUCCESS = '@blog/EDIT_BLOG_SUCCESS';
const EDIT_BLOG_FAILURE = '@blog/EDIT_BLOG_FAILURE';

const CREATE_BLOG_INIT = '@blog/CREATE_BLOG_INIT';
const CREATE_BLOG_SUCCESS = '@blog/CREATE_BLOG_SUCCESS';
const CREATE_BLOG_FAILURE = '@blog/CREATE_BLOG_FAILURE';

const GET_TAGS_INIT = '@blog/GET_TAGS_INIT';
const GET_TAGS_SUCCESS = '@blog/GET_TAGS_SUCCESS';
const GET_TAGS_FAILURE = '@blog/GET_TAGS_FAILURE';

const GET_SINGLE_TAG_INIT = '@blog/GET_SINGLE_TAG_INIT';
const GET_SINGLE_TAG_SUCCESS = '@blog/GET_SINGLE_TAG_SUCCESS';
const GET_SINGLE_TAG_FAILURE = '@blog/GET_SINGLE_TAG_FAILURE';

const DELETE_TAG_INIT = '@blog/DELETE_TAG_INIT';
const DELETE_TAG_SUCCESS = '@blog/DELETE_TAG_SUCCESS';
const DELETE_TAG_FAILURE = '@blog/DELETE_TAG_FAILURE';

const EDIT_TAG_INIT = '@blog/EDIT_TAG_INIT';
const EDIT_TAG_SUCCESS = '@blog/EDIT_TAG_SUCCESS';
const EDIT_TAG_FAILURE = '@blog/EDIT_TAG_FAILURE';

const CREATE_TAG_INIT = '@blog/CREATE_TAG_INIT';
const CREATE_TAG_SUCCESS = '@blog/CREATE_TAG_SUCCESS';
const CREATE_TAG_FAILURE = '@blog/CREATE_TAG_FAILURE';

const CLEAR_STATUS = '@blog/CLEAR_STATUS';

export {
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
  GET_BLOG_INIT,
  GET_SINGLE_BLOG_INIT,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_FAILURE,
  DELETE_BLOG_INIT,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAILURE,
  EDIT_BLOG_INIT,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAILURE,
  CREATE_BLOG_INIT,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_FAILURE,
  CLEAR_STATUS,
  GET_TAGS_INIT,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_SINGLE_TAG_INIT,
  GET_SINGLE_TAG_SUCCESS,
  GET_SINGLE_TAG_FAILURE,
  DELETE_TAG_INIT,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  EDIT_TAG_INIT,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
  CREATE_TAG_INIT,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
};
