export const Layout = {
  TEXT_LEFT_IMAGE_RIGHT: 'TEXT_LEFT_IMAGE_RIGHT',
  TEXT_RIGHT_IMAGE_LEFT: 'TEXT_RIGHT_IMAGE_LEFT',
  TEXT_BOTTOM_IMAGE_TOP: 'TEXT_BOTTOM_IMAGE_TOP',
  TEXT_TOP_IMAGE_BOTTOM: 'TEXT_TOP_IMAGE_BOTTOM',
};

export const Design = {
  HOMEPAGE: 'HOMEPAGE',
  HEADER: 'HEADER',
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  QUATERNARY: 'QUATERNARY',
};

export const TargetOptions = [
  { key: 'self', value: '_self', text: '_self' },
  { key: 'blank', value: '_blank', text: '_blank' },
];

export const LayoutOptions = [
  { key: Layout.TEXT_LEFT_IMAGE_RIGHT, value: Layout.TEXT_LEFT_IMAGE_RIGHT, text: 'Text Left - Image Right' },
  { key: Layout.TEXT_RIGHT_IMAGE_LEFT, value: Layout.TEXT_RIGHT_IMAGE_LEFT, text: 'Text Right - Image Left' },
  { key: Layout.TEXT_BOTTOM_IMAGE_TOP, value: Layout.TEXT_BOTTOM_IMAGE_TOP, text: 'Text Bottom - Image Top' },
  { key: Layout.TEXT_TOP_IMAGE_BOTTOM, value: Layout.TEXT_TOP_IMAGE_BOTTOM, text: 'Text Top - Image Bottom' },
];

export const DesignOptions = [
  { key: Design.HOMEPAGE, value: Design.HOMEPAGE, text: 'Homepage' },
  { key: Design.HEADER, value: Design.HEADER, text: 'Header' },
  { key: Design.PRIMARY, value: Design.PRIMARY, text: 'Primary' },
  { key: Design.SECONDARY, value: Design.SECONDARY, text: 'Secondary' },
  { key: Design.TERTIARY, value: Design.TERTIARY, text: 'Tertiary' },
  { key: Design.QUATERNARY, value: Design.QUATERNARY, text: 'Quaternary' },
];
