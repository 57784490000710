import {
  CREATE_OFFER_INIT,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  GET_SINGLE_OFFER_SUCCESS,
} from '@store/action-types/offers';
import client from '@api-sdk/client';
import moment from 'moment';
import sectionMapper from '@store/actions/offers/utils/sectionMapper';
import { set, toPath } from 'lodash';
import mapSingleOfferResponse from '@store/actions/offers/utils/responseMapper';
import history from '@store/history';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';

const createOfferInit = () => ({ type: CREATE_OFFER_INIT });
const createOfferSuccess = singleOffer => ({
  type: CREATE_OFFER_SUCCESS,
  payload: { singleOffer },
});

const createOfferFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const isSlugNotUnique = item.code === 'OFFER:SLUG_NOT_UNIQUE';
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    const errorsWithoutContext = [];

    if (isSlugNotUnique) {
      set(acc, 'slug', item.code?.split?.(':')?.pop() || item.message);
    }

    if (!item.context && !isSlugNotUnique) {
      errorsWithoutContext.push(item.code?.split?.(':', item.code.length)?.pop?.());
    }

    set(acc, path, item.code?.split?.(':')?.pop() || item.message);

    acc.default = errorsWithoutContext;

    return acc;
  }, {});

  return { type: CREATE_OFFER_FAILURE, payload: { error: { ...error, errors: mappedErrors } } };
};

const getSingleOfferSuccess = singleOffer => ({
  type: GET_SINGLE_OFFER_SUCCESS,
  payload: { singleOffer },
});

const createOffer = ({
  offerData,
  offerProperties,
  offerBadges,
  offerDescriptions,
  offerImage,
  offerId,
  offerSections,
  offerAttachments,
  offerAgreements,
  saveAndBack = false,
}) => async dispatch => {
  dispatch(createOfferInit());

  try {
    const createOfferResponse = await client.createOffer({
      data: {
        address: offerData?.address,
        investmentReturn: offerData?.investmentReturn,
        investmentEnd: offerData?.investmentEnd ? moment(offerData?.investmentEnd).format('YYYY-MM-DD') : '',
        investmentStart: offerData?.investmentStart ? moment(offerData?.investmentStart).format('YYYY-MM-DD') : '',
        isOpen: offerData?.isOpen,
        isVisible: offerData?.isVisible,
        mainDescription: offerData?.mainDescription,
        projectType: offerData?.projectType,
        slug: offerData?.slug,
        templateName: offerData?.templateName,
        title: offerData?.title,
        position: offerData?.position,
        seoTitle: offerData?.seoTitle,
        seoDescription: offerData?.seoDescription,
        totalCost: offerData?.totalCost,
        ...(offerData?.shouldEnableFixedInvestorsEquity && { fixedInvestorsEquity: offerData?.fixedInvestorsEquity }),
        series: offerData?.series,
        fixedInvestorsEquity: offerData?.shouldEnableFixedInvestorsEquity ? offerData?.fixedInvestorsEquity : null,
        properties: offerProperties?.reduce?.((acc, property) => {
          acc[property.backendIndex] = { ...property, backendIndex: undefined };

          return acc;
        }, {}),
        badges: offerBadges?.reduce?.((acc, badge) => {
          acc[badge.backendIndex] = { ...badge, backendIndex: undefined };

          return acc;
        }, {}),
        descriptions: offerDescriptions?.reduce?.((acc, description) => {
          acc[description.backendIndex] = { ...description, backendIndex: undefined };

          return acc;
        }, {}),
        image: offerImage,
        sections: sectionMapper(offerSections),
        agreements: offerAgreements?.reduce?.((acc, agreement) => {
          if (agreement?.toBeDeleted) {
            return acc;
          }

          acc[agreement.backendIndex] = {
            ...agreement,
            backendIndex: undefined,
            id: undefined,
            initWithEditor: undefined,
            toBeDeleted: undefined,
            position: Number(agreement.position),
          };

          return acc;
        }, {}),
      },
      slug: offerId,
    });

    await dispatch(createOfferSuccess(createOfferResponse));
    await dispatch(
      getSingleOfferSuccess({ ...createOfferResponse, response: mapSingleOfferResponse(createOfferResponse.response) }),
    );

    if (saveAndBack) {
      history.push(ROUTES.OFFERS);
    } else {
      history.push(`/pages/${createOfferResponse.response?.id}/edit`);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(createOfferFailure(error));
  }
};

export default createOffer;
