const ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:resetToken',
  HOME: '/',
  OFFERS: '/pages',
  ADD_OFFER: '/pages/create',
  EDIT_OFFER: '/pages/:id/edit',
  SERIES: '/series',
  ADD_SERIES: '/series/create',
  EDIT_SERIES: '/series/:id/edit',
  FAQ: '/faq',
  BLOG: '/blog',
  ADD_BLOG: '/blog/create',
  BLOG_TAGS: '/blog/tags',
  ADD_BLOG_TAG: '/blog/tags/create',
  EDIT_BLOG_TAG: '/blog/tags/:id',
  EDIT_BLOG: '/blog/:id',
  SINGLE_FAQ: '/faq/:id',
  ADD_FAQ: '/faq/create',
  EDIT_FAQ: '/faq/:id',
  MENU: '/menu',
  ADD_MENU: '/menu/create',
  EDIT_MENU: '/menu/:id',
  PAGES: '/static-pages',
  PAGE_CREATE: '/static-pages/create',
  EDIT_PAGE: '/static-pages/:id',
  GENERAL: '/general',
  SETTINGS: '/settings',
  INVESTMENTS: '/investments',
  SINGLE_INVESTMENT: '/investments/:id/edit',
  TRANSACTIONS: '/transactions',
  EDIT_TRANSACTION: '/transactions/:id/edit',
  AGREEMENTS: '/agreements',
  AGREEMENT_CREATE: '/agreements/create',
  SINGLE_AGREEMENT: '/agreements/:id',
  STAFF_ACCOUNT_MANAGEMENT: '/staff-accounts',
  SINGLE_STAFF_ACCOUNT: '/staff-accounts/:id',
  STAFF_ACCOUNT_CREATE: '/staff-accounts/create',
  PERMISSION_MANAGEMENT: '/permission',
  SINGLE_PERMISSION: '/permission/:id',
  PERMISSION_CREATE: '/permission/create',
  ROLES: '/roles',
  EDIT_ROLE: '/roles/:id/edit',
  CREATE_ROLE: '/roles/create',
  EMAIL_TEMPLATES: '/email-templates',
  SINGLE_TEMPLATE: '/email-templates/:id/edit',
  SLIDER_MANAGEMENT: '/slider',
  EDIT_SLIDER: '/slider/:id',
  CREATE_SLIDER: '/slider/create',
  ORDERS: '/orders',
  SINGLE_ORDER: '/orders/:id/edit',
  USERS: '/users',
  USER_CREATE: '/users/create',
  USER_EDIT: '/users/:id',
  USER_SET_PASSWORD: '/set-password/:resetPasswordToken',
  STATIC_CONTENT: '/static-content',
  STATIC_CONTENT_PARENT: '/static-content/:parentId',
  STATIC_CONTENT_ELEMENT: '/static-content/element/:elementId',
  FEATURED_OFFERS: '/featured-offers',
  EDIT_FEATURED_OFFER: '/featured-offers/:id',
  CREATE_ORDER: '/orders/create',
};

export default ROUTES;
