import { DELETE_BLOG_FAILURE, DELETE_BLOG_INIT, DELETE_BLOG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';
import getBlog from '@store/actions/blog/getBlog';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deletePostInit = () => ({ type: DELETE_BLOG_INIT });
const deletePostSuccess = () => ({ type: DELETE_BLOG_SUCCESS });
const deletePostFailure = error => ({ type: DELETE_BLOG_FAILURE, payload: { error } });

const deletePost = id => async dispatch => {
  dispatch(deletePostInit());

  try {
    await Client.deletePost({ slug: id });
    dispatch(getBlog());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
    return dispatch(deletePostSuccess(id));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'danger',
        },
      }),
    );
    return dispatch(deletePostFailure(error));
  }
};

export default deletePost;
