import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PERMISSIONS from '@shared/consts/permissions';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Card, NotFoundMessage, Table } from '@shared/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import ROUTES from '@shared/consts/routes';

const TagsList = ({ tags, isUserPermitted, isLoading, deleteAction }) => {
  const { t } = useTranslation(TRANSLATIONS.BLOG);
  const columns = useMemo(
    () => [
      {
        Header: t('polish'),
        accessor: 'name',
        Cell: ({ row }) => {
          const name = row.original.name;
          return name?.pl;
        },
      },
      {
        Header: t('english'),
        accessor: 'id',
        Cell: ({ row }) => {
          const name = row.original.name;
          return name?.en;
        },
      },
      {
        accessor: 'actions',
        className: 'text-right',
        Cell: ({ row }) => (
          <>
            {isUserPermitted(PERMISSIONS.GET_TAGS_BY_ID) ? (
              <Button as={Link} to={`/blog/tags/${row?.original?.id}`}>
                {t('edit')}
              </Button>
            ) : null}
            {isUserPermitted(PERMISSIONS.DELETE_TAGS) ? (
              <Button
                color="red"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals,no-alert
                  if (confirm(`${t('deleteTagsDisclaimer')}`)) {
                    deleteAction(row.original.id);
                  }
                }}
              >
                {t('delete')}
              </Button>
            ) : null}
          </>
        ),
      },
    ],
    [t],
  );

  return (
    <Card>
      <Table
        hideFilters
        hideFooter
        data={tags}
        columns={columns}
        isLoading={isLoading}
        notFoundMessage={
          <NotFoundMessage
            title={t('notFoundTags')}
            subtitle={t('clickBelowTags')}
            buttonText={t('create')}
            to={ROUTES.ADD_BLOG_TAG}
          />
        }
      />
    </Card>
  );
};

TagsList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.shape({
        pl: PropTypes.string,
        en: PropTypes.string,
      }),
    }),
  ),
  isUserPermitted: PropTypes.func,
  isLoading: PropTypes.bool,
  deleteAction: PropTypes.func,
};

TagsList.defaultProps = {
  tags: [],
  isLoading: true,
  isUserPermitted: () => {},
  deleteAction: () => {},
};

export default TagsList;
