import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Form, Grid } from 'semantic-ui-react';
import { TranslatableRichTextField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const BlogFormSeo = ({ isViewMode, postData, errors, onChange }) => {
  const { t } = useTranslation(TRANSLATIONS.BLOG);

  const onTranslatableInputChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));

  return (
    <>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            disabled={isViewMode}
            label={t('form.seoTitle')}
            name="seoTitle"
            errorMessage={errors?.seoTitle}
            value={postData?.seoTitle}
            onChange={onTranslatableInputChange}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
        </Grid>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            disabled={isViewMode}
            label={t('form.seoDescription')}
            name="seoDescription"
            errorMessage={errors?.seoDescription}
            value={postData?.seoDescription}
            onChange={onTranslatableInputChange}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
        </Grid>
      </Form.Group>
    </>
  );
};

BlogFormSeo.propTypes = {
  isViewMode: PropTypes.bool,
  postData: PropTypes.shape({
    seoTitle: PropTypes.shape({
      en: PropTypes.string,
      pl: PropTypes.string,
    }),
    seoDescription: PropTypes.shape({
      en: PropTypes.string,
      pl: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

BlogFormSeo.defaultProps = {
  isViewMode: false,
  postData: {},
  errors: {},
  onChange: () => {},
};

export default BlogFormSeo;
