import { ELEMENT_TYPES } from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements';
import { isEmpty } from 'lodash';

const mapInnerElementPropertiesToType = (innerElement, elementType) => {
  switch (elementType) {
    case ELEMENT_TYPES.gallery.value: {
      return {
        description: innerElement.description,
        title: innerElement.title,
        position: innerElement.position,
        file: { ...innerElement.file },
      };
    }
    // case ELEMENT_TYPES.attachments.value: {
    //   return {
    //     position: innerElement.position,
    //     attachment: {
    //       ...innerElement.attachment,
    //       id: undefined,
    //       url: undefined,
    //     },
    //   };
    // }
    case ELEMENT_TYPES.timeline.value: {
      return {
        ...innerElement,
        isCurrent: innerElement.current,
        current: undefined,
        backendIndex: undefined,
        imageUrl: undefined,
        thumbUrl: undefined,
      };
    }
    case ELEMENT_TYPES.grid.value: {
      return {
        ...innerElement,
        backendIndex: undefined,
        imageUrl: undefined,
        buttons: isEmpty(innerElement.buttons)
          ? undefined
          : innerElement.buttons.map(button => ({
              ...button,
              backendIndex: undefined,
            })),
      };
    }

    case ELEMENT_TYPES.pageHeader.value: {
      if (innerElement.url) {
        return {
          ...innerElement,
          backendIndex: undefined,
          cssClasses: innerElement?.cssClasses?.join(' '),
        };
      }

      return {
        position: innerElement.position,
        file: { ...innerElement.file },
        ...innerElement,
        backendIndex: undefined,
        imageUrl: undefined,
        thumbUrl: undefined,
      };
    }

    case ELEMENT_TYPES.successStory.value: {
      return {
        ...innerElement,
        backendIndex: undefined,
      };
    }

    default: {
      return { ...innerElement, backendIndex: undefined, cssClasses: innerElement?.cssClasses?.join(' ') };
    }
  }
};

const mapInnerElements = (innerElements, elementType) => {
  return innerElements.reduce((mappedInnerElements, innerElement) => {
    if (innerElement?.file?.delete || innerElement?.attachment?.file?.delete) {
      return mappedInnerElements;
    }

    mappedInnerElements[innerElement.backendIndex] = { ...mapInnerElementPropertiesToType(innerElement, elementType) };

    return mappedInnerElements;
  }, {});
};

const mapSectionElements = sectionElements => {
  return sectionElements?.reduce((mappedSectionElements, sectionElement) => {
    mappedSectionElements[sectionElement.backendIndex] = {
      ...sectionElement,
      ...(sectionElement.elements && { elements: mapInnerElements(sectionElement.elements, sectionElement.type) }),
      ...(sectionElement.buttons && { buttons: mapInnerElements(sectionElement.buttons, sectionElement.type) }),
      ...(!isEmpty(sectionElement.items) && { items: mapInnerElements(sectionElement.items, sectionElement.type) }),
      ...(!isEmpty(sectionElement.tags) && { tags: mapInnerElements(sectionElement.tags, sectionElement.type) }),
      ...(!isEmpty(sectionElement.images) && { images: mapInnerElements(sectionElement.images, sectionElement.type) }),
      backendIndex: undefined,
      thumbUrl: undefined,
      imageUrl: undefined,
      cssClasses: sectionElement?.cssClasses?.join(' '),
    };

    return mappedSectionElements;
  }, {});
};

const mapSectionsToSave = sections => {
  const result = sections?.reduce?.((mappedSections, section) => {
    mappedSections[section.backendIndex] = {
      ...section,
      elements: mapSectionElements(section?.elements),
      backendIndex: undefined,
    };

    return mappedSections;
  }, {});

  return result;
};

export default mapSectionsToSave;
