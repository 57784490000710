import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getPostsList, getPostsListStatus, getPostsQueryParams } from '@store/reducers/blog/selectors';
import { getBlog, clearStatus, deletePost } from '@store/actions/blog';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import Main from './Main';

const mapStateToProps = state => ({
  posts: getPostsList(state),
  status: getPostsListStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  queryParams: getPostsQueryParams(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getBlog,
      clearStatus,
      deletePost,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
