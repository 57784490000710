import React from 'react';
import { Button, Form, Grid, Icon, Label, Segment } from 'semantic-ui-react';
import { Select } from '@shared/components';
import {
  Target,
  TargetOptions,
} from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements/components/GridElement/consts';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { InputField } from '@shared/components/translatable';
import { TranslatableTextField } from '@screens/offers/NewOfferForm/components';
import { bool, func, number, shape } from 'prop-types';

// #todo add error handling

const GridElementItemButton = ({
  element,
  isViewMode,
  isArrowDownDisabled,
  isArrowUpDisabled,
  onAddButton,
  onChange,
  sectionNumber,
  gridElementPosition,
  gridElementItemPosition,
}) => {
  const { t } = useTranslation(TRANSLATIONS.OFFERS);

  const onArrowUp = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === gridElementItemPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              buttons: innerElement.buttons.reduce((buttonAcc, button) => {
                                const prevItem = innerElement.buttons.find(
                                  item => item.position === element.position - 1,
                                );
                                const matchingItem = innerElement.buttons.find(
                                  item => item.position === element.position,
                                );

                                if (button.position === prevItem.position) {
                                  return buttonAcc.concat({ ...matchingItem, position: prevItem.position });
                                }
                                if (button.position === matchingItem.position) {
                                  return buttonAcc.concat({ ...prevItem, position: matchingItem.position });
                                }

                                return buttonAcc.concat([button]);
                              }, []),
                            },
                          ]);
                        }

                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onArrowDown = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === gridElementItemPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              buttons: innerElement.buttons.reduce((buttonAcc, button) => {
                                const nextItem = innerElement.buttons.find(
                                  item => item.position === element.position + 1,
                                );
                                const matchingItem = innerElement.buttons.find(
                                  item => item.position === element.position,
                                );

                                if (button.position === matchingItem.position) {
                                  return buttonAcc.concat({ ...nextItem, position: matchingItem.position });
                                }
                                if (button.position === nextItem.position) {
                                  return buttonAcc.concat({ ...matchingItem, position: nextItem.position });
                                }
                                return buttonAcc.concat([innerElement]);
                              }, []),
                            },
                          ]);
                        }

                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onRemoveButton = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove grid button? Button changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (gridElementPosition === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        items: el.items.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === gridElementItemPosition) {
                            return innerElementsAcc.concat([
                              {
                                ...innerElement,
                                buttons: innerElement.buttons.reduce((buttonsAcc, button) => {
                                  if (button.position === element.position) {
                                    return buttonsAcc;
                                  }
                                  if (button.position > element.position) {
                                    return buttonsAcc.concat([{ ...button, position: button.position - 1 }]);
                                  }
                                  return buttonsAcc.concat([button]);
                                }, []),
                              },
                            ]);
                          }

                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  };

  const onContentChange = (e, { name, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === gridElementItemPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              buttons: innerElement.buttons.reduce((buttonsAcc, button) => {
                                if (button.position === element.position) {
                                  return buttonsAcc.concat([
                                    {
                                      ...button,
                                      [name]: value,
                                    },
                                  ]);
                                }

                                return buttonsAcc.concat([button]);
                              }, []),
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onTranslatableContentChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (gridElementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === gridElementItemPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              buttons: innerElement.buttons.reduce((buttonsAcc, button) => {
                                if (button.position === element.position) {
                                  return buttonsAcc.concat([
                                    {
                                      ...button,
                                      [name]: {
                                        ...button[name],
                                        [language]: value,
                                      },
                                    },
                                  ]);
                                }
                                return buttonsAcc.concat([button]);
                              }, []),
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  return (
    <Segment>
      <Grid container stackable columns={1} divided>
        <Grid.Row className="pl-4 mb-8 d-flex align-items-center">
          <Label>
            {t('grid.item.button.title')} #{element.position}
          </Label>
          {isViewMode ? null : (
            <>
              <Button icon className="mr-2 ml-2" disabled={isArrowUpDisabled} onClick={onArrowUp}>
                <Icon name="arrow up" />
              </Button>
              <Button icon className="mr-2 ml-2" disabled={isArrowDownDisabled} onClick={onArrowDown}>
                <Icon name="arrow down" />
              </Button>
              <Button className="mr-2 ml-2" icon color="red" onClick={onRemoveButton}>
                <Icon name="trash" />
              </Button>
              <Button className="mr-2 ml-2" icon onClick={onAddButton}>
                <Icon name="plus" />
              </Button>
            </>
          )}
        </Grid.Row>
      </Grid>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <Select
            disabled={isViewMode}
            type="text"
            label={t('grid.item.button.target')}
            name="target"
            value={element?.target}
            onChange={onContentChange}
            options={TargetOptions}
            placeholder={Target.NOT_SET}
          />
          <InputField
            disabled={isViewMode}
            icon="fonticons fi"
            type="text"
            label={t('grid.item.button.icon')}
            name="icon"
            onChange={onContentChange}
            value={element?.icon}
          />
        </Grid>
      </Form.Group>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableTextField
            disabled={isViewMode}
            icon="font"
            label={t('grid.item.button.label')}
            // errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.label}
            name="label"
            onChange={onTranslatableContentChange}
            value={element?.label}
          />
        </Grid>
      </Form.Group>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableTextField
            disabled={isViewMode}
            onChange={onTranslatableContentChange}
            icon="font"
            label={t('grid.item.button.url')}
            value={element?.url}
            name="url"
          />
        </Grid>
      </Form.Group>
    </Segment>
  );
};

GridElementItemButton.propTypes = {
  gridElementPosition: number.isRequired,
  gridElementItemPosition: number.isRequired,
  sectionNumber: number.isRequired,
  element: shape({}),
  isViewMode: bool,
  isArrowDownDisabled: bool,
  isArrowUpDisabled: bool,
  onAddButton: func,
  onChange: func,
};

GridElementItemButton.defaultProps = {
  element: {},
  isViewMode: false,
  isArrowDownDisabled: false,
  isArrowUpDisabled: false,
  onAddButton: () => {},
  onChange: () => {},
};

export default GridElementItemButton;
