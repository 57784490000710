import React from 'react';
import { Grid, Button, Icon, Header } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import OfferSectionElement from '../elements/OfferSectionElement';
import OfferSectionElementModel from '../../models/OfferSectionElementModel';
import { isEmpty } from 'lodash';

class OfferSection extends React.Component {
  state = {
    isSectionExpanded: true,
  };

  constructor(props) {
    super(props);

    this.onRemoveSection = this.onRemoveSection.bind(this);
    this.onChangePositionArrowDown = this.onChangePositionArrowDown.bind(this);
    this.onChangePositionArrowUp = this.onChangePositionArrowUp.bind(this);
    this.onDropdownChangePosition = this.onDropdownChangePosition.bind(this);
    this.onAddSectionElement = this.onAddSectionElement.bind(this);
  }

  onRemoveSection(position) {
    const { onChange } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove section ${position}? Section changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === position) {
            return acc;
          }
          if (section.position > position) {
            return acc.concat({ ...section, position: section.position - 1 });
          }
          return acc.concat(section);
        }, []),
      }));
    }
  }

  onChangePositionArrowDown(position) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        const nextItem = prevState.offerSections.find(item => item.position === position + 1);
        const matchingItem = prevState.offerSections.find(item => item.position === position);

        if (section.position === matchingItem.position) {
          return acc.concat({ ...nextItem, position: matchingItem.position });
        }

        if (section.position === nextItem.position) {
          return acc.concat({ ...matchingItem, position: nextItem.position });
        }

        return acc.concat(section);
      }, []),
    }));
  }

  onChangePositionArrowUp(position) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        const prevItem = prevState.offerSections.find(item => item.position === position - 1);
        const matchingItem = prevState.offerSections.find(item => item.position === position);

        if (section.position === prevItem.position) {
          return acc.concat({ ...matchingItem, position: prevItem.position });
        }

        if (section.position === matchingItem.position) {
          return acc.concat({ ...prevItem, position: matchingItem.position });
        }

        return acc.concat(section);
      }, []),
    }));
  }

  onDropdownChangePosition({ currentPosition, newPosition }) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        const currentPositionItem = prevState.offerSections.find(item => item.position === currentPosition);
        const newPositionItem = prevState.offerSections.find(item => item.position === newPosition);

        if (section.position === currentPositionItem.position) {
          return acc.concat({ ...currentPositionItem, position: newPositionItem.position });
        }

        if (section.position === newPositionItem.position) {
          return acc.concat({ ...newPositionItem, position: currentPositionItem.position });
        }

        return acc.concat(section);
      }, []),
    }));
  }

  onAddSectionElement(position) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === position) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.concat([
                {
                  ...OfferSectionElementModel,
                  position: section?.elements?.[section?.elements?.length - 1]?.position + 1 || 1,
                  backendIndex: section?.elements?.[section?.elements?.length - 1]?.backendIndex + 1 || 0,
                },
              ]),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  renderSectionElements() {
    const { section, onChange, sectionErrors, isViewMode, t } = this.props;

    if (isEmpty(section?.elements)) {
      return (
        <div
          className="d-flex align-items-center justify-content-center flex-column w-100 text-center"
          style={{ minHeight: 420 }}
        >
          <Icon name="info circle" />
          <Header
            style={{
              height: 'fit-content',
              margin: '16px 0 8px',
            }}
          >
            No elements in this section
          </Header>
          <Header.Subheader
            style={{
              height: 'fit-content',
            }}
          >
            Add new element by clicking on the button below
          </Header.Subheader>
          <Button className="mt-4" secondary onClick={() => this.onAddSectionElement(1)}>
            {t('addElement')}
          </Button>
        </div>
      );
    }

    return section.elements.map(element => {
      return (
        <OfferSectionElement
          isViewMode={isViewMode}
          key={element.position}
          element={element}
          elementErrors={sectionErrors?.elements?.[element?.backendIndex]}
          sectionNumber={section.position}
          onChange={onChange}
          disableUpArrow={element.position === section.elements?.[0]?.position}
          disableDownArrow={element.position === section.elements?.[section.elements.length - 1]?.position}
        />
      );
    });
  }

  toggleIsExpanded = () =>
    this.setState(prevState => ({ ...prevState, isSectionExpanded: !prevState.isSectionExpanded }));

  render() {
    return this.state.isSectionExpanded && <Grid columns={12}>{this.renderSectionElements()}</Grid>;
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferSection);
