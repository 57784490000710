import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { BlogForm } from '@screens/blog/components';

const PostModel = {
  isVisible: false,
  image: {
    base64: '',
    fileName: '',
    delete: false,
  },
  slug: {
    en: '',
    pl: '',
  },
  title: {
    en: '',
    pl: '',
  },
  description: {
    en: '',
    pl: '',
  },
  seoTitle: {
    en: '',
    pl: '',
  },
  seoDescription: {
    en: '',
    pl: '',
  },
  content: {
    en: '',
    pl: '',
  },
  author: '',
  tags: [],
  position: 1,
  publishedAt: '',
};

const Main = ({ actions, status, errors, permittedPermissions }) => {
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  return (
    <BlogForm
      isViewMode={!isUserPermitted(PERMISSIONS.CREATE_BLOG)}
      onSave={actions.createPost}
      post={PostModel}
      isLoading={status === FETCH_STATUS.LOADING}
      errors={errors}
    />
  );
};

Main.propTypes = {
  actions: shape({ createPost: func }).isRequired,
  errors: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
  status: string,
};
Main.defaultProps = {
  errors: [],
  permittedPermissions: [],
  status: undefined,
};

export default Main;
