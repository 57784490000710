import React, { useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextField, Typography, useMediaQuery } from '@material-ui/core';
import { Button } from 'semantic-ui-react';
import { useTheme } from '@material-ui/styles';
import ROUTES from '@shared/consts/routes';
import TRANSLATIONS from '@translations/translationNamespaces';
import { USER_LOGIN_SUCCESS } from '@store/action-types/user';
import { Message, UltifideLogo } from '@shared/components';
import { Link, NavLink, useHistory } from 'react-router-dom';
import useStyles from './styles';

const Main = ({ actions, loginError }) => {
  const history = useHistory();
  const styles = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [t] = useTranslation(TRANSLATIONS.UNAUTHORIZED);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  const [data, setData] = useState({
    email: '',
    password: '',
  });

  const handleChange = event => {
    const name = event.target.name;

    setData({ ...data, [name]: event.target.value });
  };

  const handleSubmit = async e => {
    await e.preventDefault();

    const loginResponse = await actions.loginUser({ history, userLogin: data.email, userPassword: data.password });

    if (loginResponse?.type === USER_LOGIN_SUCCESS) {
      await actions.getUserInfo();
    }
  };

  return (
    <div>
      <div className={styles.logoContainer}>
        <Typography color="textSecondary" variant="body2" to={ROUTES.HOME} component={Link}>
          <UltifideLogo style={{ maxWidth: 150 }} center />
        </Typography>
      </div>
      <div className="text-center mb-2">
        <h2 className="font-weight-bold">{t('login.title')}</h2>
        <p className="text-muted font-weight-bold">{t('login.subtitle')}</p>
      </div>
      <div className="my-4">
        {loginError?.map?.(code => (
          <Message danger>{error(String(code))}</Message>
        ))}
      </div>
      <form
        onSubmit={handleSubmit}
        className={isXs ? `${styles.formContainer} ${styles.formContainerMobile}` : styles.formContainer}
      >
        <TextField
          value={data.email}
          name="email"
          onChange={e => handleChange(e)}
          variant="outlined"
          label={t('login.email')}
          fullWidth
          className={styles.input}
        />
        <TextField
          value={data.password}
          name="password"
          type="password"
          onChange={e => handleChange(e)}
          variant="outlined"
          label={t('login.password')}
          fullWidth
          margin="normal"
          className={styles.input}
        />
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
          <div className="my-3 mr-2">
            <NavLink to={ROUTES.FORGOT_PASSWORD} className="text-muted text-hover-primary">
              {t('login.forgotPassword')}
            </NavLink>
          </div>
          <Button color="facebook" type="submit">
            {t('login.submit')}
          </Button>
        </div>
      </form>
    </div>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  loginError: arrayOf(shape({})),
};

Main.defaultProps = {
  loginError: [],
};

export default Main;
