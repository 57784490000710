import { ContactForm } from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements/components/ContactFormElement/consts';

const ContactFormElementModel = {
  codeName: ContactForm.Contact,
  title: {
    pl: '',
    en: '',
  },
};

export default ContactFormElementModel;
