import { EDIT_TAG_INIT, EDIT_TAG_SUCCESS, EDIT_TAG_FAILURE } from '@store/action-types/blog';
import Client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';
import history from '@store/history';

const editTagInit = () => ({ type: EDIT_TAG_INIT });
const editTagSuccess = () => ({ type: EDIT_TAG_SUCCESS });
const editTagFailure = error => {
  if (!error?.errors) {
    return { type: EDIT_TAG_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: EDIT_TAG_FAILURE, payload: { error: mappedErrors } };
};

const editTag = ({ tag, tagId, saveAndBack = false }) => async dispatch => {
  dispatch(editTagInit());

  try {
    await Client.editTag({
      data: {
        ...tag,
        imageUrl: undefined,
        thumbUrl: undefined,
        id: undefined,
      },
      slug: tagId,
    });

    if (saveAndBack) {
      history.push(ROUTES.BLOG_TAGS);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.tags.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    dispatch(editTagSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.tags.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editTagFailure(error));
  }
};

export default editTag;
