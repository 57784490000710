import React, { useState } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { TextField, useMediaQuery, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { UltifideLogo, Message } from '@shared/components';
import ROUTES from '@shared/consts/routes';
import useMount from '@shared/utils/useMountHook';
import { Link, NavLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const Main = ({ actions, errors }) => {
  const styles = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitEnable, setSubmitEnable] = useState(true);

  const [t] = useTranslation(TRANSLATIONS.UNAUTHORIZED);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  useMount(() => {
    actions.clearError();
  });

  const handleSubmit = async e => {
    await e.preventDefault();
    await setSubmitEnable(false);

    const resetPasswordStatus = await actions.resetPasswordAction(email);

    if (resetPasswordStatus?.type === '@user/RESET_PASSWORD_SUCCESS') {
      await setSubmitEnable(true);
      await setSuccess(true);
    }

    await setSubmitEnable(true);
  };

  const renderSuccessMessage = () => {
    return (
      <div className="d-flex flex-column align-items-center mt-5">
        <p>{t('forgotPassword.success')}</p>
        <div className="my-3 mr-2">
          <NavLink to={ROUTES.LOGIN} className="text-muted text-hover-primary">
            {t('forgotPassword.backToLogin')}
          </NavLink>
        </div>
      </div>
    );
  };

  const renderForgotPasswordForm = () => {
    return (
      <>
        <div className="text-center mb-4">
          <h2 className="font-weight-bold">{t('forgotPassword.title')}</h2>
          <p className="text-muted font-weight-bold">{t('forgotPassword.subtitle')}</p>
        </div>

        {!isEmpty(errors) && (
          <Message className="mb-1" danger>
            {errors?.map?.(code => error(String(code)))}
          </Message>
        )}

        <form
          className={isXs ? `${styles.formContainer} ${styles.formContainerMobile}` : styles.formContainer}
          onSubmit={handleSubmit}
        >
          <TextField
            value={email}
            name="email"
            onChange={e => setEmail(e.target.value)}
            variant="outlined"
            label={t('forgotPassword.email')}
            fullWidth
            className={styles.input}
            error={!isEmpty(errors)}
            FormHelperTextProps={{
              className: styles.helperText,
            }}
          />
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
            <div className="my-3 mr-2">
              <Link to={ROUTES.LOGIN} className="text-muted text-hover-primary">
                {t('forgotPassword.signIn')}
              </Link>
            </div>
            <Button color="facebook" disabled={!submitEnable} type="submit">
              {t('forgotPassword.submit')}
            </Button>
          </div>
        </form>
      </>
    );
  };

  return (
    <div>
      <div className={styles.logoContainer}>
        <Typography color="textSecondary" variant="body2" to={ROUTES.HOME} component={Link}>
          <UltifideLogo style={{ maxWidth: 150 }} center />
        </Typography>
      </div>
      {success ? renderSuccessMessage() : renderForgotPasswordForm()}
    </div>
  );
};

Main.propTypes = {
  actions: shape({ resetPasswordAction: func }).isRequired,
  errors: arrayOf(shape({})),
};

Main.defaultProps = {
  errors: [],
};

export default Main;
