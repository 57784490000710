import React, { useRef } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { isEmpty } from 'lodash';
import {
  PageHeaderButtonModel,
  PageHeaderElementModel,
} from '@screens/offers/NewOfferForm/OfferForm/models/elementModels';
import { Button, Divider, Dropdown, Form, Grid, Icon, Image, Input, Label, Segment } from 'semantic-ui-react';
import { InputField, TranslatableTextField, TranslatableRichTextField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import VALID_IMAGE_MIME_TYPES from '@screens/offers/NewOfferForm/OfferForm/models/ImageTypes';
import { Select } from '@shared/components';
import { ButtonCssClasses } from '@shared/consts/css-classes';
import {
  DesignOptions,
  LayoutOptions,
  TargetOptions,
} from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements/components/PageHeaderElement/consts';
import { PageHeaderImageElementModel } from '@screens/offers/NewOfferForm/OfferForm/models/elementModels/PageHeaderElementModel';
import { PageHeaderElementErrors } from './PropTypes';
import { PageHeaderImageElement } from './components';

const PageHeaderElement = ({ element, elementErrors, onChange, sectionNumber, isViewMode }) => {
  const { t } = useTranslation(TRANSLATIONS.OFFERS);

  const onSelectChange = (e, { name, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: value,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onRemoveButton = innerElementPosition => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove header button? Button changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (element.position === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        buttons: el.buttons.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === innerElementPosition) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > innerElementPosition) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  };

  const onArrowUp = innerElementPosition =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      buttons: el.buttons.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.buttons.find(item => item.position === innerElementPosition - 1);
                        const matchingItem = el.buttons.find(item => item.position === innerElementPosition);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onArrowDown = innerElementPosition =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      buttons: el.buttons.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.buttons.find(item => item.position === innerElementPosition + 1);
                        const matchingItem = el.buttons.find(item => item.position === innerElementPosition);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onTranslatableChange = (e, { value, name, language }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: {
                        ...el[name],
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onAddPageHeaderButton = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      buttons: el.buttons.concat([
                        {
                          ...PageHeaderButtonModel,
                          position: el?.buttons?.[el?.buttons?.length - 1]?.position + 1 || 1,
                          backendIndex: Number(el?.buttons?.length) || 0,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onAddPageHeaderImage = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      images: el.images.concat([
                        {
                          ...PageHeaderImageElementModel,
                          position: el?.images?.[el?.images?.length - 1]?.position + 1 || 1,
                          backendIndex: Number(el?.images?.length) || 0,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onButtonChange = (e, { name, value, position }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      buttons: el.buttons.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === position) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: value,
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  const onTranslatableButtonChange = (e, { name, value, language, position }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      buttons: el.buttons.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === position) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: {
                                ...innerElement[name],
                                [language]: value,
                              },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const renderPageHeaderButtons = () => (
    <Segment>
      {element?.buttons?.map((button, i) => (
        <React.Fragment key={button.position}>
          {button.position > 1 && <Divider className="mt-4 mb-5" />}
          <Grid container stackable columns={1} divided>
            <Grid.Row className="pl-4 mb-8 d-flex align-items-center">
              <Label>
                {t('pageHeader.button.title')} #{button.position}
              </Label>
              {isViewMode ? null : (
                <>
                  <Button
                    icon
                    className="mr-2 ml-2"
                    disabled={button.position === element.buttons[0].position}
                    onClick={() => onArrowUp(button.position)}
                  >
                    <Icon name="arrow up" />
                  </Button>
                  <Button
                    icon
                    className="mr-2 ml-2"
                    disabled={button.position === element.buttons[element.buttons.length - 1].position}
                    onClick={() => onArrowDown(button.position)}
                  >
                    <Icon name="arrow down" />
                  </Button>
                  <Button className="mr-2 ml-2" icon color="red" onClick={() => onRemoveButton(button.position)}>
                    <Icon name="trash" />
                  </Button>
                </>
              )}
            </Grid.Row>
          </Grid>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                type="text"
                name="label"
                label={t('pageHeader.button.label')}
                errorMessage={elementErrors?.buttons?.[i]?.label}
                value={button?.label}
                icon="font"
                onChange={(e, { name, language, value }) =>
                  onTranslatableButtonChange(e, { name, language, value, position: button.position })
                }
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                type="text"
                name="url"
                label={t('pageHeader.button.url')}
                errorMessage={elementErrors?.buttons?.[i]?.url}
                value={button?.url}
                icon="anchor"
                onChange={(e, { name, language, value }) =>
                  onTranslatableButtonChange(e, { name, language, value, position: button.position })
                }
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <Select
                disabled={isViewMode}
                type="text"
                label={t('pageHeader.button.target')}
                name="target"
                value={button.target}
                onChange={(e, { name, value }) => onButtonChange(e, { name, value, position: button.position })}
                options={TargetOptions}
                placeholder="_self"
              />
              <InputField
                disabled={isViewMode}
                icon="fonticons fi"
                type="text"
                label={t('pageHeader.button.icon')}
                name="icon"
                value={button.icon}
                onChange={(e, { name, value }) => onButtonChange(e, { name, value, position: button.position })}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={1} divided>
              <Grid.Column>
                <Dropdown
                  className="w-100"
                  disabled={isViewMode}
                  placeholder={t('cssClasses')}
                  selection
                  name="cssClasses"
                  value={button?.cssClasses}
                  options={ButtonCssClasses}
                  multiple
                  onChange={(e, { name, value }) => onButtonChange(e, { name, value, position: button.position })}
                />
              </Grid.Column>
            </Grid>
          </Form.Group>
        </React.Fragment>
      ))}
      <div className="d-flex justify-content-center align-items-center p-4">
        <Button onClick={onAddPageHeaderButton}>
          <Icon name="plus" />
          {t('pageHeader.button.add')}
        </Button>
      </div>
    </Segment>
  );

  return (
    <>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            type="text"
            name="content"
            label={t('pageHeader.content')}
            errorMessage={elementErrors?.content}
            value={element?.content}
            icon="font"
            onChange={onTranslatableChange}
            disabled={isViewMode}
          />
        </Grid>
      </Form.Group>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <Select
            disabled={isViewMode}
            type="text"
            label={t('pageHeader.button.layout')}
            name="layout"
            value={element.layout}
            onChange={(e, { name, value }) => onSelectChange(e, { name, value })}
            options={LayoutOptions}
            placeholder="Text Left - Image Right"
          />
          <Select
            disabled={isViewMode}
            type="text"
            label={t('pageHeader.button.design')}
            name="design"
            value={element.design}
            onChange={(e, { name, value }) => onSelectChange(e, { name, value })}
            options={DesignOptions}
            placeholder="Header"
          />
        </Grid>
      </Form.Group>
      {isEmpty(element.images) ? (
        <Segment>
          <div className="d-flex justify-content-center align-items-center p-4">
            <Button onClick={onAddPageHeaderImage} disabled={element?.images?.length >= 3}>
              <Icon name="plus" />
              {t('pageHeader.image.add')}
            </Button>
          </div>
        </Segment>
      ) : (
        <Segment>
          {isViewMode ? null : (
            <div className="d-flex justify-content-center align-items-center p-4">
              <Button onClick={onAddPageHeaderImage} disabled={element?.images?.length >= 3}>
                <Icon name="plus" />
                {t('pageHeader.image.add')}
              </Button>
            </div>
          )}
          {element.images.map((image, index) => (
            <PageHeaderImageElement
              element={image}
              isViewMode={isViewMode}
              index={index}
              sectionNumber={sectionNumber}
              elementPosition={element?.position}
              onChange={onChange}
              isArrowUpDisabled={image.position === element.images[0].position}
              isArrowDownDisabled={image.position === element.images[element.images.length - 1].position}
            />
          ))}
        </Segment>
      )}
      {isEmpty(element.buttons) ? (
        <div className="d-flex justify-content-center align-items-center p-4">
          <Button onClick={onAddPageHeaderButton}>
            <Icon name="plus" />
            {t('pageHeader.button.add')}
          </Button>
        </div>
      ) : (
        renderPageHeaderButtons()
      )}
    </>
  );
};

PageHeaderElement.propTypes = {
  sectionNumber: number.isRequired,
  element: shape({
    position: number,
    cssClasses: string,
    layout: string,
    title: {
      pl: string,
      en: string,
    },
    content: {
      pl: string,
      en: string,
    },
    images: arrayOf(
      shape({
        position: number,
        imageUrl: string,
      }),
    ),
    buttons: [
      arrayOf({
        label: {
          pl: '',
          en: '',
        },
        url: {
          en: '',
          pl: '',
        },
        position: 1,
        cssClasses: '',
        target: '',
        icon: '',
      }),
    ],
  }),
  elementErrors: PageHeaderElementErrors,
  onChange: func,
  isViewMode: bool,
};

PageHeaderElement.defaultProps = {
  element: PageHeaderElementModel,
  elementErrors: {},
  onChange: () => {},
  isViewMode: false,
};

export default PageHeaderElement;
