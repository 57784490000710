import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Divider, Button, Icon } from 'semantic-ui-react';
import {
  DatePickerField,
  InputField,
  SwitchField,
  TranslatableTextField,
} from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import styles from '@screens/blog/components/BlogForm/BlogForm.module.scss';
import { VALID_IMAGE_MIME_TYPES } from '@screens/page/const';
import { validateDocumentType, validateFileSize, validateImageType } from '@screens/page/utils';
import { TagSelect } from '@screens/blog/components/BlogForm/components';

const BlogFormMain = ({ isViewMode, postData, errors, onChange }) => {
  const { t } = useTranslation(TRANSLATIONS.BLOG);
  const [imageError, setImageError] = React.useState(null);

  const onImageChange = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if ((validateDocumentType([file]) || validateImageType([file])) && validateFileSize([file], 0, 10000000)) {
      setImageError('');

      if (file) {
        reader.onloadend = () => {
          return onChange(prevState => ({
            ...prevState,
            image: {
              base64: reader.result,
              fileName: file.name,
              delete: false,
            },
          }));
        };

        return reader.readAsDataURL(file);
      }

      return setImageError(
        'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
      );
    }

    return setImageError(
      'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
    );
  };

  const onDeleteImage = e => {
    e.preventDefault();
    setImageError('');
    onChange(prevState => ({
      ...prevState,
      image: {
        base64: '',
        fileName: '',
        delete: true,
      },
    }));
  };

  const onTagsChange = (e, { value }) => {
    onChange(prevState => ({
      ...prevState,
      tags: value,
    }));
  };

  const onSwitchChange = (e, { name, checked }) =>
    onChange(prevState => ({
      ...prevState,
      [name]: checked,
    }));

  const onInputChange = (e, { name, value }) =>
    onChange(prevState => ({
      ...prevState,
      [name]: value,
    }));

  const onTranslatableInputChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));

  return (
    <>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <Grid.Column className="d-flex justify-content-around align-items-center">
            <SwitchField
              disabled={isViewMode}
              onChange={onSwitchChange}
              className="text-center"
              name="isVisible"
              value={postData?.isVisible}
              label={t('form.isVisible')}
            />
          </Grid.Column>
          <DatePickerField
            showTimeSelect
            disabled={isViewMode}
            label={t('form.publishedAt')}
            name="publishedAt"
            onChange={onInputChange}
            value={postData?.publishedAt}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
            errorMessage={errors?.publishedAt}
          />
        </Grid>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Grid container stackable columns={isViewMode ? 1 : 2} divided>
          {isViewMode ? null : (
            <Grid.Column>
              <div className={styles['page-image-upload--buttons']}>
                <div className="page-errors">{imageError && <p style={{ color: 'red' }}>{imageError}</p>}</div>

                <Form.Field>
                  <label aria-controls="" htmlFor="create-page-add-file">
                    <p>{t('form.imageUpload')}</p>
                    <input
                      id="create-page-add-file"
                      type="file"
                      onChange={onImageChange}
                      accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                    />
                  </label>
                  {(postData?.image?.base64 || postData?.thumbUrl || postData?.imageUrl) && (
                    <Button className="mb-2" color="red" variant="outlined" type="button" onClick={onDeleteImage}>
                      {t('delete')}
                    </Button>
                  )}
                </Form.Field>

                <br />
              </div>
            </Grid.Column>
          )}

          <Grid.Column>
            <div className="page-image-upload--preview">
              <Form.Field>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>{t('form.imagePreview')}</label>
                {postData?.image?.base64 || postData?.thumbUrl || postData?.imageUrl ? (
                  <img
                    style={{
                      filter: postData?.image?.delete ? 'grayscale(100%)' : 'none',
                      opacity: postData?.image?.delete ? 0.5 : 1,
                    }}
                    src={postData?.image?.base64 || postData?.thumbUrl || postData?.imageUrl}
                    alt="preview"
                  />
                ) : (
                  <div
                    className="d-flex align-items-center"
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: 1,
                    }}
                  >
                    <p className="mb-0 mr-1"> {t('form.noImageUploaded')} </p>
                    <Icon name="images outline" />
                  </div>
                )}
              </Form.Field>
            </div>
          </Grid.Column>
        </Grid>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <InputField
            disabled={isViewMode}
            onChange={onInputChange}
            icon="numbered list"
            label={t('form.position')}
            name="position"
            type="number"
            value={postData?.position}
            errorMessage={errors.position}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
          <InputField
            disabled={isViewMode}
            icon="user"
            onChange={onInputChange}
            label={t('form.author')}
            name="author"
            value={postData?.author}
            errorMessage={errors?.author}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
        </Grid>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableTextField
            disabled={isViewMode}
            name="slug"
            onChange={onTranslatableInputChange}
            label={t('form.slug')}
            value={postData?.slug}
            errorMessage={errors?.slug}
            icon="linkify"
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
        </Grid>
      </Form.Group>
      <Divider />
      <TagSelect
        isViewMode={isViewMode}
        errorMessage={errors?.tags}
        labelClassName="d-flex align-items-center justify-content-center w-fit-content"
        onChange={onTagsChange}
        value={postData?.tags}
      />
    </>
  );
};

BlogFormMain.propTypes = {
  isViewMode: PropTypes.bool,
  postData: PropTypes.shape({
    thumbUrl: PropTypes.string,
    imageUrl: PropTypes.string,
    isVisible: PropTypes.bool,
    image: PropTypes.shape({
      base64: PropTypes.string,
      delete: PropTypes.bool,
    }),
    position: PropTypes.number,
    publishedAt: PropTypes.string,
    slug: PropTypes.shape({
      en: PropTypes.string,
      pl: PropTypes.string,
    }),
    tags: PropTypes.arrayOf(PropTypes.string),
    author: PropTypes.string,
  }),
  errors: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    author: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.string,
    publishedAt: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

BlogFormMain.defaultProps = {
  isViewMode: false,
  postData: {},
  errors: {},
  onChange: () => {},
};

export default BlogFormMain;
