import { map } from 'lodash';
import i18next from 'i18next';

const getMenuQueryParams = state => state?.menu?.queryParams;

const getMenuItemList = state => state?.menu?.list?.data;

const getMenuItemListStatus = state => state?.menu?.list?.status;

const getMenuItem = state => state?.menu?.menuItem;

const getMenuItemStatus = state => state?.menu?.status;

const getMenuError = state => state?.menu?.error;

const transformMenuItem = items => {
  return items.reduce((acc, item) => {
    acc.push({ key: item.id, value: item.id, text: item.label?.[i18next.language] });

    if (item.children.length > 0) {
      acc.push(...transformMenuItem(item.children));
    }

    return acc;
  }, []);
};

const getMenuItemsAsOptions = state => transformMenuItem(getMenuItemList(state));

export {
  getMenuQueryParams,
  getMenuItemList,
  getMenuItemListStatus,
  getMenuItem,
  getMenuItemStatus,
  getMenuItemsAsOptions,
  getMenuError,
};
