import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Form, Grid } from 'semantic-ui-react';
import { TranslatableRichTextField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const BlogFormContent = ({ isViewMode, postData, errors, onChange }) => {
  const { t } = useTranslation(TRANSLATIONS.BLOG);

  const onTranslatableInputChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));

  return (
    <>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            disabled={isViewMode}
            label={t('form.title')}
            name="title"
            errorMessage={errors?.title}
            value={postData?.title}
            onChange={onTranslatableInputChange}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
        </Grid>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            disabled={isViewMode}
            label={t('form.description')}
            name="description"
            errorMessage={errors?.description}
            value={postData?.description}
            onChange={onTranslatableInputChange}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
        </Grid>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            disabled={isViewMode}
            label={t('form.content')}
            name="content"
            errorMessage={errors?.content}
            value={postData?.content}
            onChange={onTranslatableInputChange}
            labelClassName="d-flex align-items-center justify-content-center w-fit-content"
          />
        </Grid>
      </Form.Group>
    </>
  );
};

BlogFormContent.propTypes = {
  isViewMode: PropTypes.bool,
  postData: PropTypes.shape({
    title: PropTypes.shape({
      en: PropTypes.string,
      pl: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      pl: PropTypes.string,
    }),
    content: PropTypes.shape({
      en: PropTypes.string,
      pl: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

BlogFormContent.defaultProps = {
  isViewMode: false,
  postData: {},
  errors: {},
  onChange: () => {},
};

export default BlogFormContent;
