const CounterItemModel = {
  position: 1,
  countFrom: 0,
  countTo: 100,
  color: '',
  prefix: '',
  label: { pl: '', en: '' },
};

export default CounterItemModel;
