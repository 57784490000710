import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardToolbar } from '@shared/components';
import { Button, Tab } from 'semantic-ui-react';
import ROUTES from '@shared/consts/routes';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import SCREEN_NAMES from '@shared/consts/screenNames';
import { isEmpty } from 'lodash';
import { PostsList } from './components';

const Main = ({
  status,
  posts,
  actions,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.BLOG);
  const [isLoading, setIsLoading] = useState(true);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    setIsLoading(true);
    actions.getBlog();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.BLOG]) {
      if (
        !isUserPermitted(PERMISSIONS.GET_BLOG_LIST) ||
        !isUserPermitted(PERMISSIONS.EDIT_BLOG) ||
        !isUserPermitted(PERMISSIONS.DELETE_BLOG) ||
        !isUserPermitted(PERMISSIONS.CREATE_BLOG) ||
        !isUserPermitted(PERMISSIONS.GET_BLOG_BY_ID) ||
        !isUserPermitted(PERMISSIONS.GET_TAGS_LIST) ||
        !isUserPermitted(PERMISSIONS.DELETE_TAGS) ||
        !isUserPermitted(PERMISSIONS.CREATE_TAGS) ||
        !isUserPermitted(PERMISSIONS.EDIT_TAGS) ||
        !isUserPermitted(PERMISSIONS.GET_TAGS_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.BLOG);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.GET_BLOG_LIST) && PERMISSIONS.GET_BLOG_LIST,
          !isUserPermitted(PERMISSIONS.EDIT_BLOG) && PERMISSIONS.EDIT_BLOG,
          !isUserPermitted(PERMISSIONS.DELETE_BLOG) && PERMISSIONS.DELETE_BLOG,
          !isUserPermitted(PERMISSIONS.CREATE_BLOG) && PERMISSIONS.CREATE_BLOG,
          !isUserPermitted(PERMISSIONS.GET_BLOG_BY_ID) && PERMISSIONS.GET_BLOG_BY_ID,
          !isUserPermitted(PERMISSIONS.GET_TAGS_LIST) && PERMISSIONS.GET_TAGS_LIST,
          !isUserPermitted(PERMISSIONS.DELETE_TAGS) && PERMISSIONS.DELETE_TAGS,
          !isUserPermitted(PERMISSIONS.CREATE_TAGS) && PERMISSIONS.CREATE_TAGS,
          !isUserPermitted(PERMISSIONS.EDIT_TAGS) && PERMISSIONS.EDIT_TAGS,
          !isUserPermitted(PERMISSIONS.GET_TAGS_BY_ID) && PERMISSIONS.GET_TAGS_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  useEffect(() => {
    status !== FETCH_STATUS.IDLE && status !== undefined && setIsLoading(false);
  }, [status, actions]);

  useEffect(() => {
    return () => actions.clearStatus();
  }, [actions]);

  const refreshPage = () => actions.getBlog();

  const tabs = [
    {
      menuItem: {
        as: NavLink,
        content: t('posts'),
        to: ROUTES.BLOG,
        exact: true,
        key: 'posts',
        name: 'posts',
      },
      pane: (
        <Tab.Pane key="posts">
          <PostsList
            isLoading={isLoading || status === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
            posts={posts}
            queryParams={queryParams}
            searchAction={actions.getBlog}
            reloadAction={refreshPage}
            deleteAction={actions.deletePost}
            isUserPermitted={isUserPermitted}
          />
        </Tab.Pane>
      ),
    },
    isUserPermitted(PERMISSIONS.GET_TAGS_LIST) && {
      ...{
        menuItem: {
          as: NavLink,
          content: t('tags'),
          to: ROUTES.BLOG_TAGS,
          exact: true,
          name: 'tags',
          key: 'tags',
        },
      },
    },
  ];

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar className="mb-5">
          <Button disabled={status === FETCH_STATUS.LOADING} onClick={refreshPage} color="black">
            {t('reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.CREATE_BLOG) ? (
            <Button as={Link} primary to={ROUTES.ADD_BLOG}>
              {t('create')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <Tab activeIndex={0} renderActiveOnly={false} menu={{ attached: 'top' }} panes={tabs} />
    </Card>
  );
};

Main.propTypes = {
  status: string,
  posts: arrayOf(
    shape({
      id: string,
      title: shape({
        en: string,
        ar: string,
      }),
      slug: shape({
        en: string,
        ar: string,
      }),
      publishedAt: string,
      isVisible: bool,
    }),
  ),
  actions: shape({
    getBlog: func,
    clearStatus: func,
    deleteBlogAction: func,
    setIsPermissionCheckedAction: func,
    checkPermissionAction: func,
  }),
  queryParams: shape({
    page: number,
    limit: number,
    search: string,
    sort: string,
    order: string,
  }),
  permittedPermissions: arrayOf(string),
  isPermissionChecked: shape({
    [SCREEN_NAMES.BLOG]: bool,
  }),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  status: undefined,
  posts: [],
  actions: {
    getBlog: () => {},
    clearStatus: () => {},
    deleteBlogAction: () => {},
    setIsPermissionCheckedAction: () => {},
    checkPermissionAction: () => {},
  },
  queryParams: {
    page: 1,
    limit: 10,
    search: '',
    sort: '',
    order: '',
  },
  permittedPermissions: [],
  isPermissionChecked: {
    [SCREEN_NAMES.BLOG]: false,
  },
  checkPermissionStatus: undefined,
};

export default Main;
