import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { BlogForm } from '@screens/blog/components';

const Main = ({ actions, post, status, errors, permittedPermissions }) => {
  const params = useParams();
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSinglePost(params.id);
  });

  const handleRefresh = () => actions.getSinglePost(params.id);

  return (
    <BlogForm
      isViewMode={!isUserPermitted(PERMISSIONS.CREATE_BLOG)}
      onSave={actions.editPost}
      onRefresh={handleRefresh}
      post={post}
      isLoading={status === FETCH_STATUS.LOADING || status === FETCH_STATUS.IDLE}
      errors={errors}
      isEdit
    />
  );
};

Main.propTypes = {
  actions: shape({ getSinglePost: func, editPost: func }).isRequired,
  post: shape({}),
  status: string,
  errors: shape({}),
  permittedPermissions: arrayOf(shape({})),
};
Main.defaultProps = {
  post: {},
  status: undefined,
  errors: {},
  permittedPermissions: [],
};

export default Main;
