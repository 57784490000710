import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getTagError, getTagStatus, getTag } from '@store/reducers/blog/selectors';
import { editTag, getTagById } from '@store/actions/blog';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  status: getTagStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  errors: getTagError(state),
  tag: getTag(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getTagById,
      editTag,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
