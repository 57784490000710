import React, { useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import useMount from '@shared/utils/useMountHook';
import { useParams } from 'react-router';
import { Button, TextField, Typography, useMediaQuery } from '@material-ui/core';
import ROUTES from '@shared/consts/routes';
import { Link, NavLink } from 'react-router-dom';
import { UltifideLogo, Message } from '@shared/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTheme } from '@material-ui/styles';
import { RESET_PASSWORD_SUCCESS } from '@store/action-types/user';
import useStyles from './styles';

const Main = ({ actions, passwordError }) => {
  const styles = useStyles();
  const params = useParams();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);
  const [password, setPassword] = useState({
    newPassword: '',
    repeatPassword: '',
  });
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const [t] = useTranslation(TRANSLATIONS.UNAUTHORIZED);

  useMount(() => {
    actions.validateResetPasswordTokenAction(params.resetPasswordToken);
  });

  const handleChange = e => {
    const key = e.target.name;
    const value = e.target.value;

    setPassword({
      ...password,
      [key]: value,
    });
  };

  const handleSubmit = async e => {
    await e.preventDefault();
    await setIsSubmitDisable(true);
    const resetPasswordResponse = await actions.submitResetPasswordAction(password, params.resetPasswordToken);
    resetPasswordResponse?.type === RESET_PASSWORD_SUCCESS && setIsSuccess(true);
    await setIsSubmitDisable(false);
  };

  const renderSetPasswordForm = () => (
    <form
      onSubmit={handleSubmit}
      className={isXs ? `${styles.formContainer} ${styles.formContainerMobile}` : styles.formContainer}
    >
      <TextField
        value={password?.newPassword}
        name="newPassword"
        variant="outlined"
        label="Password"
        type="password"
        fullWidth
        className={styles.input}
        onChange={handleChange}
      />
      <TextField
        value={password?.repeatPassword}
        name="repeatPassword"
        variant="outlined"
        label="Repeat password"
        type="password"
        fullWidth
        className={styles.input}
        onChange={handleChange}
      />
      <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
        <div className="my-3 mr-2">
          <NavLink to={ROUTES.LOGIN} className="text-muted text-hover-primary">
            {t('setPassword.signIn')}
          </NavLink>
        </div>
        <button
          type="submit"
          disabled={isSubmitDisable}
          className="btn btn-primary btn-sm font-weight-bold px-9 py-4 my-9"
        >
          {t('setPassword.submit')}
        </button>
      </div>
    </form>
  );

  const renderSuccessMessage = () => (
    <div className={styles.successMessageContainer}>
      <div className={styles.successMessageHeader}>
        <CheckCircleOutlineIcon className={styles.successIcon} />
        <Typography variant="h5">{t('setPassword.success')}</Typography>
      </div>
      <div className={styles.successMessageBody}>
        <Typography variant="h5">{t('setPassword.passwordSet')}</Typography>
        <Typography variant="h5">{t('setPassword.youCanLogin')}</Typography>
        <Button className={styles.backToLoginButton} to={ROUTES.LOGIN} component={Link} variant="outlined">
          {t('setPassword.backToLogin')}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.logoContainer}>
        <Typography color="textSecondary" variant="body2" to={ROUTES.HOME} component={Link}>
          <UltifideLogo style={{ maxWidth: 150 }} center />
        </Typography>
      </div>
      <div className="text-center mb-2">
        <h2 className="font-weight-bold">{t('setPassword.title')}</h2>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center my-2">
        {passwordError?.map?.(code => (
          <Message danger>{error(String(code))}</Message>
        ))}
      </div>
      {isSuccess ? renderSuccessMessage() : renderSetPasswordForm()}
    </div>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  passwordError: arrayOf(shape({})),
};

Main.defaultProps = {
  passwordError: [],
};

export default Main;
