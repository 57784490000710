import { arrayOf, number, shape, string } from 'prop-types';

export const PageHeaderButtonType = shape({
  label: {
    pl: '',
    en: '',
  },
  url: {
    en: '',
    pl: '',
  },
  position: 1,
  cssClasses: '',
  target: '',
  icon: '',
});

export const PageHeaderElementType = shape({
  position: number,
  cssClasses: string,
  title: {
    pl: string,
    en: string,
  },
  content: {
    pl: string,
    en: string,
  },
  buttons: [arrayOf(PageHeaderButtonType)],
});

export const PageHeaderElementErrors = shape({
  title: string,
  content: string,
  buttons: [
    arrayOf(
      shape({
        label: string,
        url: string,
      }),
    ),
  ],
});
