import React from 'react';
import { Dropdown, Form, Grid, Segment, Button, Input } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from '@api-sdk/consts';
import { TranslatableTextField } from '../../../../../components';
import Marker from './Marker';
import './Map.scss';
import { Tooltip } from '@shared/components';

const ZOOM_VALUES = [
  { key: 15, value: 15, text: 15 },
  { key: 16, value: 16, text: 16 },
  { key: 17, value: 17, text: 17 },
  { key: 18, value: 18, text: 18 },
];

class MapElement extends React.PureComponent {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    place: '',
    draggable: true,
    lat: null,
    lng: null,
  };

  constructor(props) {
    super(props);

    this.onAddressChange = this.onAddressChange.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.onSearchAddress = this.onSearchAddress.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mapApi !== this.state.mapApi) {
      const { mapInstance: map, mapApi } = this.state;

      this.mapService = new mapApi.places.PlacesService(map);
    }
  }

  onAddressChange(e, { value, name, language }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: {
                        ...el[name],
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onPlaceChange(place) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      latitude: place.lat,
                      longitude: place.lng,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onZoomChange(e, { value, name }) {
    const { mapInstance: map } = this.state;
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: value,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

    map.setZoom(value || ZOOM_VALUES[1].value);
  }

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
  };

  onSearchAddress() {
    const { mapInstance: map } = this.state;
    const { element } = this.props;
    const request = {
      query: this.state.place,
      fields: ['name', 'geometry'],
    };

    this.mapService.findPlaceFromQuery(request, (results, status) => {
      if (status === 'OK') {
        this.onPlaceChange({ lat: results[0]?.geometry?.location?.lat(), lng: results[0]?.geometry?.location?.lng() });
        if (results?.[0]?.geometry?.viewport) {
          map.fitBounds(results?.[0]?.geometry?.viewport);
        } else {
          map.setCenter(results?.[0]?.geometry?.location);
          map.setZoom(element?.zoom || ZOOM_VALUES[1].value);
        }
      }
    });
  }

  onChildMouseDown = (childKey, childProps, mouse) => {
    this.setState(prevState => ({
      ...prevState,
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    }));
  };

  onChildMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this.onPlaceChange({
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  renderMap() {
    const { element } = this.props;

    return (
      <>
        <div style={{ height: '400px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY, libraries: ['places', 'geometry'] }}
            defaultCenter={{ lat: element.latitude || 50.050098, lng: element.longitude || 19.947975 }}
            defaultZoom={element.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
            onChildMouseDown={this.onChildMouseDown}
            onChildMouseUp={this.onChildMouseUp}
            onChildMouseMove={this.onChildMouseDown}
            draggable={this.state.draggable}
          >
            {element.latitude && element.longitude && (
              <Marker
                key="map-marker-id"
                lat={this.state.lat || element?.latitude}
                lng={this.state.lng || element?.longitude}
              />
            )}
          </GoogleMapReact>
        </div>
      </>
    );
  }

  render() {
    const { element, elementErrors, t, isViewMode } = this.props;

    return (
      <>
        <Segment>
          <Form>
            <Form.Group>
              <Grid container stackable columns={2} divided>
                <TranslatableTextField
                  type="text"
                  disabled={isViewMode}
                  name="address"
                  label={t('map.address')}
                  tooltip={t('tooltip.map.displayedAddress')}
                  value={element?.address}
                  errorMessage={elementErrors?.address}
                  icon="home"
                  onChange={this.onAddressChange}
                />
              </Grid>
            </Form.Group>
            <Form.Group>
              <Grid container stackable columns={isViewMode ? 1 : 2} divided>
                <Grid.Column>
                  <Form.Field>
                    <label>
                      {t('map.zoom')} <Tooltip>{t('tooltip.map.zoom')}</Tooltip>
                    </label>

                    <Dropdown
                      disabled={isViewMode}
                      label={t('map.zoom')}
                      name="zoom"
                      value={element?.zoom}
                      options={ZOOM_VALUES}
                      placeholder="Select Element Type"
                      selection
                      button
                      onChange={this.onZoomChange}
                    />
                  </Form.Field>
                </Grid.Column>
                {isViewMode ? null : (
                  <Grid.Column>
                    <Form.Field>
                      <label>
                        {t('map.labelSearch')} <Tooltip>{t('tooltip.map.address')}</Tooltip>
                      </label>

                      <Input
                        ref={ref => {
                          this.searchInput = ref;
                        }}
                        type="text"
                        placeholder="Enter a location"
                        onChange={(e, { value }) => this.setState({ place: value })}
                      />
                      <Button className="mt-5" onClick={this.onSearchAddress}>
                        {t('map.search')}
                      </Button>
                    </Form.Field>
                  </Grid.Column>
                )}
              </Grid>
            </Form.Group>
            <Segment>{this.renderMap()}</Segment>
          </Form>
        </Segment>
      </>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(MapElement);
