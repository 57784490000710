const TableColumnModel = {
  name: { pl: '-', en: '-' },
  position: 1,
};

const TableRowModel = {
  position: 1,
  backgroundColor: '#FFFFFF',
  textColor: '#000000',
  cells: [{ position: 1, value: { pl: '-', en: '-' } }],
};

const AvailableHouseColumnsModel = [
  {
    name: { pl: 'Nr domu', en: 'House no' },
    position: 1,
  },
  {
    name: {
      pl: 'Pow. działki [m2]',
      en: 'Plot area [m2]',
    },
    position: 2,
  },
  {
    name: {
      pl: 'Pow. użytkowa domu [m2]',
      en: 'House usable area [m2]',
    },
    position: 3,
  },
  {
    name: {
      pl: 'Cena ofertowa',
      en: 'Offer price',
    },
    position: 4,
  },
  {
    name: {
      pl: 'Rzut kondygnacji',
      en: 'Floor plan',
    },
    position: 5,
  },
];

const AvailableHouseRowsModel = [
  {
    position: 1,
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    cells: [
      { position: 1, value: { pl: '-', en: '-' } },
      { position: 2, value: { pl: '-', en: '-' } },
      { position: 3, value: { pl: '-', en: '-' } },
      { position: 4, value: { pl: '-', en: '-' } },
      { position: 5, value: { pl: '-', en: '-' } },
    ],
  },
];

const TechnologiesColumnsModel = [
  {
    name: { pl: 'Back-end', en: 'Back-end' },
    position: 1,
  },
  {
    name: { pl: 'Front-end', en: 'Front-end' },
    position: 2,
  },
  {
    name: { en: 'Mobile Development', pl: 'Mobile Development' },
    position: 3,
  },
  {
    name: { pl: 'Baza danych', en: 'Database' },
    position: 4,
  },
];

const TechnologiesRowsModel = [
  {
    position: 1,
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    cells: [
      { position: 1, value: { pl: '-', en: '-' } },
      { position: 2, value: { pl: '-', en: '-' } },
      { position: 3, value: { pl: '-', en: '-' } },
      { position: 4, value: { pl: '-', en: '-' } },
    ],
  },
];

const TableElementModel = {
  columns: [TableColumnModel],
  rows: [TableRowModel],
};

export {
  TableElementModel,
  TableColumnModel,
  TableRowModel,
  AvailableHouseColumnsModel,
  AvailableHouseRowsModel,
  TechnologiesColumnsModel,
  TechnologiesRowsModel,
};
