import SuccessStoryElementTagModel from './SuccessStoryElementTagModel';
import SuccessStoryElementItemModel from './SuccessStoryElementItemModel';

const SuccessStoryElementModel = {
  description: {
    pl: '',
    en: '',
  },
  tags: [SuccessStoryElementTagModel],
  items: [SuccessStoryElementItemModel],
};

export default SuccessStoryElementModel;
