import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import MenuItemForm from '@screens/menu/components/MenuItemForm';

const Main = ({ actions, menuItem, status, errors, permittedPermissions }) => {
  const params = useParams();
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getMenuItem(params.id);
  });

  const handleRefresh = () => actions.getMenuItem(params.id);

  return (
    <MenuItemForm
      isViewMode={!isUserPermitted(PERMISSIONS.EDIT_MENU)}
      onRefresh={handleRefresh}
      onSave={actions.editMenuItem}
      menuItem={menuItem}
      isLoading={status === FETCH_STATUS.LOADING}
      errors={errors}
    />
  );
};

Main.propTypes = {
  actions: shape({ getMenuItem: func, editMenuItem: func }).isRequired,
  menuItem: shape({}),
  errors: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
  status: string,
};
Main.defaultProps = {
  menuItem: {},
  errors: [],
  permittedPermissions: [],
  status: undefined,
};

export default Main;
