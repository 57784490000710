const GET_MENU_LIST_INIT = '@menu/GET_MENU_LIST_INIT';
const GET_MENU_LIST_SUCCESS = '@menu/GET_MENU_LIST_SUCCESS';
const GET_MENU_LIST_FAILURE = '@menu/GET_MENU_LIST_FAILURE';

const GET_MENU_ITEM_INIT = '@menu/GET_MENU_ITEM_INIT';
const GET_MENU_ITEM_SUCCESS = '@menu/GET_MENU_ITEM_SUCCESS';
const GET_MENU_ITEM_FAILURE = '@menu/GET_MENU_ITEM_FAILURE';

const DELETE_MENU_ITEM_INIT = '@menu/DELETE_MENU_ITEM_INIT';
const DELETE_MENU_ITEM_SUCCESS = '@menu/DELETE_MENU_ITEM_SUCCESS';
const DELETE_MENU_ITEM_FAILURE = '@menu/DELETE_MENU_ITEM_FAILURE';

const EDIT_MENU_ITEM_INIT = '@menu/EDIT_MENU_ITEM_INIT';
const EDIT_MENU_ITEM_SUCCESS = '@menu/EDIT_MENU_ITEM_SUCCESS';
const EDIT_MENU_ITEM_FAILURE = '@menu/EDIT_MENU_ITEM_FAILURE';

const CREATE_MENU_ITEM_INIT = '@menu/CREATE_MENU_ITEM_INIT';
const CREATE_MENU_ITEM_SUCCESS = '@menu/CREATE_MENU_ITEM_SUCCESS';
const CREATE_MENU_ITEM_FAILURE = '@menu/CREATE_MENU_ITEM_FAILURE';

const CLEAR_STATUS = '@menu/CLEAR_STATUS';

export {
  GET_MENU_LIST_INIT,
  GET_MENU_LIST_SUCCESS,
  GET_MENU_LIST_FAILURE,
  GET_MENU_ITEM_INIT,
  GET_MENU_ITEM_SUCCESS,
  GET_MENU_ITEM_FAILURE,
  DELETE_MENU_ITEM_INIT,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_FAILURE,
  EDIT_MENU_ITEM_INIT,
  EDIT_MENU_ITEM_SUCCESS,
  EDIT_MENU_ITEM_FAILURE,
  CREATE_MENU_ITEM_INIT,
  CREATE_MENU_ITEM_SUCCESS,
  CREATE_MENU_ITEM_FAILURE,
  CLEAR_STATUS,
};
