import React from 'react';
import { Label, Form, Input, Grid, TextArea, Icon } from 'semantic-ui-react';
import clsx from 'clsx';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './styles.scss';

const languages = ['en', 'pl'];
const languageFlags = {
  en: <i className="us flag" />,
  pl: <i className="pl flag" />,
};

class TranslatableTextField extends React.PureComponent {
  render() {
    const {
      label,
      name,
      onChange,
      value,
      icon,
      iconPosition,
      errorMessage,
      labelClassName,
      multiline,
      t,
      required,
      tooltip,
      disabled,
      columnWidth,
    } = this.props;

    let InputComponent = Input;

    if (multiline) {
      InputComponent = TextArea;
    }

    return (
      <>
        {languages.map(language => {
          return (
            <Grid.Column key={`${name}-${language}`}>
              <Form.Field>
                {errorMessage && (
                  <Label color="red" className={clsx('mb-1', labelClassName)}>
                    {t(errorMessage)}
                  </Label>
                )}
                <Form.Field className="d-flex justify-content-between">
                  <label>
                    {languageFlags[language]}
                    {label}{' '}
                    {tooltip && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                      >
                        <Icon className={styles.icon} name="info circle" />
                      </OverlayTrigger>
                    )}
                  </label>
                </Form.Field>
                <InputComponent
                  icon={icon}
                  disabled={disabled}
                  iconPosition={iconPosition || 'left'}
                  name={name}
                  value={value?.[language]}
                  type="text"
                  onChange={(e, { name, value }) => onChange(e, { name, value, language })}
                  label={required ? { icon: 'asterisk' } : undefined}
                  labelPosition={required ? 'right corner' : undefined}
                />
              </Form.Field>
            </Grid.Column>
          );
        })}
      </>
    );
  }
}

TranslatableTextField.defaultProps = {
  label: '',
  name: '',
  onChange: () => {},
  value: '',
};

export default withTranslation(TRANSLATIONS.ERRORS)(TranslatableTextField);
