import React from 'react';
import { shape } from 'prop-types';
import { UltifideLogo } from '@shared/components';
import { Typography } from '@material-ui/core';

const Main = ({ config }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100">
      <UltifideLogo style={{ maxWidth: 150 }} center />
      <div className="m-4">
        <Typography>{config?.systemMaintenanceModeTitle}</Typography>
        <Typography>{config?.systemMaintenanceModeMessage}</Typography>
      </div>
    </div>
  );
};

Main.propTypes = {
  config: shape({}),
};

Main.defaultProps = {
  config: {},
};

export default Main;
