import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import errorImage from './not-found.png';
import useStyles from './styles';

const Main = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img className={styles.image} src={errorImage} alt="404 Not Found" />
      <Typography className={styles.title} variant="h5">404 - Page Not Found</Typography>
      <Typography variant="h5">Whoops, it looks like the page you request wasn&apos;t found.</Typography>
      <Button className={styles.button} component={Link} variant="outlined" color="primary" to={ROUTES.HOME}>
        Back to Dashboard
      </Button>
    </div>
  );
};

export default Main;
