import { DELETE_TAG_FAILURE, DELETE_TAG_INIT, DELETE_TAG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';
import getTags from '@store/actions/blog/getTags';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteTagInit = () => ({ type: DELETE_TAG_INIT });
const deleteTagSuccess = () => ({ type: DELETE_TAG_SUCCESS });
const deleteTagFailure = error => ({ type: DELETE_TAG_FAILURE, payload: { error } });

const deleteTag = id => async dispatch => {
  dispatch(deleteTagInit());

  try {
    await Client.deleteTag({ slug: id });
    dispatch(getTags());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.tags.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
    return dispatch(deleteTagSuccess(id));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('blog.tags.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'danger',
        },
      }),
    );
    return dispatch(deleteTagFailure(error));
  }
};

export default deleteTag;
