import axios from 'axios';
import i18next from 'i18next';
import { getUserAccessTokenFromStorage, getUserRefreshTokenFromStorage, setUserLoggedInStorage } from '@api-sdk/utils';
import { API_BASE_URL, CLIENT_SECRET } from '@api-sdk/consts';
import store from '@store/index';
import logoutUser from '@store/actions/user/logoutUser';
import handleErrorResponse from '@api-sdk/utils/handleErrorResponse';
import handleSuccessResponse from '@api-sdk/utils/handleSuccessResponse';
import { REFRESH_TOKEN_INIT, REFRESH_TOKEN_SUCCESS, SET_MAINTENANCE_MODE } from '@store/action-types/user';
import history from '@store/history';
import mapQueryParams from '@api-sdk/utils/mapQueryParams';

const setHeaders = ({ isPrivate, disableAuthorizationHeader = false }) => {
  const userAccessToken = getUserAccessTokenFromStorage();

  return {
    ...(isPrivate && userAccessToken && !disableAuthorizationHeader && { Authorization: `Bearer ${userAccessToken}` }),
    'Content-Type': 'application/json',
    // 'Content-Language': i18next.language,
  };
};

const axiosInstance = axios.create({});

const buildApiRequestRoute = ({ slug, slugs, url }) => {
  if (slugs && slugs?.length > 0) {
    return `${url}/${slugs?.join('/')}`;
  }

  return slug ? `${url}/${slug}` : url;
};

let isInLoop = false;
let isRefreshing = false;
let subscribersArray = [];

const createSDKRequest = ({ toRequest, fromResponse }) => requestParams => {
  const { url, data, slug, slugs, headers, method, isPrivate, disableAuthorizationHeader, params } = requestParams;

  const returnRawResponse = response => response;
  const normalizedRequest = toRequest ? toRequest(data) : data;
  const normalizedResponse = fromResponse || returnRawResponse;

  const requestOptions = {
    url: buildApiRequestRoute({ slug, slugs, url }),
    method: method || 'POST',
    headers: { ...setHeaders({ isPrivate, disableAuthorizationHeader }), ...headers },
    data: JSON.stringify(normalizedRequest),
    params,
    paramsSerializer: queryParams => {
      const isPermissionCheck = queryParams?.permissionCheck?.type === 'permissionCheck';
      const mappedParams = Object?.entries?.({ ...queryParams })
        ?.map?.(([key, value]) => mapQueryParams(key, value))
        .filter(item => item);

      if (mappedParams?.length > 0 && !isPermissionCheck) {
        history.push(`?${mappedParams?.length === 1 ? mappedParams : mappedParams?.join?.('&')}`);
      } else {
        window.history.replaceState(null, null, window.location.pathname);
      }

      return mappedParams?.length > 0 ? (mappedParams?.length === 1 ? mappedParams : mappedParams?.join?.('&')) : [];
    },
  };

  axiosInstance.interceptors.request.use(
    config => {
      const token = getUserAccessTokenFromStorage();

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    error => Promise.reject(error),
  );

  const subscribeTokenRefresh = failedActionCallback => {
    subscribersArray.push(failedActionCallback);
  };

  const onRefreshed = token => {
    subscribersArray.map(failedActionCallback => failedActionCallback(token));
  };

  axiosInstance.interceptors.response.use(undefined, error => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 503) {
      const maintenanceResponse = error?.response?.data?.data;
      store.dispatch({ type: SET_MAINTENANCE_MODE, payload: { config: maintenanceResponse } });
    }

    if (status === 401 && !isInLoop) {
      isInLoop = true;

      if (!isRefreshing) {
        isRefreshing = true;
        store.dispatch({ type: REFRESH_TOKEN_INIT });
        axios
          .post(`${API_BASE_URL}/token`, {
            refreshToken: getUserRefreshTokenFromStorage(),
            grantType: 'refresh_token',
            clientId: '_INITIAL_CLIENT',
            clientSecret: CLIENT_SECRET,
          })
          .then(async response => {
            isRefreshing = false;
            const transformedResponse = await handleSuccessResponse(response);

            onRefreshed(transformedResponse?.response?.accessToken);
            setUserLoggedInStorage({
              accessToken: transformedResponse?.response?.accessToken,
              refreshToken: transformedResponse?.response?.refreshToken,
              tokenExpiresIn: transformedResponse?.response?.expiresIn,
            });

            store.dispatch({ type: REFRESH_TOKEN_SUCCESS });

            subscribersArray = [];
          })
          .catch(() => {
            store.dispatch(logoutUser({ history }));
          });
      }

      return new Promise(resolve => {
        isInLoop = false;
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  });

  return axiosInstance
    .request({ ...requestOptions })
    .then(response => {
      const transformedResponse = handleSuccessResponse(response);
      return normalizedResponse(transformedResponse);
    })
    .catch(handleErrorResponse);
};

export default createSDKRequest;
