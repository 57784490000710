export const PageHeaderButtonModel = {
  label: {
    pl: '',
    en: '',
  },
  url: {
    en: '',
    pl: '',
  },
  position: 1,
  cssClasses: [],
  target: '_self',
  icon: '',
};
