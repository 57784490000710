import React, { useState } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Link, NavLink, useParams } from 'react-router-dom';
import useMount from '@shared/utils/useMountHook';
import ROUTES from '@shared/consts/routes';
import { UltifideLogo, Message } from '@shared/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { RESET_PASSWORD_SUCCESS } from '@store/action-types/user';
import { Button } from 'semantic-ui-react';
import useStyles from './styles';

const Main = ({ actions, passwordError }) => {
  const styles = useStyles();
  const theme = useTheme();
  const params = useParams();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitEnable, setSubmitEnable] = useState(true);

  const [password, setPassword] = useState({
    newPassword: '',
    repeatPassword: '',
  });

  const [t] = useTranslation(TRANSLATIONS.UNAUTHORIZED);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  useMount(() => {
    actions.validateResetPasswordTokenAction(params.resetToken);
  });

  const handleChange = e => {
    const key = e.target.name;
    const value = e.target.value;

    setPassword({
      ...password,
      [key]: value,
    });
  };

  const handleSubmit = async e => {
    await e.preventDefault();
    await setSubmitEnable(false);

    const resetPasswordStatus = await actions.submitResetPasswordAction(password, params?.resetToken);

    await setSubmitEnable(true);

    resetPasswordStatus?.type === RESET_PASSWORD_SUCCESS && setIsSuccess(true);
  };

  const renderResetPasswordForm = () => {
    return (
      <>
        <div className="text-center mb-2">
          <h2 className="font-weight-bold">{t('resetPassword.title')}</h2>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center my-2">
          {passwordError?.map?.(code => (
            <Message danger>{error(String(code))}</Message>
          ))}
        </div>
        <form
          onSubmit={handleSubmit}
          className={isXs ? `${styles.formContainer} ${styles.formContainerMobile}` : styles.formContainer}
        >
          <TextField
            value={password?.newPassword}
            name="newPassword"
            variant="outlined"
            label={t('resetPassword.newPassword')}
            type="password"
            fullWidth
            className={styles.input}
            onChange={handleChange}
          />
          <TextField
            value={password?.repeatPassword}
            name="repeatPassword"
            variant="outlined"
            label={t('resetPassword.repeatPassword')}
            type="password"
            fullWidth
            className={styles.input}
            onChange={handleChange}
          />
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
            <div className="my-3 mr-2">
              <NavLink to={ROUTES.LOGIN} className="text-muted text-hover-primary">
                {t('resetPassword.signIn')}
              </NavLink>
            </div>
            <Button color="facebook" type="submit" disabled={!submitEnable}>
              {t('resetPassword.submit')}
            </Button>
          </div>
        </form>
      </>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <div className="d-flex flex-column align-items-center mt-5">
        <p>{t('resetPassword.success')}</p>
        <div className="my-3 mr-2">
          <NavLink to={ROUTES.LOGIN} className="text-muted text-hover-primary">
            {t('resetPassword.backToLogin')}
          </NavLink>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.logoContainer}>
        <Typography color="textSecondary" variant="body2" to={ROUTES.HOME} component={Link}>
          <UltifideLogo style={{ maxWidth: 150 }} center />
        </Typography>
      </div>
      {isSuccess ? renderSuccessMessage() : renderResetPasswordForm()}
    </div>
  );
};

Main.propTypes = {
  actions: shape({
    submitResetPasswordAction: func,
    validateResetPasswordTokenAction: func,
  }).isRequired,
  errors: shape({}),
  passwordError: arrayOf(shape({})),
};

Main.defaultProps = {
  errors: {},
  passwordError: [],
};

export default Main;
