import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Grid, Icon, Label, Segment } from 'semantic-ui-react';
import { TranslatableTextField } from '@screens/offers/NewOfferForm/components';
import TRANSLATIONS from '@translations/translationNamespaces';

const SuccessStoryElementTag = ({
  onChange,
  elementPosition,
  sectionNumber,
  isViewMode,
  element,
  elementErrors,
  isArrowDownDisabled,
  isArrowUpDisabled,
}) => {
  const { t } = useTranslation(TRANSLATIONS.OFFERS);

  const onTranslatableContentChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (elementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      tags: el.tags.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === element.position) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: {
                                ...innerElement[name],
                                [language]: value,
                              },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onArrowUp = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (elementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      tags: el.tags.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.tags.find(item => item.position === element.position - 1);
                        const matchingItem = el.tags.find(item => item.position === element.position);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onArrowDown = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (elementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      tags: el.tags.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.tags.find(item => item.position === element.position + 1);
                        const matchingItem = el.tags.find(item => item.position === element.position);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onRemoveTag = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove tag? Tag changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (elementPosition === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        tags: el.tags.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === element.position) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > element.position) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  };

  return (
    <Segment>
      <Form.Group>
        <Grid container stackable columns={1} divided>
          <Grid.Column>
            <div className="d-flex align-items-center justify-content-between h-100">
              <div className="d-flex align-items-center h-100">
                <Label>
                  {t('successStory.tag')} #{element.position}
                </Label>
                <Button icon className="mr-2 ml-2" disabled={isArrowUpDisabled} onClick={onArrowUp}>
                  <Icon name="arrow up" />
                </Button>
                <Button icon className="mr-2 ml-2" disabled={isArrowDownDisabled} onClick={onArrowDown}>
                  <Icon name="arrow down" />
                </Button>
              </div>

              <div className="d-flex align-items-center h-100">
                <Button className="mr-2 ml-2" icon color="red" onClick={onRemoveTag}>
                  <Icon name="trash" />
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableTextField
            type="text"
            name="name"
            label={t('successStory.name')}
            errorMessage={elementErrors?.name}
            value={element.name}
            icon="font"
            onChange={onTranslatableContentChange}
            disabled={isViewMode}
          />
        </Grid>
      </Form.Group>
    </Segment>
  );
};

export default SuccessStoryElementTag;
