const TRANSLATIONS = {
  COMMON: 'COMMON',
  TOPBAR: 'TOPBAR',
  FAQ: 'FAQ',
  OFFERS: 'OFFERS',
  AGREEMENTS: 'AGREEMENTS',
  ERRORS: 'ERRORS',
  PAGES: 'PAGES',
  SERIES: 'SERIES',
  TRANSACTIONS: 'TRANSACTIONS',
  ORDERS: 'ORDERS',
  INVESTMENTS: 'INVESTMENTS',
  SLIDER: 'SLIDER',
  ROLES: 'ROLES',
  PERMISSIONS: 'PERMISSIONS',
  USERS: 'USERS',
  EMAIL_TEMPLATES: 'EMAIL_TEMPLATES',
  UNAUTHORIZED: 'UNAUTHORIZED',
  SETTINGS: 'SETTINGS',
  STATIC_CONTENT: 'STATIC_CONTENT',
  FEATURED_OFFERS: 'FEATURED_OFFERS',
  BLOG: 'BLOG',
  MENU: 'MENU',
};

export default TRANSLATIONS;
