import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { bool, func, number, shape } from 'prop-types';
import { Button, Dropdown, Form, Grid, Icon } from 'semantic-ui-react';
import { Select } from '@shared/components';
import { GridElementItemModel } from '@screens/offers/NewOfferForm/OfferForm/models/elementModels';
import TRANSLATIONS from '@translations/translationNamespaces';
import { TranslatableRichTextField } from '@shared/components/translatable';
import { GridOptions, LayoutOptions } from './consts';
import { GridElementItem } from './components';

const GridElement = ({ element, elementErrors, onChange, sectionNumber, isViewMode }) => {
  const { t } = useTranslation(TRANSLATIONS.OFFERS);

  const onTranslatableContentChange = (e, { name, value, language }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: {
                        ...el[name],
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onAddGirdElementItem = () =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      items: el.items.concat([
                        {
                          ...GridElementItemModel({
                            desktop: element?.defaultGrid?.desktop,
                            tablet: element?.defaultGrid?.tablet,
                            mobile: element?.defaultGrid?.mobile,
                          }),
                          position: el?.items?.[el?.items?.length - 1]?.position + 1 || 1,
                          backendIndex: el?.items?.length || 0,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onChangeDefaultGrid = ({ name, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      defaultGrid: {
                        ...el.defaultGrid,
                        [name]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onSelectChange = (e, { name, value }) =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: value,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  return (
    <>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <TranslatableRichTextField
            type="text"
            name="subtitle"
            label={t('grid.subtitle')}
            errorMessage={elementErrors?.subtitle}
            value={element?.subtitle}
            icon="font"
            onChange={onTranslatableContentChange}
            disabled={isViewMode}
          />
        </Grid>
      </Form.Group>
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <Select
            disabled={isViewMode}
            type="text"
            label={t('grid.layout')}
            name="layout"
            value={element.layout}
            onChange={(e, { name, value }) => onSelectChange(e, { name, value })}
            options={LayoutOptions}
            placeholder="Grid Tiles"
          />
          <Grid.Column>
            <Form.Field className="d-flex justify-content-between">
              <label>{t('grid.defaultGrid')}</label>
            </Form.Field>
            <div className="d-flex align-items-center justify-content-center">
              <Dropdown
                icon="desktop"
                className="icon"
                disabled={isViewMode}
                labeled
                fluid
                button
                selection
                placeholder={t('desktop')}
                label={t('desktop')}
                value={element?.defaultGrid?.desktop}
                name="desktop"
                options={GridOptions}
                onChange={(e, { value, name }) => onChangeDefaultGrid({ value, name })}
              />
              <Dropdown
                disabled={isViewMode}
                icon="tablet alternate"
                className="icon"
                labeled
                fluid
                button
                selection
                placeholder={t('tablet')}
                label={t('tablet')}
                value={element?.defaultGrid?.tablet}
                name="tablet"
                options={GridOptions}
                onChange={(e, { value, name }) => onChangeDefaultGrid({ value, name })}
              />
              <Dropdown
                disabled={isViewMode}
                icon="mobile"
                className="icon"
                labeled
                fluid
                button
                selection
                placeholder={t('mobile')}
                label={t('mobile')}
                name="mobile"
                value={element?.defaultGrid?.mobile}
                options={GridOptions}
                onChange={(e, { value, name }) => onChangeDefaultGrid({ value, name })}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Form.Group>
      {isEmpty(element.items)
        ? null
        : element.items.map((item, index) => (
            <GridElementItem
              key={item.position}
              element={item}
              onChange={onChange}
              sectionNumber={sectionNumber}
              isViewMode={isViewMode}
              elementIndex={index}
              gridElementPosition={element.position}
              isArrowDownDisabled={item.position === element.items[element.items.length - 1].position}
              isArrowUpDisabled={item.position === element.items[0].position}
              onAddGirdElementItem={onAddGirdElementItem}
            />
          ))}
      <div className="d-flex justify-content-center align-items-center p-4">
        <Button onClick={onAddGirdElementItem}>
          <Icon name="plus" />
          {t('grid.add')}
        </Button>
      </div>
    </>
  );
};

GridElement.propTypes = {
  sectionNumber: number.isRequired,
  element: shape({}),
  elementErrors: shape({}),
  onChange: func,
  isViewMode: bool,
};

GridElement.defaultProps = {
  elementErrors: {},
  element: {},
  onChange: () => {},
  isViewMode: false,
};

export default GridElement;
