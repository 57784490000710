import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { arrayOf, func, shape, string, number, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, NotFoundMessage } from '@shared/components';
import { Button } from 'semantic-ui-react';
import ROUTES from '@shared/consts/routes';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import SCREEN_NAMES from '@shared/consts/screenNames';
import { groupBy, isEmpty, map, orderBy } from 'lodash';
import clsx from 'clsx';
import { Spinner } from 'react-bootstrap';
import { MenuItem } from './components';
import styles from './styles.module.scss';

const Main = ({
  actions,
  menuList,
  listStatus,
  status,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.MENU);
  const [isLoading, setIsLoading] = useState(true);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    setIsLoading(true);
    actions.getMenuList();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.MENU]) {
      if (
        !isUserPermitted(PERMISSIONS.EDIT_MENU) ||
        !isUserPermitted(PERMISSIONS.CREATE_MENU) ||
        !isUserPermitted(PERMISSIONS.DELETE_MENU) ||
        !isUserPermitted(PERMISSIONS.GET_MENU_LIST) ||
        !isUserPermitted(PERMISSIONS.GET_MENU_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.MENU);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.EDIT_MENU) && PERMISSIONS.EDIT_MENU,
          !isUserPermitted(PERMISSIONS.CREATE_MENU) && PERMISSIONS.CREATE_MENU,
          !isUserPermitted(PERMISSIONS.DELETE_MENU) && PERMISSIONS.DELETE_MENU,
          !isUserPermitted(PERMISSIONS.GET_MENU_LIST) && PERMISSIONS.GET_MENU_LIST,
          !isUserPermitted(PERMISSIONS.GET_MENU_BY_ID) && PERMISSIONS.GET_MENU_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  useEffect(() => {
    listStatus !== FETCH_STATUS.IDLE && listStatus !== undefined && setIsLoading(false);
  }, [listStatus, actions]);

  useEffect(() => {
    return () => actions.clearStatus();
  }, [actions]);

  const refreshPage = () => actions.getMenuList();

  const renderTree = (items = [], level = 0) => (
    <div className={styles.treeItem} style={{ marginLeft: level * 16 }}>
      {orderBy(items, ['position'], ['asc']).map(item => (
        <>
          <MenuItem {...item} onDelete={actions.deleteMenuItem} />
          {isEmpty(item.children) ? null : renderTree(item.children, level + 1)}
        </>
      ))}
    </div>
  );

  return (
    <Card width="md">
      <CardHeader>
        <CardTitle title={t('title')} />
        <CardToolbar>
          <Button disabled={listStatus === FETCH_STATUS.LOADING} onClick={refreshPage} color="black">
            {t('actionButtons.reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.CREATE_MENU) ? (
            <Button as={Link} primary to={ROUTES.ADD_MENU}>
              {t('actionButtons.create')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>
        <div className={clsx(styles.listWrapper, isLoading && styles.loadingWrapper)}>
          {isLoading || listStatus === FETCH_STATUS.LOADING ? (
            <div className={styles.loader}>
              <Spinner animation="grow" variant="info" />
            </div>
          ) : null}

          {isEmpty(menuList) && listStatus === FETCH_STATUS.SUCCESS ? (
            <NotFoundMessage
              title={t('notFoundMenuItems')}
              subtitle={t('clickBelow')}
              buttonText={t('actionButtons.create')}
              to={ROUTES.ADD_MENU}
            />
          ) : (
            map(groupBy(menuList, 'codeName'), (items, codeName) => ({
              codeName,
              children: items,
            })).map(item => (
              <div>
                <p className={styles.codeName}>{t(`menu.${item.codeName}`)}</p>
                {renderTree(item.children)}
              </div>
            ))
          )}
        </div>
      </CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getMenuList: func,
    deleteMenuItem: func,
    clearStatus: func,
    setIsPermissionCheckedAction: func,
    checkPermissionAction: func,
  }).isRequired,
  menuList: arrayOf(
    shape({
      id: number,
      name: string,
      position: number,
      active: bool,
      isFeatured: bool,
    }),
  ),
  listStatus: string,
  status: string,
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};
Main.defaultProps = {
  menuList: [],
  listStatus: undefined,
  status: undefined,
  queryParams: {},
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
