import React from 'react';
import { Form, Grid, Divider } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { TranslatableTextField, SwitchField } from '../../components';

class OfferMainContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
  }

  onChangeContent(e, { name, value, language }) {
    const { onChange } = this.props;

    return language
      ? onChange(prevState => ({
          offerFields: {
            ...prevState.offerFields,
            [name]: {
              ...prevState.offerFields[name],
              [language]: value,
            },
          },
        }))
      : onChange(prevState => ({ offerFields: { ...prevState.offerFields, [name]: value } }));
  }

  onChangeSwitch(e, { name, checked }) {
    const { onChange } = this.props;

    return onChange(prevState => ({ offerFields: { ...prevState.offerFields, [name]: checked } }));
  }

  onChangeDate(e, { name, value }) {
    const { onChange } = this.props;

    return onChange(prevState => ({
      offerFields: {
        ...prevState.offerFields,
        [name]: value,
      },
    }));
  }

  onChangeNumber(e, { name, value }) {
    const { onChange } = this.props;

    return value < 0
      ? onChange(prevState => ({
          offerFields: {
            ...prevState.offerFields,
            [name]: 0,
          },
        }))
      : onChange(prevState => ({ offerFields: { ...prevState.offerFields, [name]: value } }));
  }

  render() {
    const { offerFields, t, isViewMode } = this.props;

    return (
      <Form loading={this.props.isLoading}>
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <Grid.Column>
              <SwitchField
                disabled={isViewMode}
                name="isVisible"
                label={t('isVisible')}
                value={offerFields?.isVisible}
                onChange={this.onChangeSwitch}
                tooltip={t('tooltip.isVisible')}
              />
            </Grid.Column>
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              name="title"
              disabled={isViewMode}
              label={t('title')}
              value={offerFields?.title}
              errorMessage={this?.props?.offerErrors?.title}
              icon="font"
              onChange={this.onChangeContent}
              required
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              disabled={isViewMode}
              name="slug"
              label={t('slug')}
              value={offerFields?.slug}
              icon="linkify"
              onChange={this.onChangeContent}
              errorMessage={this?.props?.offerErrors?.slug}
              required
              tooltip={t('tooltip.slug')}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              disabled={isViewMode}
              name="seoTitle"
              label={t('seoTitle')}
              value={offerFields?.seoTitle}
              icon="sort amount up"
              onChange={this.onChangeContent}
              errorMessage={this?.props?.offerErrors?.seoTitle}
              required
              tooltip={t('tooltip.seoTitle')}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group className="mb-0">
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              disabled={isViewMode}
              name="seoDescription"
              label={t('seoDescription')}
              value={offerFields?.seoDescription}
              icon="sort amount up"
              onChange={this.onChangeContent}
              errorMessage={this?.props?.offerErrors?.seoDescription}
              required
              tooltip={t('tooltip.seoDescription')}
            />
          </Grid>
        </Form.Group>
      </Form>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferMainContent);
