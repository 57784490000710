import React, { useEffect, useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { Button, Divider, Form, Grid, Segment } from 'semantic-ui-react';
import { InputField, TranslatableTextField } from '@screens/offers/NewOfferForm/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { Select } from '@shared/components';
import { isEmpty } from 'lodash';
import { Container } from '@material-ui/core';
import { getAllOffers, getOffersSlugs, getOffersAsOptions } from '@store/reducers/offers/selectors';
import { getMenuItemsAsOptions } from '@store/reducers/menu/selectors';
import { bindActionCreators, compose } from 'redux';
import { getOffersAction } from '@store/actions/offers';
import { getMenuList } from '@store/actions/menu';
import { connect } from 'react-redux';
import useStyles from './styles';
import { CodeNameOptions } from './consts';

const MenuItemForm = ({
  isCreateMode,
  isLoading,
  onSave,
  onRefresh,
  errors,
  menuItem,
  isViewMode,
  actions,
  pages,
  slugs,
  pageOptions,
  parentOptions,
}) => {
  const [t] = useTranslation(TRANSLATIONS.MENU);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const [pageUrlHelper, setPageUrlHelper] = useState('');
  const [menuItemData, setMenuItemData] = useState(menuItem);
  const styles = useStyles();

  useEffect(() => {
    if (isEmpty(pages)) {
      actions.getOffersAction();
    }

    if (isEmpty(parentOptions)) {
      actions.getMenuList();
    }
  }, []);

  useEffect(() => {
    setMenuItemData(menuItem);
  }, [menuItem]);

  const onChangeContent = (e, { name, value }) => {
    if (name === 'parent') {
      return setMenuItemData(prevState => ({
        ...prevState,
        [name]: value,
        codeName: '',
      }));
    }

    if (name === 'codeName') {
      return setMenuItemData(prevState => ({
        ...prevState,
        [name]: value,
        parent: '',
      }));
    }

    return setMenuItemData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onPageOptionsChange = (e, { value }) => {
    setPageUrlHelper(value);
    setMenuItemData(prevState => ({
      ...prevState,
      url: slugs[value],
    }));
  };

  const onChangeTranslatableContent = (e, { name, value, language }) => {
    setMenuItemData(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [language]: value,
      },
    }));
  };

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{isCreateMode ? t('addMenuItem') : t('editMenuItem')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.MENU}>
          {t('actionButtons.goBack')}
        </Button>
        {!isCreateMode && (
          <Button
            disabled={isLoading}
            type="submit"
            secondary
            onClick={() => onRefresh()}
            style={{ marginRight: '5px' }}
          >
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isCreateMode && !isViewMode && (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading || (!menuItemData.parent && !menuItemData.codeName)}
            variant="outlined"
            primary
            onClick={() => onSave({ menuItem: menuItemData })}
          >
            {t('actionButtons.save')}
          </Button>
        )}

        {!isCreateMode && !isViewMode && (
          <Button
            disabled={isLoading || (!menuItemData.parent && !menuItemData.codeName)}
            variant="outlined"
            color="facebook"
            onClick={() => onSave({ menuItem: menuItemData, saveAndBack: true })}
          >
            {t('actionButtons.saveAndBack')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading || (!menuItemData.parent && !menuItemData.codeName)}
            variant="outlined"
            primary
            onClick={() => onSave({ menuItem: menuItemData })}
          >
            {t('actionButtons.create')}
          </Button>
        )}

        {isCreateMode && (
          <Button
            disabled={isLoading || (!menuItemData.parent && !menuItemData.codeName)}
            variant="outlined"
            color="facebook"
            onClick={() => onSave({ menuItem: menuItemData, saveAndBack: true })}
          >
            {t('actionButtons.createAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="md">
      {renderMenuButtons()}
      <Segment className={styles.segment}>
        <Form loading={isLoading}>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <InputField
                disabled={isViewMode}
                icon="numbered list"
                label={t('form.position')}
                name="position"
                onChange={onChangeContent}
                value={menuItemData?.position}
                errorMessage={error(errors?.position)}
                labelClassName={styles.label}
              />
              <Select
                disabled={isViewMode}
                type="text"
                label={t('form.parent')}
                name="parent"
                value={menuItemData?.parent}
                onChange={onChangeContent}
                options={parentOptions}
                placeholder="Select parent"
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <Select
                disabled={isViewMode}
                type="text"
                label={t('form.codeName')}
                name="codeName"
                value={menuItemData?.codeName}
                onChange={onChangeContent}
                options={CodeNameOptions}
                placeholder="Select code name"
              />
              <Select
                disabled={isViewMode}
                type="text"
                label={t('form.urlHelper')}
                value={pageUrlHelper}
                onChange={onPageOptionsChange}
                options={pageOptions}
                placeholder="Select page"
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                label={t('form.url')}
                name="url"
                icon="linkify"
                errorMessage={error(errors?.url)}
                value={menuItemData?.url}
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                disabled={isViewMode}
                name="label"
                label={t('form.label')}
                value={menuItemData?.label}
                errorMessage={error(errors?.label)}
                icon="font"
                onChange={onChangeTranslatableContent}
                labelClassName={styles.label}
              />
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

MenuItemForm.propTypes = {
  isCreateMode: bool,
  isLoading: bool,
  onRefresh: func,
  onSave: func,
  errors: shape({}),
  menuItem: shape({}),
  isViewMode: bool,
};

MenuItemForm.defaultProps = {
  isCreateMode: false,
  isLoading: false,
  onSave: () => {},
  onRefresh: () => {},
  errors: {},
  menuItem: {},
  isViewMode: false,
};

const mapStateToProps = state => ({
  pages: getAllOffers(state),
  slugs: getOffersSlugs(state),
  pageOptions: getOffersAsOptions(state),
  parentOptions: getMenuItemsAsOptions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getOffersAction,
      getMenuList,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MenuItemForm);
