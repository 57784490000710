import { GET_SINGLE_TAG_INIT, GET_SINGLE_TAG_FAILURE, GET_SINGLE_TAG_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';

const getTagByIdInit = () => ({ type: GET_SINGLE_TAG_INIT });
const getTagByIdSuccess = tag => ({
  type: GET_SINGLE_TAG_SUCCESS,
  payload: { tag },
});
const getTagByIdFailure = error => ({ type: GET_SINGLE_TAG_FAILURE, payload: { error } });

const getTagById = tagId => async dispatch => {
  dispatch(getTagByIdInit());

  try {
    const tagResponse = await Client.getTagById({ slug: tagId });
    dispatch(getTagByIdSuccess(tagResponse.response));
  } catch (error) {
    dispatch(getTagByIdFailure(error));
  }
};

export default getTagById;
