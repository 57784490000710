import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    margin: 'auto',
    padding: 40,
    borderRadius: 10,
  },
  image: {
    maxWidth: 300,
  },
  title: {
    margin: '32px 0 16px',
  },
  button: {
    margin: '20px 0',
  },
}));

export default useStyles;
