import { GET_TAGS_INIT, GET_TAGS_FAILURE, GET_TAGS_SUCCESS } from '@store/action-types/blog';
import Client from '@api-sdk/client';

const getTagsInit = () => ({ type: GET_TAGS_INIT });
const getTagsSuccess = tags => ({
  type: GET_TAGS_SUCCESS,
  payload: tags,
});
const getTagsFailure = error => ({ type: GET_TAGS_FAILURE, payload: { error } });

const getTags = () => async dispatch => {
  dispatch(getTagsInit());

  try {
    const tags = await Client.getTags();
    dispatch(getTagsSuccess(tags.response));
  } catch (error) {
    dispatch(getTagsFailure(error));
  }
};

export default getTags;
