import React, { useRef } from 'react';
import { Button, Divider, Form, Grid, Icon, Image, Input, Label } from 'semantic-ui-react';
import VALID_IMAGE_MIME_TYPES from '@screens/offers/NewOfferForm/OfferForm/models/ImageTypes';

const PageHeaderImageElement = ({
  sectionNumber,
  elementPosition,
  isViewMode,
  element,
  onChange,
  isArrowUpDisabled,
  isArrowDownDisabled,
}) => {
  const imageRef = useRef();

  const onUploadImage = e => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        return onChange(prevState => ({
          offerSections: prevState.offerSections.reduce((acc, section) => {
            if (section.position === sectionNumber) {
              return acc.concat([
                {
                  ...section,
                  elements: section.elements.reduce((elementsAcc, el) => {
                    if (elementPosition === el.position) {
                      return elementsAcc.concat([
                        {
                          ...el,
                          images: el?.images.reduce((imagesAcc, img) => {
                            if (element.position === img.position) {
                              return imagesAcc.concat([
                                {
                                  ...img,
                                  file: {
                                    fileName: file.name,
                                    delete: false,
                                    base64: reader.result,
                                  },
                                },
                              ]);
                            }

                            return imagesAcc.concat([img]);
                          }, []),
                        },
                      ]);
                    }
                    return elementsAcc.concat([el]);
                  }, []),
                },
              ]);
            }
            return acc.concat([section]);
          }, []),
        }));
      };

      return reader.readAsDataURL(file);
    }

    return null;
  };

  const onRemoveImage = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove image #${element.position}? Image will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (elementPosition === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        images: el?.images.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === element.position) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > element.position) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  };

  const onArrowUp = innerElementPosition =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (elementPosition === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      images: el.images.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.images.find(item => item.position === innerElementPosition - 1);
                        const matchingItem = el.images.find(item => item.position === innerElementPosition);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  const onArrowDown = innerElementPosition =>
    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      images: el.images.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.images.find(item => item.position === innerElementPosition + 1);
                        const matchingItem = el.images.find(item => item.position === innerElementPosition);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));

  return (
    <>
      <Divider />
      <Form.Group className="mt-4">
        <Grid container stackable columns={isViewMode ? 1 : 2} divided>
          <Grid.Row>
            <Grid.Column>
              <div className="d-flex align-items-center">
                <Label>Image #{element?.position}</Label>
                <Button className="mr-2 ml-2" icon color="red" onClick={onRemoveImage}>
                  <Icon name="trash" />
                </Button>
                <Button
                  icon
                  className="mr-2 ml-2"
                  disabled={isArrowUpDisabled}
                  onClick={() => onArrowUp(element.position)}
                >
                  <Icon name="arrow up" />
                </Button>
                <Button
                  icon
                  className="mr-2 ml-2"
                  disabled={isArrowDownDisabled}
                  onClick={() => onArrowDown(element.position)}
                >
                  <Icon name="arrow down" />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Column>
            <Image src={element?.file?.base64 || element.imageUrl} size="medium" rounded bordered />
          </Grid.Column>

          {isViewMode ? null : (
            <Grid.Column>
              <Input ref={imageRef} onChange={onUploadImage} type="file" accept={VALID_IMAGE_MIME_TYPES.join(', ')} />
            </Grid.Column>
          )}
        </Grid>
      </Form.Group>
    </>
  );
};

export default PageHeaderImageElement;
