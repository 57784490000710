import {
  GET_BLOG_INIT,
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_INIT,
  GET_SINGLE_BLOG_FAILURE,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_INIT,
  DELETE_BLOG_FAILURE,
  EDIT_BLOG_INIT,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAILURE,
  CREATE_BLOG_INIT,
  CREATE_BLOG_FAILURE,
  CREATE_BLOG_SUCCESS,
  CLEAR_STATUS,
  GET_TAGS_INIT,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_SINGLE_TAG_INIT,
  GET_SINGLE_TAG_SUCCESS,
  GET_SINGLE_TAG_FAILURE,
  DELETE_TAG_INIT,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  EDIT_TAG_INIT,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
  CREATE_TAG_INIT,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
} from '@store/action-types/blog';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  status: FETCH_STATUS.IDLE,
  error: undefined,
  post: {},
  tags: {
    list: {
      data: [],
      status: FETCH_STATUS.IDLE,
      error: undefined,
    },
    tag: {
      data: {},
      status: FETCH_STATUS.IDLE,
      error: undefined,
    },
  },
  list: {
    queryParams: {},
    data: [],
    status: FETCH_STATUS.IDLE,
    error: undefined,
  },
};

const blogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BLOG_INIT: {
      return {
        ...state,
        list: {
          ...state.list,
          status: FETCH_STATUS.LOADING,
          queryParams: {},
        },
      };
    }
    case GET_BLOG_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          status: FETCH_STATUS.SUCCESS,
          data: payload?.items,
          queryParams: {
            configuredFilters: payload?.configuredFilters,
            configuredPagination: payload?.configuredPagination,
            configuredOrder: payload?.configuredOrder,
            appliedOrder: payload?.appliedOrder,
            activeFilters: payload?.activeFilters,
            pagination: payload?.pagination,
            order: payload?.order,
          },
          error: undefined,
        },
      };
    }
    case GET_BLOG_FAILURE: {
      return {
        ...state,
        list: {
          ...state.list,
          status: FETCH_STATUS.FAILURE,
          data: [],
          error: payload.error,
        },
      };
    }
    case GET_SINGLE_BLOG_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
      };
    }
    case GET_SINGLE_BLOG_SUCCESS: {
      return {
        ...state,
        post: payload.singlePost,
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_BLOG_FAILURE: {
      return {
        ...state,
        post: {},
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case DELETE_BLOG_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: {},
      };
    }
    case DELETE_BLOG_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case DELETE_BLOG_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case EDIT_BLOG_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: {},
      };
    }
    case EDIT_BLOG_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case EDIT_BLOG_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case CREATE_BLOG_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: {},
      };
    }
    case CREATE_BLOG_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case CREATE_BLOG_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }

    case GET_TAGS_INIT: {
      return {
        ...state,
        tags: {
          ...state.tags,
          list: {
            ...state.tags.list,
            status: FETCH_STATUS.LOADING,
          },
        },
      };
    }
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          list: {
            ...state.tags.list,
            status: FETCH_STATUS.SUCCESS,
            data: payload,
            error: undefined,
          },
        },
      };
    }
    case GET_TAGS_FAILURE: {
      return {
        ...state,
        tags: {
          ...state.tags,
          list: {
            ...state.tags.list,
            status: FETCH_STATUS.FAILURE,
            data: [],
            error: payload.error,
          },
        },
      };
    }
    case GET_SINGLE_TAG_INIT: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.LOADING,
            error: undefined,
          },
        },
      };
    }
    case GET_SINGLE_TAG_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.SUCCESS,
            data: payload?.tag,
            error: undefined,
          },
        },
      };
    }
    case GET_SINGLE_TAG_FAILURE: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.FAILURE,
            data: {},
            error: payload.error,
          },
        },
      };
    }
    case DELETE_TAG_INIT: {
      return {
        ...state,
        tags: {
          ...state.tags,
          status: FETCH_STATUS.LOADING,
        },
      };
    }
    case DELETE_TAG_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          status: FETCH_STATUS.SUCCESS,
        },
      };
    }
    case DELETE_TAG_FAILURE: {
      return {
        ...state,
        tags: {
          ...state.tags,
          status: FETCH_STATUS.FAILURE,
        },
      };
    }
    case EDIT_TAG_INIT: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.LOADING,
            error: undefined,
          },
        },
      };
    }
    case EDIT_TAG_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.SUCCESS,
          },
        },
      };
    }
    case EDIT_TAG_FAILURE: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.FAILURE,
            error: payload.error,
          },
        },
      };
    }
    case CREATE_TAG_INIT: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.LOADING,
            error: undefined,
          },
        },
      };
    }
    case CREATE_TAG_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.SUCCESS,
          },
        },
      };
    }
    case CREATE_TAG_FAILURE: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tag: {
            ...state.tags.tag,
            status: FETCH_STATUS.FAILURE,
            error: payload?.error,
          },
        },
      };
    }
    case CLEAR_STATUS: {
      return {
        ...state,
        status: undefined,
      };
    }
    default:
      return state;
  }
};

export default blogReducer;
