const TimelineItemModel = {
  position: 1,
  backendIndex: 0,
  current: false,
  label: { pl: '', en: '' },
  date: { pl: '-', en: '-' },
  content: { pl: '', en: '' },
};

export default TimelineItemModel;
