import React, { useEffect, useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardToolbar } from '@shared/components';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Skeleton } from '@shared/layout-components';
import { STATIC_CONTENT_TYPE } from '@screens/static-content/consts';
import { Text, Image, Breadcrumbs, RichText } from '@screens/static-content/components';
import { Button } from 'semantic-ui-react';
import useMount from '@shared/utils/useMountHook';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import PERMISSIONS from '@shared/consts/permissions';

const Main = ({ actions, element, status, breadcrumbs, permittedPermissions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [elementData, setElementData] = useState({});
  const params = useParams();
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getStaticContentElementAction(params.elementId);
  });

  const handleSave = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await actions.editStaticContentAction({
      staticContentData: elementData,
      elementId: params.elementId,
      saveAndBack,
      previousElementId: breadcrumbs?.[breadcrumbs?.length - 2]?.id,
    });
    await setIsLoading(false);
  };

  useEffect(() => {
    switch (element.type) {
      case STATIC_CONTENT_TYPE.RICH_TEXT:
      case STATIC_CONTENT_TYPE.TEXT: {
        return setElementData({ content: { pl: element?.content?.pl || '' } });
      }
      case STATIC_CONTENT_TYPE.IMAGE: {
        return setElementData({
          alt: {
            pl: element?.alt?.pl || '',
          },
          image: {
            base64: element?.imageUrl || '',
            fileName: '',
            delete: false,
          },
        });
      }
      default: {
        return () => {};
      }
    }
  }, [element]);

  const renderForm = () => {
    switch (element.type) {
      case STATIC_CONTENT_TYPE.IMAGE: {
        return (
          <Image
            label={element?.name}
            elementData={elementData}
            setElementData={setElementData}
            isLoading={isLoading}
            isViewMode={!isUserPermitted(PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT)}
          />
        );
      }
      case STATIC_CONTENT_TYPE.TEXT: {
        return (
          <Text
            label={element?.name}
            elementData={elementData}
            setElementData={setElementData}
            isLoading={isLoading}
            isViewMode={!isUserPermitted(PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT)}
          />
        );
      }
      case STATIC_CONTENT_TYPE.RICH_TEXT: {
        return (
          <RichText
            label={element?.name}
            elementData={elementData}
            setElementData={setElementData}
            isLoading={isLoading}
            isViewMode={!isUserPermitted(PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT)}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Card width="md">
      <CardHeader>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <CardToolbar>
          <Button
            as={Link}
            to={
              breadcrumbs?.[breadcrumbs?.length - 2]?.id
                ? `/static-content/${breadcrumbs?.[breadcrumbs?.length - 2]?.id}`
                : '/static-content'
            }
          >
            {t('actionButtons.goBack')}
          </Button>
          <Button
            disabled={isLoading || status === FETCH_STATUS.IDLE}
            secondary
            onClick={() => actions.getStaticContentElementAction(params.elementId)}
          >
            {t('actionButtons.refresh')}
          </Button>
          {isUserPermitted(PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT) ? (
            <Button disabled={isLoading || status === FETCH_STATUS.IDLE} primary onClick={handleSave}>
              {t('actionButtons.save')}
            </Button>
          ) : null}

          {isUserPermitted(PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT) ? (
            <Button
              variant="outlined"
              color="facebook"
              disabled={status === FETCH_STATUS.IDLE || isLoading}
              onClick={() => handleSave({ saveAndBack: true })}
            >
              {t('actionButtons.saveAndBack')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{status === FETCH_STATUS.IDLE ? <Skeleton /> : renderForm()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  element: shape({}),
  status: string,
  breadcrumbs: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
};

Main.defaultProps = {
  element: {},
  status: undefined,
  breadcrumbs: [],
  permittedPermissions: [],
};

export default Main;
