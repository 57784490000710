const SuccessStoryElementItemModel = {
  position: 1,
  title: {
    pl: '',
    en: '',
  },
  description: {
    pl: '',
    en: '',
  },
};

export default SuccessStoryElementItemModel;
