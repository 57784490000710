import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import MenuItemForm from '@screens/menu/components/MenuItemForm';

const Main = ({ actions, status, errors }) => {
  const MenuItemModel = {
    parent: null,
    codeName: '',
    position: 1,
    label: {
      pl: '',
      en: '',
    },
    url: {
      pl: '',
      en: '',
    },
  };

  return (
    <MenuItemForm
      isCreateMode
      menuItem={MenuItemModel}
      isLoading={status === FETCH_STATUS.LOADING}
      errors={errors}
      onSave={actions.createMenuItem}
    />
  );
};

Main.propTypes = {
  actions: shape({ createMenuItem: func }).isRequired,
  errors: arrayOf(shape({})),
  status: string,
};

Main.defaultProps = {
  status: undefined,
  errors: {},
};

export default Main;
