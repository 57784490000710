import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { TagsForm } from '@screens/blog/components';

const TagModel = {
  name: {
    pl: '',
    en: '',
  },
};

const Main = ({ actions, status, errors, permittedPermissions }) => {
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  return (
    <TagsForm
      isViewMode={!isUserPermitted(PERMISSIONS.CREATE_BLOG)}
      onSave={actions.createTag}
      tag={TagModel}
      isLoading={status === FETCH_STATUS.LOADING}
      errors={errors}
    />
  );
};

Main.propTypes = {
  actions: shape({ createTag: func }).isRequired,
  errors: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
  status: string,
};
Main.defaultProps = {
  errors: [],
  permittedPermissions: [],
  status: undefined,
};

export default Main;
